import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import DataAnalyzingLoading from '../../assets/DataAnalyzingLoading.png';
import ChatLogo from '../../assets/ChatLogo.png';
import DocLoadedIllus from '../../assets/DocLoadedIllus.png';
import FilePreview from './FilePreview';
import { ImgWrapper, ThanksImgWrapper, LoadingImgWrapper } from './style';
import { convertTimestampToFormattedDate } from '../../utility/dateTimeUtils';

const ThanksForAns = ({
  thanksHeadText,
  thanksFootText,
  generatingDocLoading,
  downloadFile,
  handlePreviewDoc,
  docId,
  timestamp,
  filename,
  fetchDocs,
  setDocName,
}) => {
  const [anchorDownload, setAnchorDownload] = useState(null);

  const openDownload = Boolean(anchorDownload);

  const handleClose = () => {
    setAnchorDownload(null);
  };

  return (
    <>
      {timestamp && (
        <Typography sx={{ fontSize: ' 0.75rem', color: 'secondary.light', textAlign: 'left' }}>
          {convertTimestampToFormattedDate(timestamp)}
        </Typography>
      )}
      <Stack width="100%" bgcolor="chat.bg" borderRadius={2} color="primary.black" my=".5rem">
        <Stack direction="row" p={{ xs: 1, md: 2 }} pb={{ xs: 0, md: 0 }}>
          <ImgWrapper src={ChatLogo} alt="bot-logo" />
          <Typography fontWeight={600}>{thanksHeadText}</Typography>
        </Stack>

        {generatingDocLoading ? (
          <Stack p={{ xs: 1, md: 2 }} pt={{ xs: 0, md: 0 }}>
            <Box width="100%" display="flex" justifyContent="center">
              <LoadingImgWrapper src={DataAnalyzingLoading} alt="DataAnalyzingLoading" />
            </Box>
            <Typography fontWeight={600} textAlign="center" my={1}>
              {thanksFootText}
            </Typography>
            <Box width="50%" mx="auto" mt={1}>
              <LinearProgress
                sx={{
                  border: '4px solid',
                  borderColor: 'primary.black',
                  backgroundColor: 'primary.black',
                  borderRadius: 4,
                  p: 0.2,
                }}
              />
            </Box>
          </Stack>
        ) : (
          <Stack p={{ xs: 1, md: 2 }} pt={{ xs: 0, md: 0 }} mt={1}>
            <Box
              width={{ xs: '80%', sm: '75%', md: '66%', lg: '55%', xl: '45%' }}
              mx="auto"
              display="flex"
              justifyContent="center"
            >
              <ThanksImgWrapper src={DocLoadedIllus} alt="DocLoadedIllus" />
            </Box>
            <Typography fontWeight={600} textAlign="center" my={1}>
              Review the document and consult a lawyer for further assistance.
            </Typography>
            <FilePreview
              anchorEl={anchorDownload}
              setAnchorEl={setAnchorDownload}
              handleClose={handleClose}
              openDownload={openDownload}
              handleDownload={downloadFile}
              docId={docId}
              handlePreviewDoc={handlePreviewDoc}
              filename={filename}
              fetchDocs={fetchDocs}
              setDocName={setDocName}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

ThanksForAns.propTypes = {
  thanksHeadText: PropTypes.string,
  thanksFootText: PropTypes.string,
  docId: PropTypes.string,
  timestamp: PropTypes.number,
  filename: PropTypes.string,
  generatingDocLoading: PropTypes.bool,
  downloadFile: PropTypes.func,
  handlePreviewDoc: PropTypes.func,
  fetchDocs: PropTypes.func.isRequired,
  setDocName: PropTypes.func.isRequired,
};

ThanksForAns.defaultProps = {
  thanksHeadText: '',
  thanksFootText: '',
  docId: '',
  timestamp: 0,
  filename: '',
  generatingDocLoading: false,
  downloadFile: () => {},
  handlePreviewDoc: () => {},
};

export default ThanksForAns;
