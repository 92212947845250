import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Wallet, XSquareCloseDelete } from 'react-basicons';
import Button from '../Button';
import { createPaymentOrderAction, verifySignatureAction } from '../../../redux/actions/paymentActions';
import ShowToastMessage from '../../../utility/showToastMessage';
import paymentLogo from '../../../assets/paymentLogo.png';
import { clearChats, setPaymentDoc } from '../../../redux/reducers/chat';
import { selectSessionId } from '../../../redux/selectors/authSelectors';
import { session } from '../../../redux/actions/chatActions';

const DocumentModal = ({ open, setOpen, docText, dataFromPrevModal, docId, id }) => {
  const { payment } = useSelector((state) => state.chat);
  const { user } = useSelector((state) => state.auth);
  const [paymentStarted, setPaymentStarted] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sessionId = useSelector(selectSessionId);

  const handleClose = () => {
    setOpen(false);
  };

  const handleZeroPayment = async (pay) => {
    let sessionID;
    if (dataFromPrevModal) {
      sessionID = await dispatch(session());
    }
    const data = {
      razorpay_payment_id: 'response.razorpay_payment_id',
      razorpay_order_id: 'response.razorpay_order_id',
      razorpay_signature: 'response.razorpay_signature',
      session_id: id || sessionID || sessionId,
      amount: pay.amount,
      status: 'SUCCESS',
      document_id: dataFromPrevModal ? dataFromPrevModal._id : docId,
    };

    await dispatch(verifySignatureAction(data));
    setPaymentStarted(false);
    dispatch(setPaymentDoc(true));
    if (dataFromPrevModal) {
      await dispatch(clearChats());
      navigate('/chat');
    }
  };

  function loadRazorPayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const displayRazorPayPopup = async () => {
    const res = await loadRazorPayScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    if (payment) {
      const { amount, order_id, currency } = payment;

      const options = {
        key: 'rzp_test_V2HFL4GRbQYjDW',
        amount,
        currency,
        name: 'Brihaspati',
        description: 'Test Transaction',
        image: paymentLogo,
        order_id,
        handler: async (response) => {
          let sessionID;
          if (dataFromPrevModal) {
            sessionID = await dispatch(session());
          }
          const data = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            session_id: id || sessionID || sessionId,
            amount,
            status: 'SUCCESS',
            document_id: dataFromPrevModal ? dataFromPrevModal._id : docId,
          };

          await dispatch(verifySignatureAction(data));
          setPaymentStarted(false);
          dispatch(setPaymentDoc(true));
          if (dataFromPrevModal) {
            await dispatch(clearChats());
            navigate('/chat');
          }
        },
        modal: {
          escape: false,
          ondismiss: () => {
            setPaymentStarted(false);
          },
        },

        prefill: {
          name: user.name,
          email: user.email,
          contact: '000000001',
        },
        notes: {
          address: 'Brihaspati Corporate Office',
        },
        theme: {
          color: '#FECE2F',
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on('payment.failed', (response) => {
        ShowToastMessage('error', response.error.description);
      });
    } else {
      alert('payment order failed!!!');
    }
  };

  const onSuccess = () => {
    setPaymentStarted(true);
  };

  const handlePayment = async () => {
    const data = {
      amount: 0,
      metadata: {
        type: 'DOC_GENERATION_PAYMENT',
      },
      session_id: sessionId,
    };
    await dispatch(createPaymentOrderAction(data, onSuccess));
  };

  useEffect(() => {
    if (paymentStarted && Object.keys(payment).length > 0 && payment.amount !== 0) {
      displayRazorPayPopup();
      setOpen(false);
    }
    if (paymentStarted && Object.keys(payment).length > 0 && payment.amount === 0) {
      handleZeroPayment(payment);
      setOpen(false);
    }
  }, [paymentStarted, payment]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'primary.white',
          borderRadius: '8px',
          width: '88%',
        },
        '& .MuiDialog-container': {
          alignItems: 'start',
        },
      }}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.2)',
          backdropFilter: 'blur(5px)',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" fontSize="1.125rem" color="primary.black" fontWeight={600} lineHeight={1.5}>
            Generate Document
          </Typography>
          <Box />
          <Box
            component="div"
            p="5px"
            onClick={() => setOpen(false)}
            sx={{
              borderRadius: '50%',
              border: (theme) => `2px solid ${theme.palette.primary.main}`,
              backgroundColor: 'primary.light',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <XSquareCloseDelete onClick={handleClose} />
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={1.2}>
          <Box display="flex" gap={{ xs: 2, md: 4 }}>
            <Stack>
              <Typography variant="caption" fontSize="0.75rem">
                DOCUMENT TYPE
              </Typography>
              <Typography variant="body1" fontSize="1rem" fontWeight={600}>
                {dataFromPrevModal ? dataFromPrevModal.document_name : docText}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="caption" fontSize="0.75rem">
                DOCUMENT GENERATION FEE
              </Typography>
              <Typography variant="body1" fontSize="1rem" fontWeight={600}>
                ₹0
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="caption" fontSize="0.75rem">
                GST
              </Typography>
              <Typography variant="body1" fontSize="1rem" fontWeight={600}>
                ₹0
              </Typography>
            </Stack>
          </Box>
          <Box display="flex" justifyContent="space-between" mt={2} gap={{ xs: 2, md: 4 }}>
            <Stack>
              <Typography variant="caption">TOTAL AMOUNT</Typography>
              <Typography variant="h6" fontSize="1.5rem" fontWeight={600}>
                ₹0.00
              </Typography>
            </Stack>
            <Button
              variant="contained"
              size="small"
              icon={<Wallet />}
              text="Start"
              onClick={handlePayment}
              sx={{
                bgcolor: 'primary.main',
                textTransform: 'capitalize',
                borderRadius: '20px',
                fontSize: '0.875rem',
                height: '2.5rem',
                fontWeight: 600,
                color: 'primary.black',
                transition: 'all ease 0.3s',
                '&:hover': {
                  transform: 'scale(1.01)',
                  bgcolor: 'secondary.yellow400',
                  color: 'primary.black',
                },
                m: 1,
                padding: '0.625rem 1.25rem',
              }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          bgcolor: 'chat.bg',
        }}
      >
        <Typography variant="body2" fontSize="0.875rem" fontWeight={600} color="secondary.main" p={1}>
          Terms of this Service.
        </Typography>
        <Typography maxWidth="88%" variant="caption" fontSize="0.875rem" p={1}>
          Updating soon...
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

DocumentModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  docText: PropTypes.string,
  docId: PropTypes.string,
  dataFromPrevModal: PropTypes.object,
  id: PropTypes.string,
};

DocumentModal.defaultProps = {
  open: false,
  setOpen: false,
  docText: '',
  docId: '',
  id: '',
  dataFromPrevModal: null,
};

export default DocumentModal;
