import styled from '@emotion/styled';
import DataTable from 'react-data-table-component';

export const TableWrapper = styled.div`
  padding: 1rem 2rem;

  @media (max-width: 600px) {
    padding: 0.6rem;
  }
`;

export const CustomDataTable = styled(DataTable)`
  font-family: 'Work Sans';
  .rdt_Table {
    background-color: ${({ theme }) => theme.palette.primary.bg};
    margin: 16px auto;

    .sc-hmdomO.cTsUPQ {
      border-radius: 12px;
      background-color: ${({ theme }) => theme.palette.secondary.grey400};
      border: 1px solid ${({ theme }) => theme.palette.secondary.grey};
      color: white !important;
    }
  }

  .rdt_TableHeadRow {
    background-color: ${({ theme }) => theme.palette.primary.bg};
    color: white;
    padding: 1.25rem 3.8125rem 0.25rem 1.5rem;
    font-size: 0.75rem;
  }

  .rdt_TableBody {
    gap: 0.625rem;
  }

  .rdt_TableCell {
    flex: none;
  }

  .rdt_TableRow {
    color: white;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.palette.secondary.grey400};
    padding: 1.75rem 3.8125rem 1.75rem 1.5rem;
    font-size: 1rem;

    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.palette.secondary.hover};
    }
  }

  .rdt_TableCol {
    letter-spacing: 2px;
  }
`;

export const ProfileWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.bg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
`;

export const ImgWrapper = styled.img`
  width: auto;
  height: auto;
  object-fit: contain;

  @media (max-width: 600px) {
    height: 128px;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;
