/* eslint-disable no-console */
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Wallet, XSquareCloseDelete } from 'react-basicons';
import Button from '../Button';
import ShowToastMessage from '../../../utility/showToastMessage';
import paymentLogo from '../../../assets/paymentLogo.png';
import { createPaymentOrderAction, verifySignatureAction } from '../../../redux/actions/paymentActions';
import { selectSessionId } from '../../../redux/selectors/authSelectors';
import { setConsultLawyerModal } from '../../../redux/reducers/modal';
import { session } from '../../../redux/actions/chatActions';
import { clearChatMessages, setLawyerJoined } from '../../../redux/reducers/chat';
import { getAllLawyers } from '../../../redux/actions/authActions';

const ConsultLawyer = ({ open, setOpen, doc, id }) => {
  const [paymentStarted, setPaymentStarted] = useState(false);

  const { payment } = useSelector((state) => state.chat);
  const { user, lawyer } = useSelector((state) => state.auth);
  const sessionId = useSelector(selectSessionId);

  const { allLawyers } = lawyer;
  const { isLoggedIn } = user;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleZeroPayment = async (pay) => {
    const data = {
      razorpay_payment_id: 'response.razorpay_payment_id',
      razorpay_order_id: 'response.razorpay_order_id',
      razorpay_signature: 'response.razorpay_signature',
      session_id: id || sessionId,
      amount: pay.amount,
      status: 'SUCCESS',
    };

    dispatch(verifySignatureAction(data));
    setPaymentStarted(false);
    navigate('/calendly', { state: { sId: id } });
  };

  function loadRazorPayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const displayRazorPayPopup = async () => {
    const res = await loadRazorPayScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    if (payment) {
      const { amount, order_id, currency } = payment;

      const options = {
        key: 'rzp_test_V2HFL4GRbQYjDW',
        amount,
        currency,
        name: 'Brihaspati',
        description: 'Test Transaction',
        image: paymentLogo,
        order_id,
        handler: async (response) => {
          const data = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            session_id: id || sessionId,
            amount,
            status: 'SUCCESS',
          };

          dispatch(verifySignatureAction(data));
          setPaymentStarted(false);
          navigate('/calendly', { state: { sId: id } });
        },
        modal: {
          escape: false,
          ondismiss: () => {
            setPaymentStarted(false);
          },
        },

        prefill: {
          name: user.name,
          email: user.email,
          contact: '000000001',
        },
        notes: {
          address: 'Brihaspati Corporate Office',
        },
        theme: {
          color: '#FECE2F',
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on('payment.failed', (response) => {
        ShowToastMessage('error', response.error.description);
      });
    } else {
      alert('payment order failed!!!');
    }
  };

  const onSuccess = () => {
    setPaymentStarted(true);
  };

  const handlePayment = async (paise) => {
    let newSessionId;
    if (!doc) {
      newSessionId = await dispatch(session());
      dispatch(clearChatMessages());
    }
    const data = {
      amount: paise,
      metadata: {
        type: 'CONSULTATION_PAYMENT',
      },
      session_id: newSessionId || sessionId,
    };
    await dispatch(createPaymentOrderAction(data, onSuccess));
  };

  const handleConsultPay = async () => {
    handlePayment(0);
  };

  useEffect(() => {
    if (paymentStarted && Object.keys(payment).length !== 0 && payment.amount !== 0) {
      displayRazorPayPopup();
      dispatch(setConsultLawyerModal(false));
    }
    if (paymentStarted && Object.keys(payment).length > 0 && payment.amount === 0) {
      handleZeroPayment(payment);
      dispatch(setConsultLawyerModal(false));
      dispatch(setLawyerJoined(true));
    }
  }, [payment, paymentStarted]);

  useEffect(() => {
    if (isLoggedIn) dispatch(getAllLawyers({ page: 1, page_size: 10 }));
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'primary.white',
          borderRadius: '8px',
          width: '88%',
        },
        '& .MuiDialog-container': {
          alignItems: 'start',
        },
      }}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.2)',
          backdropFilter: 'blur(5px)',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" fontSize="1.125rem" color="primary.black" fontWeight={600} lineHeight={1.5}>
            Consult a lawyer
          </Typography>
          <Box />
          <Box
            component="div"
            p="5px"
            onClick={() => setOpen(false)}
            sx={{
              borderRadius: '50%',
              border: (theme) => `2px solid ${theme.palette.primary.main}`,
              backgroundColor: 'primary.light',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <XSquareCloseDelete onClick={handleClose} />
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={1.2}>
          <Box display="flex" gap={{ xs: 2, md: 4 }}>
            <Stack>
              <Typography variant="subtitle2" fontSize="0.75rem">
                DOCUMENT
              </Typography>
              <Typography variant="body2" fontSize="1rem" fontWeight={600}>
                {doc || 'CONSULTATION'}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="subtitle2" fontSize="0.75rem">
                CONSULTATION FEE
              </Typography>
              <Typography variant="body2" fontSize="1rem" fontWeight={600}>
                ₹0
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="subtitle2" fontSize="0.75rem">
                GST
              </Typography>
              <Typography variant="body2" fontSize="1rem" fontWeight={600}>
                ₹0
              </Typography>
            </Stack>
          </Box>
          <Box display="flex" justifyContent="space-between" mt="0.8rem" gap={{ xs: 2, md: 4 }}>
            <Stack>
              <Typography variant="subtitle2" fontSize="0.75rem">
                TOTAL AMOUNT
              </Typography>
              <Typography variant="h6" fontWeight={600}>
                ₹0.00
              </Typography>
            </Stack>
            <Button
              onClick={handleConsultPay}
              variant="contained"
              size="small"
              icon={<Wallet />}
              text="Schedule"
              sx={{
                bgcolor: 'primary.main',
                textTransform: 'capitalize',
                borderRadius: '20px',
                fontSize: '0.875rem',
                height: '2.5rem',
                fontWeight: 600,
                color: 'primary.black',
                transition: 'all ease 0.3s',
                '&:hover': {
                  transform: 'scale(1.01)',
                  bgcolor: 'secondary.yellow400',
                  color: 'primary.black',
                },
                m: 1,
                padding: '0.625rem 1.25rem',
              }}
            />
          </Box>
          <Divider
            sx={{
              height: '0.062rem',
            }}
          />
          <Typography variant="body2" fontSize="0.875rem" sx={{ color: 'primary.black' }}>
            A lawyer from our legal team will be assigned to your matter.
            <br /> Make sure you have reviewed the document before consulting the lawyer for a productive call where you
            can answer all clarifications from the lawyer. The lawyer will revert with a revised document within 24
            hours after the call.
          </Typography>
          <Stack gap={0.3}>
            <Typography variant="subtitle2" fontSize="0.75rem" sx={{ color: 'text.light', textTransform: 'uppercase' }}>
              Legal Team
            </Typography>
            <Box display="flex" gap={{ xs: 2, md: 4 }} flexWrap="wrap">
              {allLawyers &&
                allLawyers.map((lawyerInfo) => (
                  <Stack key={lawyerInfo._id} gap={1} alignItems="center" textTransform="capitalize">
                    <Avatar sx={{ width: { xs: '4rem', md: '7rem' }, height: { xs: '4rem', md: '7rem' } }}>
                      {lawyerInfo.full_name[0]}
                    </Avatar>

                    <Typography
                      variant="body2"
                      textAlign="center"
                      fontSize={{ xs: '0.88rem', md: '1rem' }}
                      fontWeight={600}
                    >
                      {lawyerInfo.full_name || '-'}
                      <br />
                      {lawyerInfo.company_name && (
                        <Typography
                          variant="caption"
                          display="flex"
                          width="100%"
                          justifyContent="center"
                          sx={{ color: 'text.light' }}
                        >
                          {lawyerInfo.company_name}
                        </Typography>
                      )}
                    </Typography>
                  </Stack>
                ))}
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          bgcolor: 'chat.bg',
        }}
      >
        <Typography variant="body2" fontSize="0.875rem" fontWeight={600} color="secondary.main" p={1}>
          Terms of this Service.
        </Typography>
        <Typography maxWidth="88%" variant="caption" fontSize="0.875rem" p={1}>
          Updating soon...
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

ConsultLawyer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  doc: PropTypes.string,
  id: PropTypes.string,
};

ConsultLawyer.defaultProps = {
  open: false,
  setOpen: null,
  doc: '',
  id: '',
};

export default ConsultLawyer;
