/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Pagination, Stack, Typography, useMediaQuery, Skeleton } from '@mui/material';
import { Download } from 'react-basicons';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import question from '../../../assets/Question.png';
import { CustomDataTable, ImgWrapper, TableWrapper } from './style';
import TextField from '../../common/TextField';
import { getSession, session } from '../../../redux/actions/chatActions';
import {
  addStartChatQuery,
  clearChats,
  clearSecondPersonDetails,
  setSecondPersonDetails,
} from '../../../redux/reducers/chat';
import { convertTimestampToFormattedDateTime } from '../../../utility/dateTimeUtils';
import { getUserDetails } from '../../../redux/actions/authActions';
import { selectIsLawyerLoggedIn } from '../../../redux/selectors/authSelectors';
import convertPaiseToRupees from '../../../utility/PaiseToRupees';
import ConsultLawyer from '../../common/Modal/ConsultLawyer';
import { setConsultLawyerModal } from '../../../redux/reducers/modal';

export const DocumentTable = ({ currentPage }) => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { chatHistory } = useSelector((state) => state.chat);
  const isLawyerLoggedIn = useSelector(selectIsLawyerLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef();

  const convertedMsg = (msg) => {
    const formattedMsg = msg.toLowerCase();
    if (
      formattedMsg.includes('₹ 0.0 received for lawyer consultation') ||
      formattedMsg.includes('₹ 0.0 received for document generation') ||
      formattedMsg.includes('0.0 received for lawyer consultation') ||
      formattedMsg.includes('0.0 received for document generation')
    ) {
      const newMsg = msg.includes('/n') ? msg.replace('/n', '\n') : msg;
      return convertPaiseToRupees(newMsg);
    }
    return msg;
  };

  const columns = [
    {
      name: 'LAST CHAT',
      selector: (row) =>
        row.latest_message_updated_at && (
          <Typography
            data-tag="allowRowEvents"
            fontSize=".875rem"
            sx={{ overflowWrap: 'anywhere', whiteSpace: 'pre-line', lineHeight: { xs: '1.5rem', lg: '30px' } }}
          >
            {convertTimestampToFormattedDateTime(row.latest_message_updated_at)}
          </Typography>
        ),
      width: '8rem',
    },
    {
      name: 'MATTER',
      selector: (row) => (
        <Typography overflow="hidden" textOverflow="ellipsis" data-tag="allowRowEvents" fontSize=".875rem">
          {convertedMsg(row.session_name)}
        </Typography>
      ),
      width: '22rem',
    },
    {
      name: 'DOCUMENTS',
      selector: (row) => (
        <Stack data-tag="allowRowEvents" maxHeight="64px" sx={{ overflowY: 'auto !important' }} gap={1}>
          {row.user_documents.length > 0 ? (
            row.user_documents.slice(-2).map((doc) => (
              <Stack data-tag="allowRowEvents" direction="row" alignItems="center" key={doc._id}>
                <Download data-tag="allowRowEvents" color="white" size={18} />
                <Typography data-tag="allowRowEvents" variant="caption" ml={1}>
                  {(doc.s3_doc_key && doc.s3_doc_key.split('/').pop()) || 'NA'}
                </Typography>
              </Stack>
            ))
          ) : (
            <Typography data-tag="allowRowEvents" variant="caption" fontSize=".875rem" ml={1}>
              -
            </Typography>
          )}
        </Stack>
      ),
      width: '17rem',
    },
    {
      name: 'REVIEWS & CONSULTATIONS',
      selector: (row) => (
        <Typography data-tag="allowRowEvents" fontSize=".875rem" sx={{ textTransform: 'capitalize' }}>
          {(row.lawyer.length !== 0 && row.lawyer[0].full_name) || '-'}
        </Typography>
      ),
      width: '15rem',
    },
  ];

  useEffect(() => {
    dispatch(getSession(currentPage));
  }, [currentPage]);

  const handleRowSelected = (row) => {
    const secondPersonData = {
      name: row.lawyer[0]?.full_name,
      company: row.lawyer[0]?.company_name,
      email: row.lawyer[0]?.email,
    };

    dispatch(setSecondPersonDetails(secondPersonData));
    navigate(`/chat/${row.session_id}`, { state: { currentPage } });
  };

  const sortedChatHistory = chatHistory?.data;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputRef.current.value.length > 2) {
      dispatch(clearChats());
      dispatch(session());
      dispatch(clearSecondPersonDetails());

      navigate('/chat');
      dispatch(addStartChatQuery({ userQuery: inputRef.current.value }));
    }
  };

  useEffect(() => {
    if (!isLawyerLoggedIn) {
      dispatch(getUserDetails({ user: true }));
    } else {
      dispatch(getUserDetails({ lawyer: true }));
    }
  }, []);

  return (
    <TableWrapper>
      <Typography
        alignSelf="flex-start"
        variant="subtitle1"
        fontWeight={600}
        color="primary.white"
        position={{ xs: 'static', md: 'relative' }}
        top="0.5rem"
        left="4.2rem"
      >
        Chat History
      </Typography>
      <Stack
        width="100%"
        height={{ xs: 'auto', md: '140px' }}
        direction="row"
        sx={{ paddingTop: { xs: '0.5rem', md: '1rem' }, gap: '1rem' }}
        alignItems="center"
        position="relative"
      >
        <Box width={{ xs: '100%', md: '86%' }} display="flex" mx="auto" alignSelf={{ md: 'end' }}>
          <Box
            width={{ xs: '100%', md: '92%' }}
            mx="auto"
            component="form"
            onSubmit={handleSubmit}
            alignSelf={{ md: 'end' }}
            zIndex={2}
          >
            <Typography
              variant="subtitle2"
              color="primary.main"
              sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', float: 'right' }}
              mr={{ xs: '0', md: '4rem' }}
              mb={{ xs: '0.5rem', md: '1rem' }}
              // onClick={() => navigate('/disclaimer')}
              onClick={() => navigate('/coming-soon')}
            >
              Disclaimers <MdOutlineKeyboardArrowRight size={18} />
            </Typography>
            <TextField inputRef={inputRef} placeholder="Type your legal prompt / question here" fullWidth />
          </Box>
          {!isMd && (
            <Box position="absolute" right="0" top="-4px">
              <ImgWrapper src={question} alt="Question" />
            </Box>
          )}
        </Box>
      </Stack>
      <Box height="100%" width="100%" pt={5}>
        {sortedChatHistory ? (
          <CustomDataTable columns={columns} data={sortedChatHistory} onRowClicked={handleRowSelected} />
        ) : (
          <Stack gap={1} pt={4}>
            {Array.from({ length: 5 }, (_, index) => (
              <Skeleton
                animation="wave"
                key={index}
                variant="rectangular"
                sx={{
                  borderRadius: '12px',
                }}
                height={66}
              />
            ))}
          </Stack>
        )}
      </Box>
    </TableWrapper>
  );
};

DocumentTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
};

const ChatHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useDispatch();
  const consultLawyerModal = useSelector((state) => state.modal.consultLawyerModal);
  const { chatHistory } = useSelector((state) => state.chat);

  const toggleConsultLawyerModal = () => {
    dispatch(setConsultLawyerModal(false));
  };

  const handleChange = (e, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (location.state && location.state.currentPage) {
      setCurrentPage(location.state.currentPage);
      navigate(location.pathname, { state: null });
    }
  }, [location]);

  return (
    <Box
      sx={{
        bgcolor: 'primary.bg',
        minHeight: '90vh',
      }}
    >
      <ConsultLawyer open={consultLawyerModal} setOpen={toggleConsultLawyerModal} />

      <Container>
        <DocumentTable currentPage={currentPage} />
        {chatHistory?.metadata?.total_records > 10 && (
          <Pagination
            count={
              chatHistory?.metadata?.total_records &&
              chatHistory?.metadata?.page_size &&
              Math.ceil(chatHistory.metadata.total_records / chatHistory.metadata.page_size)
            }
            color="primary"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '1rem',
              '& .MuiButtonBase-root': { color: 'primary.white' },
            }}
            page={currentPage}
            onChange={handleChange}
          />
        )}
      </Container>
    </Box>
  );
};

export default ChatHistory;
