import styled from '@emotion/styled';
import DataTable from 'react-data-table-component';

export const TableWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CustomDataTable = styled(DataTable)`
  font-family: 'Work Sans';
  .rdt_Table {
    background-color: ${({ theme }) => theme.palette.primary.bg};

    .sc-hmdomO.cTsUPQ {
      border-radius: 12px;
      background-color: ${({ theme }) => theme.palette.secondary.grey400};
      border: 1px solid ${({ theme }) => theme.palette.secondary.grey};
      color: white !important;
    }
  }

  .rdt_TableHeadRow {
    background-color: ${({ theme }) => theme.palette.primary.bg};
    color: white;
    padding: 0.25rem 1.5rem;
  }

  .rdt_TableBody {
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  .rdt_TableRow {
    color: white;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.secondary.grey400};
    padding: 0.75rem 1.5rem;
    min-height: 82px;

    &:hover {
      background-color: ${({ theme }) => theme.palette.secondary.hover};
    }
  }

  .rdt_TableCol {
    letter-spacing: 2px;
  }
`;

export const ImgWrapper = styled.img`
  width: 11rem;
  height: 11rem;
  object-fit: contain;
  margin-right: 4px;
`;
