import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import UserResponseWrapper from './style';

const UserResponse = ({ response }) => (
  <UserResponseWrapper>
    <Stack bgcolor="primary.white" borderRadius={2} color="primary.black" width={{ xs: '100%', md: '80%' }} gap={1}>
      <Stack direction="row" alignItems="flex-start" p={{ xs: 1, md: 2 }}>
        <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>KS</Avatar>
        <Typography mt={0.5} width="88%">
          {response}
        </Typography>
      </Stack>
    </Stack>
  </UserResponseWrapper>
);

UserResponse.propTypes = {
  response: PropTypes.string,
};

UserResponse.defaultProps = {
  response: '',
};

export default UserResponse;
