/* eslint-disable no-undef */
import ShowToastMessage from './showToastMessage';
import { store } from '../redux/store';

const { dispatch } = store;

const MIN_ERROR_INTERVAL_MS = 5000; // Minimum time between error notifications (in milliseconds)

let lastErrorTime = 0; // Timestamp of the last error notification

const showErrorNotification = (errorMessage) => {
  const currentTime = Date.now();
  if (currentTime - lastErrorTime >= MIN_ERROR_INTERVAL_MS) {
    ShowToastMessage('error', errorMessage);
    lastErrorTime = currentTime;
  }
};

const handleError = (err, callBack) => {
  if (callBack) {
    dispatch(callBack(err));
  }
  showErrorNotification(err?.response?.data?.errorData?.message || 'Operation could not be completed');
};

const handleErrorCode = (err, callBack) => {
  if (err?.response?.status === 401) {
    showErrorNotification('Unauthorized!');
    //  window.location.href = '/auth/login';
    //  localStorage.clear();
    //  sessionStorage.clear();
  } else {
    handleError(err, callBack);
  }
};

const errorHandler = (err, callBack) => {
  if (window.navigator.onLine) {
    if (
      err?.response?.status === 502 ||
      err?.response?.status === 500 ||
      err?.message === 'Network Error' ||
      err?.message === 'CORS error'
    ) {
      ShowToastMessage('error', 'Something went wrong, try again later.');
      if (callBack) {
        dispatch(callBack(err));
      }

      // setTimeout(() => {
      //   localStorage.clear();
      //   window.location.href = '/';
      // }, 1300);
    } else if (err?.response?.status === 401) {
      ShowToastMessage('error', err?.response?.data?.errorData?.message || 'Unauthorized');
    } else if (err?.response?.status === 403) {
      ShowToastMessage('error', err?.response?.data?.errorData?.message || 'Access Denied');
    } else {
      handleErrorCode(err, callBack);
    }
  }
};
export default errorHandler;
