import { createBrowserRouter } from 'react-router-dom';
import Home from '../pages/Home';
import App from '../App';
import ForgotPassword from '../pages/ForgotPassword';
import LawyerLogin from '../pages/LawyerLogin';
import Login from '../pages/Login';
import Layout from '../layouts/Layout';
import LawyerLayout from '../layouts/LawyerLayout';
import LawyerHome from '../pages/LawyerHome';
import StartChat from '../pages/StartChat/StartChat';
import Chat from '../components/shared/Chat/Chat';
import Main from '../layouts/Main';
import Disclaimer from '../pages/Inforamtion/Disclaimer';
import LawyerChat from '../pages/LawyerChat';
import ChatHistory from '../components/shared/ChatHistory/ChatHistory';
import ChangePassword from '../pages/ChangePassword';
import LawyerSignup from '../pages/LawyerSignup';
import ResetPassword from '../pages/ResetPassword';
import LoginLayout from '../layouts/LoginLayout';
import Auth from '../utility/Auth';
import Guest from '../utility/Guest';
import Profile from '../components/shared/Profile/index';
import Calendly from '../components/shared/Calendly';

import AdminDashboard from '../components/shared/AdminDashboard';
import NotFound from '../pages/NotFound/NotFound';
import Terms from '../pages/Inforamtion/Terms';
import Pricing from '../pages/Inforamtion/Pricing';
import About from '../pages/Inforamtion/About';
import Privacy from '../pages/Inforamtion/Privacy';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,

    children: [
      {
        path: '/',
        element: (
          <Layout>
            <Guest>
              <Home />
            </Guest>
          </Layout>
        ),
      },
      {
        path: '/get-started',
        element: (
          <LawyerLayout>
            <StartChat />
          </LawyerLayout>
        ),
      },
      {
        path: '/chat',
        element: (
          <LawyerLayout>
            <Chat />
          </LawyerLayout>
        ),
      },

      {
        path: '/client-login',
        element: (
          <Guest>
            <Layout>
              <Login />
            </Layout>
          </Guest>
        ),
      },
      {
        path: '/lawyer-signup',
        element: (
          <Guest>
            <LawyerLayout>
              <LawyerSignup />
            </LawyerLayout>
          </Guest>
        ),
      },
      {
        path: '/lawyer-login',
        element: (
          <Guest>
            <LoginLayout>
              <LawyerLogin />
            </LoginLayout>
          </Guest>
        ),
      },
      {
        path: '/forgot-password',
        element: (
          <Guest>
            <Layout>
              <ForgotPassword />
            </Layout>
          </Guest>
        ),
      },
      {
        path: '/reset-password',
        element: (
          <Guest>
            <Layout>
              <ResetPassword />
            </Layout>
          </Guest>
        ),
      },
      {
        path: '/disclaimer',
        element: (
          <Layout>
            <Disclaimer />
          </Layout>
        ),
      },
      {
        path: '/terms-of-use',
        element: (
          <Layout>
            <Terms />
          </Layout>
        ),
      },
      {
        path: '/pricing',
        element: (
          <Layout>
            <Pricing />
          </Layout>
        ),
      },
      {
        path: '/privacy-policy',
        element: (
          <Layout>
            <Privacy />
          </Layout>
        ),
      },
      {
        path: '/about',
        element: (
          <Layout>
            <About />
          </Layout>
        ),
      },
      {
        path: '/admin-home',
        element: (
          <Main>
            <AdminDashboard />
          </Main>
        ),
      },
      {
        path: '/coming-soon',
        element: <NotFound />,
      },

      {
        path: '/',
        element: <Auth />,
        children: [
          {
            path: '/new-chat',
            element: (
              <LawyerLayout>
                <StartChat />
              </LawyerLayout>
            ),
          },

          {
            path: '/chat/:id',
            element: (
              <LawyerLayout>
                <Chat />
              </LawyerLayout>
            ),
          },

          {
            path: '/chat-history',
            element: (
              <LawyerLayout>
                <ChatHistory />
              </LawyerLayout>
            ),
          },

          {
            path: '/change-password',
            element: (
              <LawyerLayout>
                <ChangePassword />
              </LawyerLayout>
            ),
          },

          {
            path: '/lawyer-home',
            element: (
              <LawyerLayout>
                <LawyerHome />
              </LawyerLayout>
            ),
          },
          {
            path: '/lawyer-chat',
            element: (
              <LawyerLayout>
                <LawyerChat />
              </LawyerLayout>
            ),
          },
          {
            path: '/profile',
            element: (
              <LawyerLayout>
                <Profile />
              </LawyerLayout>
            ),
          },
          {
            path: '/calendly',
            element: (
              <LawyerLayout>
                <Calendly />
              </LawyerLayout>
            ),
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
