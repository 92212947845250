/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Card as MuiCard, Avatar, Stack, Typography } from '@mui/material';
import Button from './Button';
import {
  selectIsAdminLoggedIn,
  selectIsLawyerLoggedIn,
  selectIsUserLoggedIn,
} from '../../redux/selectors/authSelectors';

const Card = ({ handleClick }) => {
  const isLawyerLoggedIn = useSelector(selectIsLawyerLoggedIn);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isAdminLoggedIn = useSelector(selectIsAdminLoggedIn);

  const { user, lawyer, admin } = useSelector((state) => state.auth);

  const getInitials = () => {
    if (isLawyerLoggedIn) {
      return (lawyer.full_name && lawyer.full_name[0].toUpperCase()) || lawyer.name[0].toUpperCase();
    }
    if (isUserLoggedIn) {
      return (user.full_name && user.full_name[0].toUpperCase()) || user.name[0].toUpperCase();
    }
    if (isAdminLoggedIn) {
      return (admin.full_name && admin.full_name[0].toUpperCase()) || admin.name[0].toUpperCase();
    }
    return 'GU';
  };

  return (
    <MuiCard
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 250,
        borderRadius: '0.5rem',
        bgcolor: 'secondary.grey300',
        border: (theme) => `1px solid ${theme.palette.secondary.grey200}`,
      }}
    >
      <Stack direction="row" padding="1rem .75rem" gap={2}>
        <Avatar
          sx={{
            width: isLawyerLoggedIn ? '7rem' : '5rem',
            height: isLawyerLoggedIn ? '7rem' : '5rem',
            color: 'primary.black',
            bgcolor: 'primary.main',
            fontSize: ' 1.5rem',
            fontWeight: 600,
          }}
        >
          {getInitials()}
        </Avatar>
        <Stack gap={1} alignItems="flex-start" justifyContent="center">
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 600 }}>
            {isLawyerLoggedIn
              ? lawyer.full_name || lawyer.name
              : user.full_name || user.name || admin.full_name || admin.name}
            {!isLawyerLoggedIn && !isUserLoggedIn && !isAdminLoggedIn && 'Guest User'}
          </Typography>
          <Typography color="secondary.grey500" variant="body2" sx={{ fontSize: '.75rem', fontWeight: 600 }}>
            {user && user.company_name}
            {!isLawyerLoggedIn && !isUserLoggedIn && user.user_id}
          </Typography>

          {(isLawyerLoggedIn || isUserLoggedIn || isAdminLoggedIn) && (
            <Button
              text="View Profile"
              variant="outlined"
              onClick={handleClick}
              sx={{
                marginTop: '0.6rem',
                bgcolor: 'primary.light',
                borderRadius: '20px',
                color: 'primary.black',
                fontSize: '.875rem',
                fontWeight: 600,
                height: '2.5rem',
                border: '2px solid',
                textTransform: 'capitalize',
                borderColor: 'primary.main',
                transition: 'all ease 0.3s',
                '&:hover': {
                  border: '2px solid',
                  borderColor: 'primary.main',
                  transform: 'scale(1.01)',
                  bgcolor: 'primary.light',
                  color: 'primary.black',
                },
                padding: '0.625rem 1.25rem',
              }}
            />
          )}
        </Stack>
      </Stack>
    </MuiCard>
  );
};

Card.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default Card;
