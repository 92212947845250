import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { NextArrowForward } from 'react-basicons';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { SHA1 } from 'crypto-js';
import { BannerWrapper, ImgWrapper } from './style';
import login from '../../assets/login.png';
import TextStyle from '../../components/common/TextStyle';
import Button from '../../components/common/Button';
import { lawyerSignup } from '../../redux/actions/authActions';
import ShowToastMessage from '../../utility/showToastMessage';

const LawyerSignup = () => {
  const validationSchema = yup.object().shape({
    fullName: yup.string().min(3, 'Minimum of 3 letters is required').required('Email or mobile number is required'),
    email: yup.string().required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = () => {
    ShowToastMessage('success', 'Account created successfully');
    navigate('/lawyer-login');
  };

  const onSubmit = (data) => {
    const { fullName, email, password } = data;
    const hashedPassword = SHA1(password).toString();
    const formData = {
      full_name: fullName,
      email,
      password: hashedPassword,
    };

    const json = JSON.stringify(formData);

    dispatch(lawyerSignup(json, onSuccess));
  };

  return (
    <BannerWrapper>
      {' '}
      <Box
        p={{ xs: 2, md: 4 }}
        width={{ xs: 'auto', lg: '560px' }}
        bgcolor="primary.white"
        sx={{ borderRadius: '8px' }}
      >
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.black" fontWeight={600} lineHeight={1.5}>
            Sign Up
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextStyle
              id="fullName"
              label="Full Name"
              {...register('fullName')}
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
            />
            {errors.fullName && (
              <Typography color="red" variant="caption">
                {errors.fullName.message}
              </Typography>
            )}
            <TextStyle
              id="email"
              label="Email Address"
              {...register('email')}
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
            />
            {errors.email && (
              <Typography color="red" variant="caption">
                {errors.email.message}
              </Typography>
            )}

            <TextStyle
              type="password"
              id="password"
              {...register('password')}
              label="Password"
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
            />
            {errors.password && (
              <Typography color="red" variant="caption">
                {errors.password.message}
              </Typography>
            )}

            <Stack direction="row" justifyContent="space-between" pt={2}>
              <Button
                type="submit"
                text="sign up"
                icon={<NextArrowForward />}
                variant="contained"
                size="small"
                sx={{
                  fontWeight: 600,
                  bgcolor: 'primary.main',
                  borderRadius: '20px',
                  color: 'primary.black',
                  transition: 'all ease 0.3s',
                  '&:hover': {
                    transform: 'scale(1.01)',
                    bgcolor: 'secondary.yellow400',
                    color: 'primary.black',
                  },
                  p: 1,
                  px: 2,
                }}
              />
            </Stack>
          </form>
        </Stack>
      </Box>
      <ImgWrapper src={login} alt="" />
    </BannerWrapper>
  );
};

export default LawyerSignup;
