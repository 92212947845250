import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import LawyerNavbar from '../components/shared/LawyerNavbar';

const Main = ({ children }) => (
  <Box bgcolor="primary.bg" width="100vw" minHeight="100vh">
    <LawyerNavbar />
    {children}
  </Box>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Main;
