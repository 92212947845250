/* eslint-disable */
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { PropTypes } from 'prop-types';
import DocumentModal from './DocumentModal';
import GenerateDocument from './GenerateDocument';
import SignupModal from './SignupModal';

const MultiStepModal = ({ open, setOpen, step, setStep, userFingerprint, docId }) => {
  const { pathname } = useLocation();
  const [dataForNextModal, setDataForNextModal] = useState(null);
  const notInChat = pathname.includes('/get-started') || pathname.includes('/new-chat');

  const handleNext = (data) => {
    if (data) {
      setDataForNextModal(data);
    }
    setStep(step + 1);
  };

  const secondComp = notInChat ? (
    <GenerateDocument open={open} setOpen={setOpen} onNext={handleNext} />
  ) : (
    <DocumentModal open={open} setOpen={setOpen} docId={docId} />
  );

  let modalComponent;
  switch (step) {
    case 1:
      modalComponent = (
        <SignupModal open={open} setOpen={setOpen} userFingerprint={userFingerprint} onNext={handleNext} />
      );
      break;

    case 2:
      modalComponent = secondComp;
      break;

    case 3:
      modalComponent = notInChat && (
        <DocumentModal dataFromPrevModal={dataForNextModal} open={open} setOpen={setOpen} />
      );
      break;
    default:
      modalComponent = null;
  }

  return <div>{modalComponent}</div>;
};

MultiStepModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  step: PropTypes.number,
  setStep: PropTypes.func,
  userFingerprint: PropTypes.string,
  docId: PropTypes.string,
};

MultiStepModal.defaultProps = {
  open: false,
  setOpen: false,
  step: 1,
  setStep: 1,
  userFingerprint: '',
  docId: '',
};
export default MultiStepModal;
