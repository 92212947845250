import { DateTime } from 'luxon';

export const formatTimestamp = (timestamp) => {
  const dateTime = DateTime.fromMillis(timestamp);
  return dateTime.toFormat('dd/MM/yyyy');
};

export const sortChats = (chats) => {
  const sortedChats = chats.slice().sort((a, b) => {
    const timeA = DateTime.fromMillis(a.last_message_updated_at || a.created_at);
    const timeB = DateTime.fromMillis(b.last_message_updated_at || b.created_at);

    if (timeA < timeB) {
      return 1;
    }

    if (timeA > timeB) {
      return -1;
    }

    return 0;
  });

  return sortedChats;
};

export const convertTimestampToFormattedDate = (timestamp) => {
  const dateTime = DateTime.fromMillis(timestamp);
  const formattedDate = dateTime.toFormat("h:mm a',' EEE',' dd LLLL yyyy");
  return formattedDate;
};

export const convertTimestampToFormattedDateTime = (timestamp) => {
  const dateTime = DateTime.fromMillis(timestamp);
  const formattedDateTime = dateTime.toFormat('dd/LL/yyyy h:mm a');
  return formattedDateTime;
};

export const separateDateAndTime = (timestamp) => {
  const dateTime = DateTime.fromISO(timestamp);

  const formattedDate = dateTime.toFormat('dd/LL/yyyy');
  const formattedTime = dateTime.toFormat('h:mm a');

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export const formatDateForQuestions = (inputDate) => {
  function getOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  const dateTime = DateTime.fromISO(inputDate).setLocale('en');
  const { day } = dateTime;
  const ordinalSuffix = getOrdinalSuffix(day);
  const formattedDate = dateTime.toFormat(`d'${ordinalSuffix} day of' MMMM, yyyy`);
  return formattedDate;
};
