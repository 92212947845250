import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Navbar from '../components/shared/Navbar';

const Layout = ({ children }) => (
  <Box bgcolor="primary.bg" width="100vw" minHeight="100vh">
    <Navbar />
    {children}
  </Box>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
