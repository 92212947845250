import getAllSessionsService from '../../services/adminServices';
import errorHandler from '../../utility/errorHandler';
import { addChatHistory } from '../reducers/chat';

const getAllSessionsAction = (page) => async (dispatch) => {
  try {
    const resp = await getAllSessionsService(page);
    dispatch(addChatHistory(resp.data.data));
  } catch (error) {
    errorHandler(error);
  }
};

export default getAllSessionsAction;
