import {
  addLawyerToChatService,
  createSessionService,
  getChatFromHistory,
  getSessionService,
  lawyerMetricService,
  userMetricService,
} from '../../services/chatServices';
import errorHandler from '../../utility/errorHandler';
import { addLawyerMetrics, addUserMetrics, setSession } from '../reducers/auth';
import { addChatHistory, addChats, setLawyerJoined } from '../reducers/chat';
import { getMeetingDetails } from './calendlyActions';

const session = () => async (dispatch) => {
  try {
    const resp = await createSessionService();
    await dispatch(setSession(resp.data.data.session_id));
    return resp.data.data.session_id;
  } catch (error) {
    errorHandler(error);
  }
  return null;
};

const getSession = (currentPage) => async (dispatch) => {
  try {
    const resp = await getSessionService(currentPage);
    dispatch(addChatHistory(resp.data.data));
  } catch (error) {
    errorHandler(error);
  }
};

const fetchChatFromHistory = (id, page) => async (dispatch) => {
  try {
    const resp = await getChatFromHistory(id, page);

    const chatHistoryMessages = resp.data.data.messages;

    const isLawyerJoined = chatHistoryMessages.is_lawyer_joined;
    dispatch(setLawyerJoined(isLawyerJoined));

    const data = page === 1 ? chatHistoryMessages.data.slice().reverse() : chatHistoryMessages.data;

    data.map((chat) => {
      if (chat.chat_type === 'CONSULTATION_PAYMENT') {
        const scheduledObj = {
          id: chat._id,
          sender: chat.user[0].email,
          sender_type: 'BOT',
          message: chat.message,
          timestamp: chat.created_at,
          isScheduled: true,
          page,
        };
        dispatch(getMeetingDetails(chat.optional_id, chat.created_at));
        dispatch(addChats(scheduledObj));
      } else if (chat.chat_type === 'FORM_FILLED') {
        const formFilledObj = {
          id: chat._id,
          sender: chat.user[0].email,
          name: chat.user[0].full_name,
          sender_type: 'BOT',
          filledBy: chat.user[0].user_type,
          message: chat.message,
          timestamp: chat.created_at,
          doc_name: chat.optional_id,
          isFormFilled: true,
          userDocId: chat.message.split(' ')[2],
          page,
        };
        dispatch(addChats(formFilledObj));
      } else if (chat.chat_type === 'DOC_PAYMENT') {
        const docPayObj = {
          id: chat._id,
          sender: chat.user[0].email,
          name: chat.user[0].full_name,
          sender_type: 'BOT',
          filledBy: chat.user[0].user_type,
          message: chat.message,
          timestamp: chat.created_at,
          isPaid: true,
          page,
        };
        dispatch(addChats(docPayObj));
      } else {
        if (chat.user[0].user_type === 'USER') {
          const userChatObj = {
            id: chat._id,
            name: chat.user[0].full_name,
            sender: chat.user[0].email,
            sender_type: 'USER',
            userQuery: chat.message,
            timestamp: chat.created_at,
            full_name: chat.user[0].full_name,
            company_name: chat.user[0].company_name,
            page,
          };
          dispatch(addChats(userChatObj));
        }

        if (chat.user[0].user_type === 'BOT') {
          const botChatObj = {
            id: chat._id,
            sender: chat.user[0].email,
            sender_type: 'BOT',
            botResponse: chat.message,
            isQA: false,
            timestamp: chat.created_at,
            page,
          };
          dispatch(addChats(botChatObj));
        }

        if (chat.user[0].user_type === 'LAWYER') {
          const lawyerChatObj = {
            id: chat._id,
            name: chat.user[0].full_name,
            sender: chat.user[0].email,
            sender_type: 'LAWYER',
            userQuery: chat.message,
            timestamp: chat.created_at,
            page,
          };
          dispatch(addChats(lawyerChatObj));
        }
      }
    });
  } catch (error) {
    errorHandler(error);
  }
};

const addLawyerToUserChat = (sessionId, userId) => async () => {
  try {
    await addLawyerToChatService(sessionId, userId);
  } catch (error) {
    errorHandler(error);
  }
};

const userMetricsAction = () => async (dispatch) => {
  try {
    const resp = await userMetricService();
    dispatch(addUserMetrics(resp.data.data.messages));
  } catch (error) {
    errorHandler(error);
  }
};
const lawyerMetricsAction = () => async (dispatch) => {
  try {
    const resp = await lawyerMetricService();
    dispatch(addLawyerMetrics(resp.data.data));
  } catch (error) {
    errorHandler(error);
  }
};

export { session, getSession, fetchChatFromHistory, addLawyerToUserChat, userMetricsAction, lawyerMetricsAction };
