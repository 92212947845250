/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Download } from 'react-basicons';
import { useDispatch, useSelector } from 'react-redux';
import question from '../../../assets/Question.png';
import { CustomDataTable, ImgWrapper, TableWrapper } from './style';
import getAllSessionsAction from '../../../redux/actions/adminAction';
import convertPaiseToRupees from '../../../utility/PaiseToRupees';

export const DocumentTable = () => {
  const dispatch = useDispatch();
  const { chatHistory } = useSelector((state) => state.chat);

  const extractFileNameFromPath = (path) => {
    const regex = /\/([^/]+)\.docx$/;
    const match = path.match(regex);
    if (match) {
      return match[1];
    }
    return null;
  };

  const convertedMsg = (msg) => {
    const formattedMsg = msg.toLowerCase();
    if (
      formattedMsg.includes('₹ 800000.0 received for lawyer consultation') ||
      formattedMsg.includes('₹ 80000.0 received for document generation') ||
      formattedMsg.includes('800000.0 received for lawyer consultation') ||
      formattedMsg.includes('80000.0 received for document generation')
    ) {
      const newMsg = msg.includes('/n') ? msg.replace('/n', '\n') : msg;

      return convertPaiseToRupees(newMsg);
    }
    return msg;
  };
  const columns = [
    {
      name: 'CLIENT',
      selector: (row) => (
        <>
          <Typography variant="body2" textAlign="left">
            {row.users[0]?.full_name}
          </Typography>
          <Typography variant="caption" textAlign="left">
            {row.users[0]?.email}
          </Typography>
        </>
      ),
      width: '12rem',
    },
    {
      name: 'LAWYER',
      selector: (row) => (
        <>
          <Typography variant="body2" textAlign="left">
            {row.lawyer[0]?.full_name || '-'}
          </Typography>
          <Typography variant="caption" textAlign="left">
            {row.lawyer[0]?.email}
          </Typography>
        </>
      ),
      width: '12rem',
    },

    {
      name: 'CHAT DETAILS',
      selector: (row) => <Typography fontSize="1rem">{convertedMsg(row.session_name)}</Typography>,
      width: '20rem',
    },
    {
      name: 'DOCUMENTS',
      selector: (row) => (
        <Stack gap={1}>
          {row.user_documents.length > 0 ? (
            row.user_documents?.slice(-2).map((doc) => (
              <Stack direction="row" alignItems="center">
                <Download color="white" />
                <Typography variant="body2" ml={1}>
                  {extractFileNameFromPath(doc.s3_doc_key)}
                </Typography>
              </Stack>
            ))
          ) : (
            <Typography variant="body2" ml={1}>
              -
            </Typography>
          )}
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllSessionsAction(1));
  }, []);

  const sortedChatHistory = chatHistory;

  return (
    <TableWrapper>
      <Stack
        width="100%"
        direction={{ xs: 'column-reverse', md: 'row' }}
        sx={{ paddingBottom: '1rem', gap: '1rem' }}
        mb=".5rem"
        justifyContent="space-between"
        alignItems={{ xs: 'center', md: 'end' }}
      >
        <Typography variant="subtitle1" sx={{ fontSize: '1.125rem' }} fontWeight={600} color="primary.white">
          Details
        </Typography>
        <Box>
          <ImgWrapper src={question} alt="Question" />
        </Box>
      </Stack>
      <Box width="100%" height="100%">
        <CustomDataTable columns={columns} data={sortedChatHistory} />
      </Box>
    </TableWrapper>
  );
};

const AdminDashboard = () => (
  <Box
    sx={{
      bgcolor: 'primary.bg',
      minHeight: '90vh',
    }}
  >
    {' '}
    <Container>
      <DocumentTable />{' '}
    </Container>
  </Box>
);

export default AdminDashboard;
