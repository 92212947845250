import API from '../configs/api';
import DataService from '../configs/dataService/dataService';

const createSessionService = () => DataService.post(API.chat.session);
const getSessionService = (currentPage) => DataService.get(`${API.chat.allSession}?page=${currentPage}`);
const userMetricService = () => DataService.get(`${API.chat.userMetrics}`);
const lawyerMetricService = () => DataService.get(`${API.chat.lawyerMetrics}`);
const getChatFromHistory = (id, page) => DataService.get(`${API.chat.history}?page=${page}&session_id=${id}`);
const addLawyerToChatService = (sessionId, userId) =>
  DataService.post(`${API.chat.addLawyerToChat}?session_id=${sessionId}&to_add_user_id=${userId}`);

export {
  createSessionService,
  addLawyerToChatService,
  getSessionService,
  getChatFromHistory,
  userMetricService,
  lawyerMetricService,
};
