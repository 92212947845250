import styled from '@emotion/styled';

export const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.bg};
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 12px;
  gap: 6rem;

  padding-top: 5rem;
  @media (max-width: 1024px) {
    gap: 1rem;
  }

  @media (max-width: 475px) {
    flex-direction: column-reverse;
    justify-content: start;
    margin: 0 auto;
  }
`;

export const ImgWrapper = styled.img`
  height: 280px;
  width: 280px;
  object-fit: contain;

  @media screen and (max-width: 600px) {
    height: 200px;
    width: 200px;
  }
  @media screen and (max-width: 475px) {
    display: none;
  }
`;
