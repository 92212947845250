import styled from '@emotion/styled';

const StartChatWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${({ theme }) => theme.breakpoints.values.xl}px;
  margin: 0 auto;
`;

const StartChatImgWrapper = styled.img`
  height: 220px;
  width: 220px;
  object-fit: contain;

  @media screen and (max-width: 600px) {
    height: 140px;
    width: 140px;
  }
`;

const ImgBackBtnWrapper = styled.img`
  width: auto;
  height: auto;
  object-fit: contain;
  @media screen and (max-width: 600px) {
    height: 16px;
  }
`;

export { StartChatWrapper, StartChatImgWrapper, ImgBackBtnWrapper };
