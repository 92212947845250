import styled from '@emotion/styled';

const ImgBgWrapper = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  opacity: 0.1;
`;

export default ImgBgWrapper;
