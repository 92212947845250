import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { NextArrowForward } from 'react-basicons';

import Button from '../Button';
import { FormContainer } from './style';
import ToggleButton from '../ToggleButton';

const RefillFormModal = ({
  inProgressForms,
  openRefillModal,
  handleRefillClose,
  setStartRefilling,
  setRefillFormData,
  setDocId,
}) => {
  const [selectedButtonId, setSelectedButtonId] = useState(null);
  const [, setSelectedDoc] = useState(null);

  const handleSelect = (buttonId, doc) => {
    setSelectedButtonId(buttonId);
    setRefillFormData(doc);
    setDocId(doc.document_id);
  };

  return (
    <Dialog
      open={openRefillModal}
      onClose={handleRefillClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'primary.white',
          minWidth: { md: '680px' },
        },
        '& .MuiDialog-container': {
          alignItems: 'start',
        },
      }}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(5px)',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        You have {inProgressForms.length} unfilled form{inProgressForms.length > 1 ? 's' : ''}
      </DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          <Typography variant="caption" color="text.light">
            Select a Form to refill
          </Typography>
          <FormContainer>
            {inProgressForms.map((doc) => (
              <ToggleButton
                flexGrow={inProgressForms.length > 1 ? 1 : 0}
                key={doc._id}
                doc={doc}
                onClick={handleSelect}
                isSelected={selectedButtonId === doc._id}
                setSelectedDoc={setSelectedDoc}
              />
            ))}
          </FormContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          text="Start Refilling"
          onClick={() => setStartRefilling(true)}
          icon={<NextArrowForward />}
          sx={{
            bgcolor: 'primary.main',
            borderRadius: '16px',
            fontSize: '12px',
            fontWeight: 600,
            color: 'primary.black',
            '&:hover': {
              bgcolor: 'primary.main',
              color: 'primary.black',
            },
            m: 1,
            p: 1,
            px: 3,
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

RefillFormModal.propTypes = {
  inProgressForms: PropTypes.array.isRequired,
  openRefillModal: PropTypes.bool.isRequired,
  handleRefillClose: PropTypes.func.isRequired,
  setStartRefilling: PropTypes.func.isRequired,
  setRefillFormData: PropTypes.func.isRequired,
  setDocId: PropTypes.func.isRequired,
};

export default RefillFormModal;
