import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LawyerNavbar from '../components/shared/LawyerNavbar';

const LawyerLayout = ({ children }) => (
  <Box bgcolor="primary.bg" width="100vw" minHeight="100vh">
    <LawyerNavbar />
    {children}
  </Box>
);

LawyerLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LawyerLayout;
