import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { BannerWrapper, ImgWrapper } from './style';
import hero from '../../../assets/hero.png';
import Button from '../../common/Button';

const Hero = () => (
  <BannerWrapper>
    <Box
      width={{ lg: '31rem', sm: '18rem' }}
      height={{ lg: '25rem', sm: '18rem' }}
      display={{ xs: 'none', sm: 'flex' }}
      my={2}
      position="relative"
      left="2.5%"
      pt={4}
    >
      <ImgWrapper src={hero} alt="hero" />
    </Box>
    <Stack spacing={{ xs: 1, md: 1 }} pt={4} alignItems={{ xs: 'center', md: 'start' }}>
      <Box
        bgcolor="primary.white"
        maxWidth={{ xs: '16rem', smob: '19rem', mob: '22rem', sm: '25rem', md: '35rem' }}
        sx={{ borderRadius: '8px', padding: { xs: '1.25rem', md: '2.5rem' } }}
        my={2}
      >
        <Stack spacing={3}>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: 'small', md: 'medium' }}
            color="primary.black"
            fontWeight={600}
            lineHeight={1.5}
          >
            Answers to legal questions & smart legal document generator powered by AI for your business
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: 'small', md: 'medium' }}
            color="primary.black"
            fontWeight={600}
            lineHeight={1.5}
          >
            Also consultation with expert corporate lawyers
          </Typography>
          <Stack spacing={2}>
            <Link to="/get-started">
              <Button
                text="Get Started"
                endIcon={<EastIcon />}
                variant="contained"
                sx={{
                  height: '2.5rem',
                  bgcolor: 'primary.main',
                  borderRadius: '20px',
                  color: 'primary.black',
                  transition: 'all ease 0.3s',
                  textTransform: 'capitalize',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  padding: '0.625rem 1.25rem',
                  '&:hover': {
                    transform: 'scale(1.01)',
                    bgcolor: 'secondary.yellow400',
                    color: 'primary.black',
                  },
                }}
              />
            </Link>

            <Link to="/client-login">
              <Button
                text="Login"
                variant="outlined"
                sx={{
                  maxWidth: 'max-content',
                  bgcolor: 'primary.light',
                  borderRadius: '20px',
                  height: '2.5rem',
                  color: 'primary.black',
                  border: '2px solid',
                  borderColor: 'primary.main',
                  transition: 'all ease 0.3s',
                  textTransform: 'capitalize',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  padding: '0.625rem 1.25rem',
                  '&:hover': {
                    border: '2px solid',
                    borderColor: 'primary.main',
                    transform: 'scale(1.01)',
                    bgcolor: 'primary.light',
                    color: 'primary.black',
                  },
                }}
              />
            </Link>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  </BannerWrapper>
);

export default Hero;
