import styled from '@emotion/styled';

const Wrapper = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.values.xl}px;
  max-height: calc(100vh - 122px);
  overflow: hidden;
  margin: auto;
  padding: 24px 12px;
  scrollbar-width: none;

  & ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  & ::-webkit-scrollbar-track {
    border-radius: 50px;
    height: 1px;
    width: 1px;

    background-color: ${({ theme }) => theme.palette.secondary.grey500};
  }

  & ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    height: 6px;
    width: 6px;
  }
`;

const ImgWrapper = styled.img`
  width: 220px;
  height: 220px;
  object-fit: contain;
  margin-right: 4px;
  margin-top: -3%;
`;

export { Wrapper, ImgWrapper };
