/* eslint-disable no-console */
import {
  getDocService,
  getAllDocs,
  getTotalDocCountService,
  getSessionDocService,
  uploadDocService,
  checkDocumentStatusService,
} from '../../services/docServices';
import errorHandler from '../../utility/errorHandler';
import { addTotalDoc, setAllDocs, setChatDocStatus, setHtmlContent, setUploadDoc } from '../reducers/chat';

const downloadDoc = (userDocId, doc_type) => async () => {
  try {
    const res = await getDocService(userDocId, doc_type);
    if (doc_type === 'HTML') {
      return res.data.data.html_url;
    }
    if (doc_type === 'PDF') {
      return res.data.data.pdf_url;
    }
    if (doc_type === 'DOCX') {
      return res.data.data.doc_url;
    }
  } catch (error) {
    errorHandler(error);
  }
  return null;
};

const getDocumentCount = (data) => async (dispatch) => {
  try {
    const res = await getTotalDocCountService(data);
    dispatch(addTotalDoc(res.data.data.total_count));
  } catch (error) {
    errorHandler(error);
  }
};

const fetchHtml = (link) => async (dispatch) => {
  try {
    const response = await fetch(link);
    const html = await response.text();
    dispatch(setHtmlContent(html));
  } catch (error) {
    errorHandler(error);
  }
};

const fetchAllDocs = () => async (dispatch) => {
  try {
    const res = await getAllDocs();
    dispatch(setAllDocs(res.data.data.documents));
  } catch (error) {
    errorHandler(error);
  }
};

const fetchSessionDocs = (data) => async () => {
  try {
    const res = await getSessionDocService(data);
    return res.data.data.documents;
  } catch (error) {
    errorHandler(error);
  }
  return null;
};

const uploadDoc = (data) => async (dispatch) => {
  try {
    const res = await uploadDocService(data);
    dispatch(setUploadDoc(res.data.data));
  } catch (error) {
    errorHandler(error);
  }
};
const checkDocStatusInChat = (data) => async (dispatch) => {
  try {
    const { sessionId, page, pageSize } = data;
    const res = await checkDocumentStatusService({ sessionId, page, pageSize });
    dispatch(setChatDocStatus(res.data.data));
  } catch (error) {
    errorHandler(error);
  }
};

export { downloadDoc, fetchHtml, getDocumentCount, fetchAllDocs, fetchSessionDocs, uploadDoc, checkDocStatusInChat };
