import styled from '@emotion/styled';

const BotResponseImgLogoWrapper = styled.img`
  width: 50px;
  height: 55px;
  margin-right: 16px;
  object-fit: contain;
  transition: all ease 0.3s;

  @media screen and (max-width: 600px) {
    margin-right: 8px;

    width: 32px;
    height: 45px;
  }
`;

const BotResponseDocImgWrapper = styled.img`
  width: 90px;
  height: 100px;
  position: absolute;
  right: 8px;
  object-fit: contain;

  @media screen and (max-width: 900px) {
    width: 70px;
    height: 80px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export { BotResponseImgLogoWrapper, BotResponseDocImgWrapper };
