import * as React from 'react';
import { PropTypes } from 'prop-types';
import { Menu, Stack, Button as MuiButton } from '@mui/material';
import { PaperFileText, VideoCameraMedia } from 'react-basicons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../common/Card';
import Button from '../common/Button';
import {
  selectIsAdminLoggedIn,
  selectIsLawyerLoggedIn,
  selectIsUserLoggedIn,
} from '../../redux/selectors/authSelectors';
import { logoutAdmin, logoutLawyer, logoutUser } from '../../redux/reducers/auth';
import { clearChats } from '../../redux/reducers/chat';
// import { session } from '../../redux/actions/chatActions';
import { setConsultLawyerModal, setSignUpModal } from '../../redux/reducers/modal';

const ProfileMenu = ({ open, anchorProfile, handleAvatarClose }) => {
  const isLawyerLoggedIn = useSelector(selectIsLawyerLoggedIn);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isAdminLoggedIn = useSelector(selectIsAdminLoggedIn);
  const navigate = useNavigate();

  // const { pathname } = useLocation();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate('/profile');
    handleAvatarClose();
  };

  const handleLogout = () => {
    dispatch(clearChats());
    if (isUserLoggedIn) {
      dispatch(logoutUser());
      navigate('/client-login');
    }
    if (isLawyerLoggedIn) {
      dispatch(logoutLawyer());
      navigate('/lawyer-login');
    }
    if (isAdminLoggedIn) {
      dispatch(logoutAdmin());
      navigate('/client-login');
    }
    localStorage.clear();
  };

  const handleGenerateDoc = () => {
    const data = {
      generateDoc: true,
    };

    navigate('/get-started', { state: data });
    handleAvatarClose();
  };

  const handleConsultLawyer = () => {
    // const data = {
    //   consultLawyer: true,
    // };

    // if (pathname === '/chat-history') {
    //   dispatch(clearChats());
    //   dispatch(session());
    // }

    // navigate('/chat', { state: data });
    if (isUserLoggedIn) {
      dispatch(setConsultLawyerModal(true));
      handleAvatarClose();
    } else {
      dispatch(setSignUpModal(true));
      handleAvatarClose();
    }
  };

  const handleChangePassword = () => {
    handleAvatarClose();
    navigate('/change-password');
  };

  return (
    <Menu
      anchorEl={anchorProfile}
      id="account-menu"
      open={open}
      onClose={handleAvatarClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{
        '.MuiPaper-root': {
          p: 1,
          px: 1.8,
          borderRadius: '0.5rem',
        },
        '.MuiMenu-paper': {
          maxWidth: '26rem',
          maxHeight: isLawyerLoggedIn ? '20rem' : '26rem',
        },
      }}
    >
      <Card handleClick={handleClick} />

      {!isLawyerLoggedIn && !isAdminLoggedIn && (
        <Stack my={1}>
          <Button
            onClick={handleGenerateDoc}
            variant="contained"
            size="small"
            icon={<PaperFileText size={20} />}
            text="Generate Document"
            sx={{
              width: 'max-content',
              bgcolor: 'primary.main',
              borderRadius: '16px',
              fontSize: '.875rem',
              fontWeight: 600,
              textTransform: 'none',
              color: 'primary.black',
              '&:hover': {
                bgcolor: 'primary.main',
                color: 'primary.black',
              },
              m: 1,
              p: 1,
              px: 2,
            }}
          />
          <Button
            variant="contained"
            size="small"
            icon={<VideoCameraMedia size={20} />}
            text="Consult Lawyer"
            onClick={handleConsultLawyer}
            sx={{
              width: 'max-content',
              bgcolor: 'primary.main',
              borderRadius: '16px',
              fontSize: '.875rem',
              fontWeight: 600,
              textTransform: 'none',
              color: 'primary.black',
              '&:hover': {
                bgcolor: 'primary.main',
                color: 'primary.black',
              },
              m: 1,
              p: 1,
              px: 2,
            }}
          />
        </Stack>
      )}

      {(isLawyerLoggedIn || isUserLoggedIn || isAdminLoggedIn) && (
        <Stack mt={1} direction="row" justifyContent="space-between">
          <div>
            <MuiButton
              onClick={handleChangePassword}
              size="small"
              variant="text"
              sx={{ color: 'primary.black', textTransform: 'capitalize' }}
            >
              Change Password
            </MuiButton>
            <MuiButton
              size="small"
              variant="text"
              sx={{ color: 'primary.black', textTransform: 'capitalize' }}
              onClick={() => navigate('/coming-soon')}
            >
              About Brihaspati.ai
            </MuiButton>
            <div />
            <MuiButton
              size="small"
              variant="text"
              onClick={handleLogout}
              sx={{ color: 'primary.black', textTransform: 'capitalize', ml: '-4px' }}
            >
              Logout
            </MuiButton>
          </div>
          <div>
            <MuiButton
              size="small"
              variant="text"
              sx={{ color: 'primary.black', textTransform: 'capitalize' }}
              onClick={() => navigate('/coming-soon')}
            >
              Terms of Use
            </MuiButton>

            <MuiButton
              size="small"
              variant="text"
              sx={{ color: 'primary.black', textTransform: 'capitalize' }}
              onClick={() => navigate('/coming-soon')}
            >
              Privacy Policy
            </MuiButton>
          </div>
        </Stack>
      )}

      {!isLawyerLoggedIn && !isUserLoggedIn && !isAdminLoggedIn && (
        <Stack mt={1} alignItems="start" justifyContent="space-between">
          <MuiButton
            size="small"
            variant="text"
            sx={{ color: 'primary.black', textTransform: 'capitalize' }}
            onClick={() => navigate('/coming-soon')}
          >
            About brihaspati.ai
          </MuiButton>
          <MuiButton
            size="small"
            variant="text"
            sx={{ color: 'primary.black', textTransform: 'capitalize' }}
            onClick={() => navigate('/coming-soon')}
          >
            Terms of Use
          </MuiButton>

          <MuiButton
            size="small"
            variant="text"
            sx={{ color: 'primary.black', textTransform: 'capitalize' }}
            onClick={() => navigate('/coming-soon')}
          >
            Privacy Policy
          </MuiButton>
        </Stack>
      )}
    </Menu>
  );
};

ProfileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorProfile: PropTypes.object,
  handleAvatarClose: PropTypes.func.isRequired,
};

ProfileMenu.defaultProps = {
  anchorProfile: null,
};

export default ProfileMenu;
