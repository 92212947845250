import styled from '@emotion/styled';

export const BannerWrapper = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.primary.bg};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    gap: 5rem;
  }

  @media (max-width: 475px) {
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const ImgWrapper = styled.img`
  width: 20rem;
  height: 20rem;
`;
