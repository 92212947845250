import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import LawyerMessageWrapper from './style';

const LawyerMessage = ({ query }) => (
  <LawyerMessageWrapper>
    <Stack
      bgcolor="secondary.grey200"
      borderRadius={2}
      color="primary.black"
      width={{ xs: '100%', md: '80%' }}
      direction="row"
      alignItems="flex-start"
      gap={1}
      my="1rem"
      p={{ xs: 1, md: 2 }}
    >
      <Avatar sx={{ bgcolor: 'primary.main' }}>L</Avatar>
      <Typography mt={0.5} width="88%">
        {query}
      </Typography>
    </Stack>
  </LawyerMessageWrapper>
);

LawyerMessage.propTypes = {
  query: PropTypes.string,
};
LawyerMessage.defaultProps = {
  query: '',
};
export default LawyerMessage;
