import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    isLoggedIn: false,
    sessionId: '',
    fingerprint: '',
    isGuest: false,
    metrics: [],
    transactionHistory: [],
  },
  admin: {
    isAdminLoggedIn: false,
  },

  lawyer: {
    isLoggedIn: false,
    metrics: [],
    allLawyers: [],
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setGuestUser: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
    }),
    userSignupAction: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        isLoggedIn: true,
        ...action.payload,
      },
    }),

    setSession: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        sessionId: action.payload,
      },
    }),

    userLogOut: (state) => ({
      ...state,
      user: {
        isLoggedIn: false,
      },
    }),

    lawyerLogOut: (state) => ({
      ...state,
      lawyer: {
        isLoggedIn: false,
      },
    }),

    userLogin: (state, action) => ({
      ...state,
      user: { ...state.user, ...action.payload, isLoggedIn: true },
    }),
    adminLogin: (state, action) => ({
      ...state,
      admin: { ...state.admin, ...action.payload, isAdminLoggedIn: true },
    }),

    userLoginFailure: (state, action) => ({
      ...state,
      user: { error: action.payload },
    }),

    lawyerLogin: (state, action) => ({
      ...state,
      lawyer: { ...action.payload, isLoggedIn: true },
    }),

    lawyerLoginFailure: (state, action) => ({
      ...state,
      lawyer: { error: action.payload },
    }),

    deleteGuestUserFingerprint: (state) => ({
      ...state,
      user: {
        ...state.user,
        fingerprint: '',
      },
    }),

    logoutUser: (state) => ({
      ...state,
      user: {
        ...initialState.user,
      },
    }),
    logoutAdmin: (state) => ({
      ...state,
      admin: {
        ...initialState.admin,
      },
    }),

    userDetails: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
    }),

    lawyerDetails: (state, action) => ({
      ...state,
      lawyer: {
        ...state.lawyer,
        ...action.payload,
      },
    }),
    addUserMetrics: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        metrics: action.payload,
      },
    }),

    addLawyerMetrics: (state, action) => ({
      ...state,
      lawyer: {
        ...state.lawyer,
        metrics: action.payload,
      },
    }),

    addTransactionHistory: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        transactionHistory: action.payload,
      },
    }),

    logoutLawyer: (state) => ({
      ...state,
      lawyer: {
        ...initialState.lawyer,
      },
    }),

    allLawyersInfo: (state, action) => ({
      ...state,
      lawyer: {
        ...state.lawyer,
        allLawyers: action.payload,
      },
    }),
  },
});

export const {
  userLogOut,
  lawyerLogOut,
  userLogin,
  adminLogin,
  lawyerLogin,
  userLoginFailure,
  lawyerLoginFailure,
  setGuestUser,
  setSession,
  deleteGuestUserFingerprint,
  userSignupAction,
  logoutUser,
  logoutAdmin,
  userDetails,
  lawyerDetails,
  addLawyerMetrics,
  addUserMetrics,
  logoutLawyer,
  addTransactionHistory,
  allLawyersInfo,
} = authSlice.actions;

export default authSlice.reducer;
