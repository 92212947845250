import styled from '@emotion/styled';

const BotResponseWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0.5rem 0;
`;

const BotResponseImgWrapper = styled.img`
  width: 50px;
  height: 55px;
  object-fit: contain;
  margin-right: 16px;
  transition: all ease 0.3s;

  @media screen and (max-width: 600px) {
    margin-right: 8px;
    width: 32px;
    height: 45px;
  }
`;

export { BotResponseWrapper, BotResponseImgWrapper };
