import React from 'react';
import * as yup from 'yup';
import { SHA1 } from 'crypto-js';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SecurityPasswordKey } from 'react-basicons';
import { BannerWrapper, ImgWrapper } from './style';
import resetPwd from '../../assets/resetPwd.png';
import TextStyle from '../../components/common/TextStyle';
import ShowToastMessage from '../../utility/showToastMessage';
import { changePassword } from '../../redux/actions/authActions';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
      .required('New Password is required'),
    confirmPassword: yup.string().required('Re-Enter Password is required'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSuccess = () => {
    ShowToastMessage('success', 'Password updated.');
    navigate('/client-login');
  };

  const onSubmit = (data) => {
    const { oldPassword, newPassword, confirmPassword } = data;

    if (newPassword === confirmPassword) {
      const hashedPassword = SHA1(newPassword).toString();
      const oldHashedPassword = SHA1(oldPassword).toString();
      dispatch(changePassword(oldHashedPassword, hashedPassword, onSuccess));
    } else {
      ShowToastMessage('error', 'Passwords did not match.');
    }
  };

  return (
    <BannerWrapper>
      {' '}
      <Stack>
        <Box
          width={{ xs: 'auto', lg: '35rem' }}
          bgcolor="primary.white"
          p={{ xs: 2, md: '1.5rem' }}
          sx={{ borderRadius: '8px' }}
        >
          <Stack spacing={3}>
            <Typography variant="h5" color="primary.black" fontWeight={600} lineHeight={1.5}>
              Change Password
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextStyle
                id="oldPassword"
                type="Password"
                label="ENTER OLD PASSWORD"
                {...register('oldPassword')}
                variant="outlined"
                fullWidth
                sx={{
                  marginY: '.5rem',
                  '& .MuiInputBase-root': {
                    borderRadius: '.75rem',
                  },
                }}
              />
              {errors.oldPassword && (
                <Typography color="red" variant="caption">
                  {errors.oldPassword.message}
                </Typography>
              )}
              <TextStyle
                id="newPassword"
                type="Password"
                label="ENTER NEW PASSWORD"
                {...register('newPassword')}
                variant="outlined"
                fullWidth
                sx={{
                  marginY: '.5rem',
                  '& .MuiInputBase-root': {
                    borderRadius: '.75rem',
                  },
                }}
              />
              {errors.newPassword && (
                <Typography color="red" variant="caption">
                  {errors.newPassword.message}
                </Typography>
              )}
              <TextStyle
                type="Password"
                id="confirmPassword"
                label="RE-ENTER NEW PASSWORD"
                {...register('confirmPassword')}
                variant="outlined"
                fullWidth
                sx={{
                  marginY: '.5rem',
                  '& .MuiInputBase-root': {
                    borderRadius: '.75rem',
                  },
                }}
              />
              {errors.confirmPassword && (
                <Typography color="red" variant="caption">
                  {errors.confirmPassword.message}
                </Typography>
              )}

              <Stack direction="row" justifyContent="space-between" pt={2}>
                <Button
                  type="submit"
                  startIcon={<SecurityPasswordKey />}
                  variant="contained"
                  sx={{
                    borderRadius: '20px',
                    padding: '0.5rem 1.25rem',
                    fontWeight: 600,
                    fontSize: '.875rem',
                    textTransform: 'capitalize',
                  }}
                >
                  Reset Password
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>{' '}
      <ImgWrapper src={resetPwd} alt="Reset Password Image" />
    </BannerWrapper>
  );
};

export default ChangePassword;
