import React, { useRef, useState } from 'react';
import {
  Box,
  Container,
  Button as MuiButton,
  useTheme,
  Grid,
  Stack,
  Typography,
  IconButton,
  useMediaQuery,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  PaperFileText,
  VideoCameraMedia,
  Download,
  XSquareCloseDelete,
  ArrowDropdownCaretSortSelectArrow,
} from 'react-basicons';
import FileViewer from 'react-file-viewer';
import Button from '../../components/common/Button';
import TextField from '../../components/common/TextField';
import LawyerMessage from '../../components/shared/LawyerMessage';
import UserResponse from '../../components/shared/UserResponse';
import ChatLogo from '../../assets/ChatLogo.png';
import { ChatContainer, ImgWrapper } from './style';
import Appointment from '../../components/shared/Appointment';
import FilePreview from '../../components/shared/FilePreview';
import UserResponseWrapper from '../../components/shared/UserResponse/style';

const LawyerChat = () => {
  const [setUserQuery] = useState('');
  const [setResponse] = useState('');
  const [showDoc, setShowDoc] = useState(false);
  const [anchorElSec, setAnchorElSec] = useState(null);
  const openRight = Boolean(anchorElSec);
  const chatData = [
    {
      id: 1,
      sender: true,
      message: 'Hello, how are you?',
    },
    {
      id: 2,
      sender: false,
      message: "I'm doing great, thanks! How about you?",
    },
    {
      id: 3,
      sender: true,
      message: "I'm good too. Just working on a project.",
    },
    {
      id: 4,
      sender: false,
      message: "That's awesome! What kind of project are you working on?",
    },
    {
      id: 5,
      sender: true,
      message: "I'm building a chat application. It's quite challenging but exciting!",
    },
    {
      id: 6,
      sender: false,
      message: 'Oh, I see! Chat apps are indeed interesting. Best of luck with your project!',
    },
    {
      id: 7,
      sender: true,
      message: 'Hello, how are you?',
    },
    {
      id: 8,
      sender: false,
      message: "I'm doing great, thanks! How about you?",
    },
    {
      id: 9,
      sender: true,
      message: "I'm good too. Just working on a project.",
    },
    {
      id: 10,
      sender: false,
      message: "That's awesome! What kind of project are you working on?",
    },
    {
      id: 11,
      sender: true,
      message: "I'm building a chat application. It's quite challenging but exciting!",
    },
    {
      id: 12,
      sender: false,
      message: 'Oh, I see! Chat apps are indeed interesting. Best of luck with your project!',
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();

  const inputRef = useRef();
  const myTheme = useTheme();
  const isSmallScreen = useMediaQuery((t) => t.breakpoints.down('sm'));

  const primaryColor = myTheme.palette.primary.main;

  const onError = (e) => {
    console.error(e, 'error in file-viewer');
  };

  const botAns =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet.  ';

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserQuery(inputRef.current.value);
    inputRef.current.value = '';

    setResponse(botAns);
  };

  const handleDocClick = () => {
    setShowDoc(true);
  };

  const handleClose = () => {
    setAnchorElSec(null);
  };

  return (
    <Grid sx={{ bgcolor: 'primary.bg', minHeight: '92.4vh' }} container spacing={2}>
      <Grid sx={{ minHeight: '92.25vh', display: isSmallScreen && showDoc && 'none' }} item xs={showDoc ? 6 : 12}>
        <Container sx={{ padding: '0 16px !important' }}>
          {!showDoc && (
            <MuiButton
              onClick={handleDocClick}
              sx={{
                bgcolor: 'secondary.grey400',
                zIndex: '999',
                borderRadius: '20px',
                position: 'fixed',
                top: '10%',
                right: '1.5%',
              }}
              startIcon={<PaperFileText color={primaryColor} />}
            >
              0
            </MuiButton>
          )}

          <Box height="85vh" p={1} position="relative">
            <ChatContainer>
              <Box
                height={{ xs: '72vh', lg: '77vh' }}
                overflow="auto"
                display="flex"
                flexDirection="column"
                gap={0.4}
                py={2}
              >
                {chatData.map((chat) => (
                  <div key={chat.id}>
                    {chat.sender ? <LawyerMessage query={chat.message} /> : <UserResponse response={chat.message} />}
                  </div>
                ))}
                <UserResponseWrapper>
                  <Stack
                    bgcolor="chat.bg"
                    borderRadius={2}
                    color="primary.black"
                    width={{ xs: '100%', md: '77.25%' }}
                    direction="row"
                    alignItems="flex-start"
                    gap={1}
                    my="1rem"
                    p={{ xs: 1, md: 2 }}
                  >
                    <ImgWrapper src={ChatLogo} alt="bot-logo" />
                    <Box flexGrow={1}>
                      <Typography fontWeight={600} mt={0.5} width="88%">
                        Kartik Sanyal of PE Enterprises has scheduled a video consultation with you
                      </Typography>{' '}
                      <Appointment />
                    </Box>
                  </Stack>
                </UserResponseWrapper>
                <UserResponseWrapper>
                  <Stack
                    bgcolor="primary.white"
                    borderRadius={2}
                    color="primary.black"
                    width={{ xs: '100%', md: '77.25%' }}
                    direction="row"
                    alignItems="flex-start"
                    gap={1}
                    my="1rem"
                    p={{ xs: 1, md: 2 }}
                  >
                    <Avatar sx={{ bgcolor: 'primary.main' }}>L</Avatar>
                    <Box flexGrow={1}>
                      <Typography mt={0.5} width="88%">
                        You sent you a revised document with track changes.
                      </Typography>{' '}
                      <FilePreview />
                    </Box>
                  </Stack>
                </UserResponseWrapper>
              </Box>
            </ChatContainer>
            <Box position="absolute" bottom="0" left="0" right="0">
              <form onSubmit={handleSubmit}>
                <TextField fullWidth inputRef={inputRef} placeholder="Reply or ask clarification" type="submit" />
              </form>
            </Box>
          </Box>
        </Container>
      </Grid>{' '}
      {showDoc && (
        <Grid p={1} item xs={12} sm={6}>
          <Box
            sx={{
              bgcolor: 'primary.white',
              color: 'primary.black',
              borderRadius: '8px',
              overflow: 'auto',
              height: '87.5vh',
            }}
          >
            <Box bgcolor="chat.bg" borderRadius="8px" p={1}>
              <Stack direction="row" gap={5} alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                  <Typography variant="body2" component="span" fontWeight={600}>
                    {selectedFile.split('/')[3].split('.')[0].length > 20
                      ? `${selectedFile.split('/')[3].split('.')[0].substring(0, 20)}...`
                      : selectedFile.split('/')[3].split('.')[0]}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ borderRadius: '.5rem', bgcolor: 'primary.white200' }}
                    onClick={(event) => setAnchorElSec(event.currentTarget)}
                  >
                    {' '}
                    <ArrowDropdownCaretSortSelectArrow />
                  </Box>

                  <Menu
                    anchorEl={anchorElSec}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={openRight}
                    onClose={handleClose}
                    onClick={handleClose}
                    sx={{
                      mt: '2.5%',

                      '& .MuiPaper-root': { width: '125px', backgroundColor: 'secondary.blackShade' },
                    }}
                  >
                    {files.map((fileName) => (
                      <MenuItem key={fileName} sx={{ fontSize: '.9rem' }} onClick={() => setSelectedFile(fileName)}>
                        <Stack
                          color={selectedFile === fileName ? 'primary.main' : 'primary.black'}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography variant="body2" sx={{ textTransform: 'capitalize', ml: 2 }}>
                            {fileName.split('/')[3].split('.')[0].length > 10
                              ? `${fileName.split('/')[3].split('.')[0].substring(0, 10)}...`
                              : fileName.split('/')[3].split('.')[0]}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Menu>
                </Stack>

                <Stack gap={2} direction="row" alignItems="center" justifyContent="center">
                  <Button
                    onClick={() => setShowDoc(false)}
                    variant="contained"
                    text="Consult Lawyer"
                    sx={{
                      bgcolor: 'primary.main',
                      borderRadius: '20px',
                      fontSize: '12px',
                      color: 'primary.black',
                      transition: 'all ease 0.3s',
                      '&:hover': {
                        transform: 'scale(1.01)',
                        bgcolor: 'secondary.yellow400',
                        color: 'primary.black',
                      },
                    }}
                    icon={<VideoCameraMedia />}
                  />
                  <IconButton
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'secondary.main',
                      borderRadius: '20px',
                    }}
                  >
                    <Download />
                  </IconButton>
                  <Box
                    p="5px"
                    onClick={() => setShowDoc(false)}
                    sx={{
                      borderRadius: '50%',
                      border: (theme) => `2px solid ${theme.palette.primary.main}`,
                      backgroundColor: 'primary.light',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <XSquareCloseDelete />
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Stack
              spacing={1}
              p={2}
              sx={{
                overflow: 'auto',
                height: '73vh',
              }}
            >
              {files.map((fileName) => {
                if (selectedFile === fileName) {
                  return (
                    <div key={fileName}>
                      <FileViewer fileType="docx" filePath={selectedFile} onError={onError} />
                    </div>
                  );
                }
                return null;
              })}
            </Stack>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default LawyerChat;
