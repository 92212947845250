import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAdminLoggedIn, selectIsLawyerLoggedIn, selectIsUserLoggedIn } from '../redux/selectors/authSelectors';

const Guest = ({ children }) => {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isLawyerLoggedIn = useSelector(selectIsLawyerLoggedIn);
  const isAdminLoggedIn = useSelector(selectIsAdminLoggedIn);

  if (isUserLoggedIn) {
    return <Navigate to="/chat-history" />;
  }

  if (isLawyerLoggedIn) {
    return <Navigate to="/lawyer-home" />;
  }
  if (isAdminLoggedIn) {
    return <Navigate to="/admin-home" />;
  }

  return children;
};
Guest.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Guest;
