import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NextArrowForward, XSquareCloseDelete } from 'react-basicons';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import { fetchAllDocs } from '../../../redux/actions/docActions';
import { selectIsUserLoggedIn } from '../../../redux/selectors/authSelectors';
import ToggleButton from '../ToggleButton';
import { addDocId } from '../../../redux/reducers/chat';
import { DocContainer } from './style';

const GenerateDocument = ({ open, setOpen, onNext }) => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const chatDetails = useSelector((state) => state.chat);
  const [selectedButtonId, setSelectedButtonId] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const handleButtonClick = (buttonId, doc) => {
    setSelectedButtonId(buttonId);
    setSelectedDoc(doc);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGenerateDoc = () => {
    if (selectedDoc && Object.keys(selectedDoc).length !== 0) {
      onNext(selectedDoc);
      dispatch(addDocId(selectedDoc._id));
    }
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(fetchAllDocs());
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'primary.white',
          minWidth: { md: '560px' },
        },
        '& .MuiDialog-container': {
          alignItems: 'start',
        },
      }}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.2)',
          backdropFilter: 'blur(5px)',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color="primary.black" fontWeight={600} lineHeight={1.5}>
            Generate a legal document
          </Typography>
          <Box />
          <Box
            p="5px"
            sx={{
              borderRadius: '50%',
              border: (theme) => `2px solid ${theme.palette.primary.main}`,
              backgroundColor: 'primary.light',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <XSquareCloseDelete onClick={handleClose} />
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" gap={{ xs: 2, md: 4 }}>
          <Stack gap={1}>
            <Typography variant="caption" color="text.light">
              Business Registrations
            </Typography>
            <DocContainer>
              {chatDetails.userDoc.allDocs.map((doc) => (
                <ToggleButton
                  key={doc._id}
                  doc={doc}
                  isSelected={selectedButtonId === doc._id}
                  onClick={handleButtonClick}
                  setSelectedDoc={setSelectedDoc}
                />
              ))}
            </DocContainer>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ alignSelf: 'start' }}>
        <Button
          variant="contained"
          text="Next"
          icon={<NextArrowForward />}
          onClick={handleGenerateDoc}
          sx={{
            bgcolor: 'primary.main',
            borderRadius: '16px',
            fontSize: '12px',
            fontWeight: 600,
            color: 'primary.black',
            '&:hover': {
              bgcolor: 'primary.main',
              color: 'primary.black',
            },
            m: 1,
            p: 1,
            px: 3,
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

GenerateDocument.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onNext: PropTypes.func,
};

GenerateDocument.defaultProps = {
  open: false,
  onNext: null,
  setOpen: null,
};

export default GenerateDocument;
