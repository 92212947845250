import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { Box, useTheme } from '@mui/material';
import { scheduledMeetingAction } from '../../redux/actions/calendlyActions';
import { selectSessionId } from '../../redux/selectors/authSelectors';

const Calendly = () => {
  const { user } = useSelector((state) => state.auth);
  const sessionId = useSelector(selectSessionId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    state: { sId },
  } = useLocation();

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      const calendlyEventUri = e.data.payload.event.uri;
      const calendlyInviteeUri = e.data.payload.invitee.uri;

      const fetchDetailsAPI = async (url) =>
        fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_TOKEN}`,
          },
        }).then((response) => response.json());

      const fetchCalendlyData = async (eventUri, inviteeUri) => {
        try {
          const [eventData, inviteeData] = await Promise.all([fetchDetailsAPI(eventUri), fetchDetailsAPI(inviteeUri)]);

          const data = {
            event_type: eventData.resource.event_type,
            event_name: eventData.resource.name,
            start_time: eventData.resource.start_time,
            end_time: eventData.resource.end_time,
            lawyer_details: {
              calendly_user_id: eventData.resource.event_memberships[0].user,
              email: eventData.resource.event_memberships[0].user_email,
              schedule_url: eventData.resource.uri,
            },
            location: eventData.resource.location,
            invitee_details: {
              name: inviteeData.resource.name,
              email: inviteeData.resource.email,
              url: inviteeData.resource.uri,
              questions_and_answers: inviteeData.resource.questions_and_answers,
              cancel_url: inviteeData.resource.cancel_url,
              reschedule_url: inviteeData.resource.reschedule_url,
              timezone: inviteeData.resource.timezone,
              event_url: inviteeData.resource.event,
            },
            calendar_event: eventData.resource.calendar_event,
            session_id: sessionId || sId,
          };

          await dispatch(scheduledMeetingAction(data));
          const meetingData = {
            isScheduled: true,
            id: sId,
          };

          if (sId) {
            navigate(`/chat/${sId}`, { state: meetingData });
            return;
          }
          navigate('/chat', { state: meetingData });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchCalendlyData(calendlyEventUri, calendlyInviteeUri);
    },
  });

  return (
    <Box width="100%" height="100vh">
      <InlineWidget
        url={`${process.env.REACT_APP_CALENDLY_ENDPOINT}`}
        className="calendly-component"
        styles={{
          height: '100%',
          position: 'relative',
          top: '-60px',
        }}
        pageSettings={{
          backgroundColor: '#1C1D23',
          primaryColor: theme.palette.primary.main,
          textColor: '#ffffff',
        }}
        prefill={{
          name: user.name,
          email: user.email,
        }}
      />
    </Box>
  );
};
export default Calendly;
