/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { Avatar, Box, Container, Pagination, Skeleton, Stack, Typography } from '@mui/material';
import { Save } from 'react-basicons';
import { PropTypes } from 'prop-types';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { CustomDataTable, FormWrapper, ImgWrapper, ProfileWrapper, TableWrapper } from './style';
import profile from '../../../assets/Newsletter.png';
import lawyerProfileImg from '../../../assets/lawyerProfile.png';
import Button from '../../common/Button';
import {
  selectIsAdminLoggedIn,
  selectIsLawyerLoggedIn,
  selectIsUserLoggedIn,
} from '../../../redux/selectors/authSelectors';
import { getUserDetails, updateUser } from '../../../redux/actions/authActions';
import TextStyle from '../../common/TextStyle';
import ShowToastMessage from '../../../utility/showToastMessage';
import { getAllTransactions } from '../../../redux/actions/paymentActions';
import { convertTimestampToFormattedDateTime, sortChats } from '../../../utility/dateTimeUtils';
import { lawyerMetricsAction, userMetricsAction } from '../../../redux/actions/chatActions';

export const TransactionTable = ({ sortedTransactions }) => {
  const columns = [
    {
      name: 'TIMESTAMP',
      selector: (row) =>
        row.created_at && (
          <Typography fontSize=".875rem" sx={{ overflowWrap: 'anywhere', whiteSpace: 'pre-line' }}>
            {convertTimestampToFormattedDateTime(row.created_at)}
          </Typography>
        ),
      width: '12rem',
    },
    {
      name: 'ACTIVITY',
      selector: (row) => (
        <Typography fontSize=".875rem">
          {row.amount && row.amount / 100 === 8000 ? 'VC With Lawyer' : 'Document Generation'}
        </Typography>
      ),
      width: '20.875rem',
    },
    {
      name: 'DOCUMENT',
      selector: (row) => (
        <Typography fontSize=".875rem">
          {(row.document?.s3_doc_key && row.document.s3_doc_key.split('/').pop()) ||
            row.document?.document_name ||
            'NA'}
        </Typography>
      ),
      width: '22.875rem',
    },
    {
      name: 'PAYMENT',
      selector: (row) => <Typography fontSize=".875rem">{row.amount ? `₹ ${row.amount / 100}` : 'NA'}</Typography>,
    },
  ];

  return (
    <TableWrapper>
      <Typography ml={{ xs: 0, md: '2rem' }} variant="subtitle1" fontWeight={600} color="primary.white">
        Transactions
      </Typography>

      <CustomDataTable columns={columns} data={sortedTransactions} />
    </TableWrapper>
  );
};
TransactionTable.propTypes = {
  sortedTransactions: PropTypes.array,
};
TransactionTable.defaultProps = {
  sortedTransactions: [],
};

const Profile = () => {
  const { user, lawyer } = useSelector((state) => state.auth);
  const { transactionHistory, metrics } = useSelector((state) => state.auth.user);
  const { metrics: lawyerMetrics } = lawyer;
  const lawyerProfile = useSelector(selectIsLawyerLoggedIn);
  const userProfile = useSelector(selectIsUserLoggedIn);
  const adminProfile = useSelector(selectIsAdminLoggedIn);

  // const [usageTime, setUsageTime] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const sortedTransactions = transactionHistory?.data && sortChats(transactionHistory?.data);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const userValidationSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    companyName: yup.string().required('Company Name is required'),
    designation: yup.string().required('Designation is required'),
  });

  const lawyerValidationSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(lawyerProfile ? lawyerValidationSchema : userValidationSchema),
  });

  const onSuccess = () => {
    ShowToastMessage('success', 'Profile Updated Successfully!');
    dispatch(getUserDetails(lawyerProfile ? { lawyer: true } : { user: true }));
  };

  const onSubmit = (data) => {
    if (!lawyerProfile) {
      const formData = {
        designation: data.designation,
        company_name: data.companyName,
        full_name: data.fullName,
      };

      dispatch(updateUser(formData, onSuccess));
      return;
    }

    const formData = {
      full_name: data.fullName,
    };
    dispatch(updateUser(formData, onSuccess));
  };

  // const handleChange = (event) => {
  //   setUsageTime(event.target.value);
  // };

  useEffect(() => {
    if (!lawyerProfile) {
      setTimeout(() => {
        !user.email && dispatch(getUserDetails({ user: true }));
      }, [1000]);
      return;
    }
    setTimeout(() => {
      !lawyer.email && dispatch(getUserDetails({ lawyer: true }));
    }, [1000]);
  }, []);

  useEffect(() => {
    if (!lawyerProfile) {
      dispatch(getAllTransactions(currentPage));
    }
  }, [currentPage]);

  useEffect(() => {
    if (userProfile) dispatch(userMetricsAction());
    if (lawyerProfile) dispatch(lawyerMetricsAction());
  }, []);

  return (
    <ProfileWrapper>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: '28px' }}>
        <Stack>
          <Typography ml={{ xs: 0, md: '2rem' }} mb={{ xs: 1, md: 2 }} fontWeight={600}>
            {lawyerProfile ? 'Lawyer Profile' : 'Your Profile'}
          </Typography>
          {lawyerProfile ? (
            <Box
              bgcolor="primary.white"
              p={2}
              borderRadius="8px"
              sx={{ height: '100%', width: { xs: '15rem', smob: '21rem', sm: '35rem', md: '25rem', lg: '32rem' } }}
            >
              <Stack mb={2} spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                <Avatar sx={{ width: '6rem', height: '6rem', fontSize: '2rem' }}>
                  {lawyer.full_name && lawyer.full_name[0].toUpperCase()}
                </Avatar>
                <Box color="secondary.main">
                  <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1.25rem' }}>
                    {lawyer.full_name}
                  </Typography>
                </Box>
              </Stack>
              <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                {!lawyer.email ? (
                  <Stack spacing={4}>
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />

                    <Skeleton variant="rectangular" height={36} width="40%" />
                  </Stack>
                ) : (
                  <>
                    <TextStyle
                      id="fullName"
                      {...register('fullName')}
                      defaultValue={lawyer.full_name}
                      label="Your Full Name"
                      variant="outlined"
                      fullWidth
                      sx={{ marginY: '.5rem' }}
                    />
                    {errors.fullName && (
                      <Typography color="red" variant="caption">
                        {errors.fullName.message}
                      </Typography>
                    )}

                    <TextStyle
                      id="mobileNumber"
                      disabled
                      {...register('mobileNumber')}
                      defaultValue={lawyer.mobileNumber || '+91 xxxxxxxxxx'}
                      label="Your Mobile Number"
                      variant="outlined"
                      fullWidth
                      sx={{ marginY: '.5rem' }}
                    />
                    {errors.mobileNumber && (
                      <Typography color="red" variant="caption">
                        {errors.mobileNumber.message}
                      </Typography>
                    )}

                    <TextStyle
                      disabled
                      id="companyEmail"
                      {...register('companyEmail')}
                      defaultValue={lawyer.email}
                      label="Your Company Email Address"
                      variant="outlined"
                      fullWidth
                      sx={{ marginY: '.5rem' }}
                    />
                    {errors.companyEmail && (
                      <Typography color="red" variant="caption">
                        {errors.companyEmail.message}
                      </Typography>
                    )}

                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      icon={<Save />}
                      disabled={!isDirty}
                      text="Update Profile"
                      sx={{
                        alignSelf: 'flex-start',
                        bgcolor: 'primary.main',
                        borderRadius: '20px',
                        color: 'primary.black',
                        transition: 'all ease 0.3s',
                        fontWeight: 600,
                        '&:hover': {
                          transform: 'scale(1.01)',
                          bgcolor: 'secondary.yellow400',
                          color: 'primary.black',
                        },
                        p: 1,
                        px: 2,
                      }}
                    />
                  </>
                )}
              </FormWrapper>
            </Box>
          ) : (
            <Box
              bgcolor="primary.white"
              p={2}
              borderRadius="8px"
              sx={{ height: '100%', width: { xs: '15rem', smob: '20rem', sm: '35rem', md: '25rem', lg: '32rem' } }}
            >
              <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                {!user.email || !user.company_name ? (
                  <Stack spacing={4}>
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={36} width="40%" />
                  </Stack>
                ) : (
                  <>
                    <TextStyle
                      id="fullName"
                      {...register('fullName')}
                      defaultValue={user.full_name}
                      label="Your Full Name"
                      variant="outlined"
                      fullWidth
                      sx={{ marginY: '.5rem' }}
                    />
                    {errors.fullName && (
                      <Typography color="red" variant="caption">
                        {errors.fullName.message}
                      </Typography>
                    )}
                    <TextStyle
                      disabled
                      id="companyEmail"
                      {...register('companyEmail')}
                      defaultValue={user.email}
                      label="Your Company Email Address"
                      variant="outlined"
                      fullWidth
                      sx={{ marginY: '.5rem' }}
                    />
                    {errors.companyEmail && (
                      <Typography color="red" variant="caption">
                        {errors.companyEmail.message}
                      </Typography>
                    )}
                    <TextStyle
                      id="companyName"
                      defaultValue={user.company_name}
                      {...register('companyName')}
                      label="Company Name"
                      variant="outlined"
                      fullWidth
                      sx={{ marginY: '.5rem' }}
                    />
                    {errors.companyName && (
                      <Typography color="red" variant="caption">
                        {errors.companyName.message}
                      </Typography>
                    )}
                    <TextStyle
                      id="designation"
                      defaultValue={user.designation}
                      {...register('designation')}
                      label="Your Designation"
                      variant="outlined"
                      fullWidth
                      sx={{ marginY: '.5rem' }}
                    />
                    {errors.designation && (
                      <Typography color="red" variant="caption">
                        {errors.designation.message}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      disabled={!isDirty}
                      icon={<Save />}
                      text="Update Profile"
                      sx={{
                        alignSelf: 'flex-start',
                        bgcolor: 'primary.main',
                        borderRadius: '20px',
                        color: 'primary.black',
                        transition: 'all ease 0.3s',
                        fontWeight: 600,
                        '&:hover': {
                          transform: 'scale(1.01)',
                          bgcolor: 'secondary.yellow400',
                          color: 'primary.black',
                        },
                        p: 1,
                        px: 2,
                      }}
                    />
                  </>
                )}
              </FormWrapper>
            </Box>
          )}
        </Stack>
        <Stack>
          <Stack direction="row" alignItems="start" justifyContent="space-between">
            <Typography ml={{ xs: 0, md: '2rem' }} mb={{ xs: 1, md: 2 }} fontWeight={600}>
              {lawyerProfile ? 'Performance' : 'Usage'}
            </Typography>
            {/* <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={usageTime}
                label="Usage Time"
                displayEmpty
                onChange={handleChange}
                sx={{
                  color: 'secondary.grey100',
                  fontSize: '1rem',
                  fontWeight: 500,

                  px: '.85rem',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              >
                <MenuItem value="" sx={{ display: 'none' }}>
                  This Month
                </MenuItem>
                <MenuItem value="This Month">This Month</MenuItem>
                <MenuItem value="This Year">This Year</MenuItem>
              </Select>
            </FormControl> */}
          </Stack>
          <Box
            bgcolor="secondary.grey400"
            p={{ xs: '16px', md: '1.5rem' }}
            borderRadius="8px"
            sx={{ height: '100%', width: { xs: '15rem', smob: '21rem', sm: '35rem', md: '25rem', lg: '35rem' } }}
          >
            <Stack alignItems="center" pb={1}>
              <ImgWrapper src={lawyerProfile ? lawyerProfileImg : profile} alt="Profile" />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" mt={lawyerProfile && 4}>
              {lawyerProfile ? (
                <>
                  <Stack width="100%" spacing={1}>
                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        # OF CONSULTATIONS
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        {(lawyerMetrics && lawyerMetrics?.messages?.no_of_consultations) || '-'}
                      </Typography>
                    </Box>
                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        # OF UNIQUE CLIENTS
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        {(lawyerMetrics && lawyerMetrics?.messages?.no_of_unique_clients) || '-'}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack width="100%" spacing={1}>
                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        VIDEO CALL MINUTES
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        -
                      </Typography>
                    </Box>

                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        DOCUMENTS SENT
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        {(lawyerMetrics && lawyerMetrics?.messages?.documents_sent) || '-'}
                      </Typography>
                    </Box>
                  </Stack>
                </>
              ) : (
                <>
                  <Stack spacing={1}>
                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        # OF CHATS
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        {metrics && metrics.user_chat_count ? metrics.user_chat_count : 0}
                      </Typography>
                    </Box>
                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        # OF DOCUMENTS GENERATED
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        {metrics && metrics.documents_generated ? metrics.documents_generated : 0}
                      </Typography>
                    </Box>
                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        #OF EXPERT DOCUMENT REVIEWS
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        -
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack spacing={1}>
                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        TOTAL PAYMENTS
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        {metrics && metrics.total_payments
                          ? `₹ ${(metrics.total_payments / 100).toLocaleString('en-IN')}`
                          : 0}
                      </Typography>
                    </Box>

                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        #OF LAWYER CONSULTATIONS
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        {metrics && metrics.lawyer_consultation_count ? metrics.lawyer_consultation_count : 0}
                      </Typography>
                    </Box>
                    <Box borderRadius="8px" p=".6rem 1.25rem" bgcolor="secondary.bg" color="primary.white">
                      <Typography variant="body2" sx={{ fontSize: '.75rem' }}>
                        VIDEO CALL MINUTES
                      </Typography>
                      <Typography variant="h6" fontWeight={600} sx={{ fontSize: '1rem' }}>
                        -
                      </Typography>
                    </Box>
                  </Stack>
                </>
              )}
            </Stack>
          </Box>
        </Stack>
      </Stack>
      {/* {!lawyerProfile && !adminProfile && (
        <Container maxWidth="1248px" sx={{ maxWidth: '1288px', mt: { xs: '42px', md: 2 } }}>
          <TransactionTable sortedTransactions={sortedTransactions} />

          {transactionHistory?.metadata?.total_records > 10 && (
            <Pagination
              count={
                transactionHistory?.metadata?.total_records &&
                transactionHistory?.metadata?.page_size &&
                Math.ceil(transactionHistory.metadata.total_records / transactionHistory.metadata.page_size)
              }
              color="primary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: '1rem',
                '& .MuiButtonBase-root': { color: 'primary.white' },
              }}
              page={currentPage}
              onChange={handlePageChange}
            />
          )}
        </Container>
      )} */}
    </ProfileWrapper>
  );
};

export default Profile;
