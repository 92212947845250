import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import BotResponseImgLogoWrapper from './style';
import ChatLogo from '../../../assets/ChatLogo.png';
import { convertTimestampToFormattedDate } from '../../../utility/dateTimeUtils';
import convertPaiseToRupees from '../../../utility/PaiseToRupees';

const PaymentNotification = ({ timestamp, message }) => (
  <>
    {timestamp && (
      <Typography sx={{ fontSize: ' 0.75rem', color: 'secondary.light', textAlign: 'left' }}>
        {convertTimestampToFormattedDate(timestamp)}
      </Typography>
    )}
    <Stack
      bgcolor="chat.bg"
      borderRadius={2}
      color="primary.black"
      my=".5rem"
      width={{ xs: '100%', md: '98%' }}
      position="relative"
    >
      <Box display="flex" p={{ xs: 1, md: 2 }}>
        <BotResponseImgLogoWrapper src={ChatLogo} alt="bot-logo" />
        <Stack gap={{ xs: 1, md: 2 }}>
          <Typography fontWeight={600}>{convertPaiseToRupees(message)}</Typography>
        </Stack>
      </Box>
    </Stack>
  </>
);

PaymentNotification.propTypes = {
  timestamp: PropTypes.number,
  message: PropTypes.string,
};

PaymentNotification.defaultProps = {
  timestamp: '',
  message: '',
};

export default PaymentNotification;
