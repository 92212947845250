import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const ChatContainer = styled.div`
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  & ::-webkit-scrollbar {
    width: 0.5em;
  }

  & ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
`;

const ChatWrapper = styled.div`
  min-height: 100vh;
  max-width: ${({ theme }) => theme.breakpoints.values.lg}px;
  margin: auto;
`;

const TooltipSpanWrapper = styled.span`
  z-index: 999;
  position: fixed;
  top: 14% !important;
  right: 1.5% !important;
`;

// const shakeAnimation = keyframes`
//   0%, 100% {
//     transform: translateX(0);
//   }
//   10%, 30%, 50%, 70%, 90% {
//     transform: translateX(-1px);
//   }
//   20%, 40%, 60%, 80% {
//     transform: translateX(1px);
//   }
// `;

const blinkAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.2;

  }
  100% {
    transform: scale(1);
    opacity: 1;

  }
`;
export { ChatWrapper, TooltipSpanWrapper, blinkAnimation };
