import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import BotResponseImgLogoWrapper from './style';
import ChatLogo from '../../../assets/ChatLogo.png';
import { convertTimestampToFormattedDate } from '../../../utility/dateTimeUtils';
import Appointment from '../Appointment';
import convertPaiseToRupees from '../../../utility/PaiseToRupees';

const ScheduleNotification = ({ timestamp, message, lawyerDetails, isLawyerLoggedIn }) => {
  const { scheduleData } = useSelector((state) => state.chat);
  const { end_time, start_time, lawyer_details, created_at } = scheduleData;

  const msg = message.replace('/n', '\n');

  return (
    <>
      {(timestamp || created_at) && (
        <Typography sx={{ fontSize: ' 0.75rem', color: 'secondary.light', textAlign: 'left' }}>
          {convertTimestampToFormattedDate(timestamp || created_at)}
        </Typography>
      )}
      <Stack
        bgcolor="chat.bg"
        borderRadius={2}
        color="primary.black"
        my=".5rem"
        width={{ xs: '100%', md: '98%' }}
        position="relative"
      >
        <Box display="flex" p={{ xs: 1, md: 2 }}>
          <BotResponseImgLogoWrapper src={ChatLogo} alt="bot-logo" />
          <Stack gap={{ xs: 1, md: 2 }}>
            <Typography
              fontWeight={600}
              sx={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {convertPaiseToRupees(msg)}
            </Typography>
          </Stack>
        </Box>
        <Appointment
          startTime={start_time}
          endTime={end_time}
          lawyerDetails={
            Object.keys(lawyerDetails).length > 0 && !isLawyerLoggedIn && lawyerDetails.name
              ? lawyerDetails
              : lawyer_details
          }
        />
      </Stack>
    </>
  );
};

ScheduleNotification.propTypes = {
  timestamp: PropTypes.number,
  message: PropTypes.string,
  lawyerDetails: PropTypes.object,
  isLawyerLoggedIn: PropTypes.bool,
};

ScheduleNotification.defaultProps = {
  timestamp: '',
  message: '',
  isLawyerLoggedIn: false,
  lawyerDetails: {},
};

export default ScheduleNotification;
