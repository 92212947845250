/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Pagination, Stack, Typography, Skeleton } from '@mui/material';
import { Download } from 'react-basicons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import question from '../../assets/Question.png';
import { CustomDataTable, ImgWrapper, TableWrapper } from './style';
import { getSession } from '../../redux/actions/chatActions';
import { setSecondPersonDetails } from '../../redux/reducers/chat';
import convertPaiseToRupees from '../../utility/PaiseToRupees';

export const DocumentTable = ({ currentPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chatHistory } = useSelector((state) => state.chat);

  const extractFileNameFromPath = (path) => {
    const regex = /\/([^/]+)\.[^.]+$/;
    const match = path.match(regex);
    if (match) {
      return match[1];
    }
    return null;
  };

  const convertedMsg = (msg) => {
    const formattedMsg = msg.toLowerCase();
    if (
      formattedMsg.includes('₹ 0.0 received for lawyer consultation') ||
      formattedMsg.includes('₹ 0.0 received for document generation') ||
      formattedMsg.includes('0.0 received for lawyer consultation') ||
      formattedMsg.includes('0.0 received for document generation')
    ) {
      const newMsg = msg.includes('/n') ? msg.replace('/n', '\n') : msg;

      return convertPaiseToRupees(newMsg);
    }
    return msg;
  };

  const columns = [
    {
      name: 'CLIENT',
      selector: (row) => (
        <Box data-tag="allowRowEvents" sx={{ lineHeight: { xs: '1.5rem', lg: '30px' } }}>
          <Typography data-tag="allowRowEvents" variant="body2" fontSize=".875rem" textAlign="left">
            {row.users[0]?.full_name}
          </Typography>
          <Typography data-tag="allowRowEvents" variant="caption" textAlign="left">
            {row.users[0]?.email}
          </Typography>
        </Box>
      ),
      width: '15rem',
    },

    {
      name: 'CHAT DETAILS',
      selector: (row) => (
        <Typography
          overflow="hidden"
          textOverflow="ellipsis"
          data-tag="allowRowEvents"
          fontSize=".875rem"
          whiteSpace="pre"
        >
          {convertedMsg(row.session_name)}
        </Typography>
      ),
      width: '28rem',
    },
    {
      name: 'DOCUMENTS',
      selector: (row) => (
        <Stack data-tag="allowRowEvents" gap={1}>
          {row.user_documents.length > 0 ? (
            row.user_documents?.slice(-2).map((doc) => (
              <Stack data-tag="allowRowEvents" direction="row" alignItems="center" key={doc._id}>
                <Download data-tag="allowRowEvents" color="white" size={18} />
                <Typography data-tag="allowRowEvents" variant="caption" fontSize=".875rem" ml={1}>
                  {extractFileNameFromPath(doc.s3_doc_key)}
                </Typography>
              </Stack>
            ))
          ) : (
            <Typography data-tag="allowRowEvents" variant="body2" fontSize=".875rem" ml={1}>
              -
            </Typography>
          )}
        </Stack>
      ),
    },
  ];

  const handleRowSelected = (row) => {
    const secondPersonData = {
      name: row.users[0]?.full_name,
      company: row.users[0]?.company_name,
      email: row.users[0]?.email,
    };

    dispatch(setSecondPersonDetails(secondPersonData));
    navigate(`/chat/${row.session_id}`, { state: { currentPage } });
  };

  useEffect(() => {
    dispatch(getSession(currentPage));
  }, [currentPage]);

  const sortedChatHistory = chatHistory?.data;

  return (
    <TableWrapper>
      <Stack
        width="100%"
        direction={{ xs: 'column-reverse', md: 'row' }}
        sx={{ paddingBottom: '1rem', gap: '1rem' }}
        mb=".5rem"
        justifyContent="space-between"
        alignItems={{ xs: 'center', md: 'end' }}
      >
        <Typography variant="subtitle1" sx={{ fontSize: '1.125rem' }} fontWeight={600} color="primary.white">
          Clients
        </Typography>
        <Box>
          <ImgWrapper src={question} alt="Question" />
        </Box>
      </Stack>
      <Box width="100%" height="100%">
        {sortedChatHistory ? (
          <CustomDataTable columns={columns} data={sortedChatHistory} onRowClicked={handleRowSelected} />
        ) : (
          <Stack gap={1} pt={4}>
            {Array.from({ length: 5 }, (_, index) => (
              <Skeleton
                animation="wave"
                key={index}
                variant="rectangular"
                sx={{
                  borderRadius: '12px',
                }}
                height={66}
              />
            ))}
          </Stack>
        )}
      </Box>
    </TableWrapper>
  );
};

DocumentTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
};

const LawyerHome = () => {
  const { chatHistory } = useSelector((state) => state.chat);
  const location = useLocation();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (location.state && location.state.currentPage) {
      setCurrentPage(location.state.currentPage);
      navigate(location.pathname, { state: null });
    }
  }, [location]);
  return (
    <Box
      sx={{
        bgcolor: 'primary.bg',
        minHeight: '90vh',
      }}
    >
      {' '}
      <Container>
        <DocumentTable currentPage={currentPage} />{' '}
        {chatHistory?.metadata?.total_records > 10 && (
          <Pagination
            count={
              chatHistory?.metadata?.total_records &&
              chatHistory?.metadata?.page_size &&
              Math.ceil(chatHistory.metadata.total_records / chatHistory.metadata.page_size)
            }
            color="primary"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '1rem',
              '& .MuiButtonBase-root': { color: 'primary.white' },
            }}
            page={currentPage}
            onChange={handleChange}
          />
        )}
      </Container>
    </Box>
  );
};

export default LawyerHome;
