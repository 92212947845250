import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Box } from '@mui/material';
import ChatLogo from '../../../assets/ChatLogo.png';
import BotQuestionsImg from '../../../assets/BotQuestions.png';
import { BotResponseImgLogoWrapper, BotResponseDocImgWrapper } from './style';
import { convertTimestampToFormattedDate } from '../../../utility/dateTimeUtils';

const BotQuestions = ({ question, questionNo, totalQuestions, timestamp }) => (
  <>
    {timestamp && (
      <Typography sx={{ fontSize: ' 0.75rem', color: 'secondary.light', textAlign: 'left' }}>
        {convertTimestampToFormattedDate(timestamp)}
      </Typography>
    )}

    <Stack
      bgcolor="chat.bg"
      borderRadius={2}
      color="primary.black"
      my=".5rem"
      width={{ xs: '100%', md: '98%' }}
      position="relative"
    >
      {questionNo === 1 && (
        <Stack direction="row" p={{ xs: 1, md: 2 }}>
          <BotResponseImgLogoWrapper src={ChatLogo} alt="bot-logo" />
          <Stack gap={{ xs: 1, md: 2 }} maxWidth={{ xs: '98%', sm: 'calc(100% - 154px)' }}>
            <Typography fontWeight={600}>Kindly answer a few questions and provide necessary details</Typography>
            <Typography fontWeight={600}>
              Question {questionNo} of upto {totalQuestions}
            </Typography>

            <Typography>{question}</Typography>
          </Stack>
          <BotResponseDocImgWrapper src={BotQuestionsImg} alt="bot-doc-logo" />
        </Stack>
      )}

      {questionNo > 1 && (
        <Box display="flex" p={{ xs: 1, md: 2 }}>
          <BotResponseImgLogoWrapper src={ChatLogo} alt="bot-logo" />
          <Stack gap={{ xs: 1, md: 2 }}>
            <Typography fontWeight={600}>
              Question {questionNo} of upto {totalQuestions}
            </Typography>

            <Typography>{question}</Typography>
          </Stack>
        </Box>
      )}
    </Stack>
  </>
);

BotQuestions.propTypes = {
  question: PropTypes.string.isRequired,
  questionNo: PropTypes.number.isRequired,
  totalQuestions: PropTypes.number,
  timestamp: PropTypes.number.isRequired,
};

BotQuestions.defaultProps = {
  totalQuestions: 0,
};

export default BotQuestions;
