import { PropTypes } from 'prop-types';
import MuiButton from '@mui/material/Button';
import { CheckGoodYes, PaperFileText } from 'react-basicons';
import { Box, Typography } from '@mui/material';

const ToggleButton = ({ doc, isSelected, onClick, setSelectedDoc, flexGrow }) => {
  const handleToggle = (docObj) => {
    onClick(doc._id, docObj);
    setSelectedDoc(docObj);
  };

  return (
    <MuiButton
      disableElevation
      variant="contained"
      onClick={() => handleToggle(doc)}
      sx={{
        flexGrow,
        bgcolor: isSelected ? 'primary.main' : 'secondary.grey300',
        padding: '0.375rem 1.5rem',
        borderRadius: '.5rem',
        position: 'relative',
        justifyContent: 'space-between',
        width: '18rem',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <PaperFileText />
        {isSelected ? doc.selectedValue : doc.defaultValue}
        <Typography sx={{ fontSize: '0.875rem', textTransform: 'capitalize' }}>{doc.document_name}</Typography>
      </Box>

      {isSelected && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckGoodYes />
        </Box>
      )}
    </MuiButton>
  );
};

ToggleButton.propTypes = {
  doc: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  setSelectedDoc: PropTypes.func,
  flexGrow: PropTypes.number,
};

ToggleButton.defaultProps = {
  doc: null,
  isSelected: false,
  onClick: null,
  setSelectedDoc: null,
  flexGrow: 0,
};

export default ToggleButton;
