import styled from '@emotion/styled';

export const LogoWrapper = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;

  @media (max-width: 500px) {
    width: 162px !important;
  }
`;

export const ImageWrapper = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;

  @media (max-width: 475px) {
    display: none;
  }
`;
