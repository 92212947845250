import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton } from '@mui/material';

const Button = ({ text, icon, variant, sx, size, type, onClick, endIcon, disabled }) => (
  <MuiButton
    id={text}
    variant={variant}
    startIcon={icon}
    endIcon={endIcon}
    sx={sx}
    size={size}
    type={type}
    onClick={onClick}
    disableElevation
    disabled={disabled}
  >
    {text}
  </MuiButton>
);

Button.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  variant: PropTypes.string,
  sx: PropTypes.object,
  size: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  icon: null,
  endIcon: null,
  variant: '',
  sx: null,
  size: 'medium',
  type: 'button',
  onClick: null,
  disabled: false,
};
export default Button;
