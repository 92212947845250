import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  consultLawyerModal: false,
  signUpModal: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setConsultLawyerModal: (state, action) => ({
      ...state,
      consultLawyerModal: action.payload,
    }),
    setSignUpModal: (state, action) => ({
      ...state,
      signUpModal: action.payload,
    }),
  },
});

export const { setConsultLawyerModal, setSignUpModal } = modalSlice.actions;

export default modalSlice.reducer;
