import API from '../configs/api';
import DataService from '../configs/dataService/dataService';

const getDocService = (userDocId, doc_type) =>
  DataService.get(`${API.docs.getDoc}?user_document_id=${userDocId}&doc_type=${doc_type}`);
const getTotalDocCountService = (data) => DataService.get(`${API.docs.getTotalDocCount}?session_id=${data}`);
const getAllDocs = () => DataService.get(`${API.docs.getAllDocs}`);
const getSessionDocService = (data) => DataService.get(`${API.docs.getSessionDocs}?session_id=${data}`);
const uploadDocService = (data) =>
  DataService.post(`${API.docs.uploadDoc}`, data, { 'Content-Type': 'multipart/form-data' });

const checkDocumentStatusService = ({ sessionId, page, pageSize }) =>
  DataService.get(`${API.docs.docStatus}?session_id=${sessionId}&page=${page}&page_size=${pageSize}`);

export {
  getDocService,
  getTotalDocCountService,
  getAllDocs,
  getSessionDocService,
  uploadDocService,
  checkDocumentStatusService,
};
