import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Button as MuiButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowDropdownCaretSortSelectArrow,
  Download,
  PaperFileText,
  VideoCameraMedia,
  XSquareCloseDelete,
  AlertCirlcleError,
} from 'react-basicons';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import TextField from '../../common/TextField';
import UserQuery from '../UserQuery/UserQuery';
import BotResponse from '../BotResponse/BotResponse';
import ThanksForAns from '../ThanksForAns';
import BotQuestions from '../BotQuestions/BotQuestions';
import { ChatContainer, TooltipSpanWrapper } from './style';
import Button from '../../common/Button';
import SignUpModal from '../../common/Modal/SignupModal';
import { fetchChatFromHistory, session } from '../../../redux/actions/chatActions';
import {
  addChats,
  addDocLink,
  addUserDocDetails,
  addDocResultsToChat,
  clearChats,
  deleteFirstChatQuery,
  updateChats,
  setPaymentDoc,
  setPdfLink,
} from '../../../redux/reducers/chat';
import { selectIsLawyerLoggedIn, selectIsUserLoggedIn, selectSessionId } from '../../../redux/selectors/authSelectors';
import DocumentModal from '../../common/Modal/DocumentModal';
import MultiStepModal from '../../common/Modal/MultiStepModal';
import LawyerQuery from '../LawyerQuery/LawyerQuery';
import {
  downloadDoc,
  fetchHtml,
  fetchAllDocs,
  getDocumentCount,
  fetchSessionDocs,
  uploadDoc,
  checkDocStatusInChat,
} from '../../../redux/actions/docActions';
import ConsultLawyer from '../../common/Modal/ConsultLawyer';
import PaymentNotification from '../PaymentNotification';
import ScheduleNotification from '../ScheduleNotification';
import UploadDocNotification from '../UploadDocNotification/UploadDocNotification';
import { getUserAction } from '../../../redux/actions/authActions';
import PdfPreview from '../PdfPreview';
import RefillFormModal from '../../common/Modal/RefillFormModal';
import { formatDateForQuestions } from '../../../utility/dateTimeUtils';

const Chat = () => {
  const auth = useSelector((state) => state.auth);
  const chat = useSelector((state) => state.chat);

  const sessionId = useSelector(selectSessionId);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isLawyerLoggedIn = useSelector(selectIsLawyerLoggedIn);
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();

  const { user, lawyer } = auth;
  const {
    chats,
    StartChatQuery,
    userDoc,
    scheduleData,
    lawyerDoc,
    isLawyerJoined,
    secondPersonDetails,
    chatDocumentStatus,
  } = chat;
  const paymentDone = chat.paymentDoc;
  const { user_id } = user;
  const { created_at } = scheduleData;
  const [loading, setLoading] = useState(false);

  const [generatingDocLoading, setGeneratingDocLoading] = useState(true);
  const [isQA, setIsQA] = useState(false);
  const [serialNo, setSerialNo] = useState(1);
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [consultLawyerOpen, setConsultLawyerOpen] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [docText, setDocText] = useState('');
  const [anchorElSec, setAnchorElSec] = useState(null);
  const [anchorDownload, setAnchorDownload] = useState(null);
  const [sessionDocs, setSessionDocs] = useState([]);
  const [docName, setDocName] = useState('');
  const [selectedDoc, setSelectedDoc] = useState('');
  const [documentId, setDocumentId] = useState(null);
  const [uploadDocLoading, setUploadDocLoading] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [showDownloadText, setShowDownloadText] = useState(false);

  const [salutation, setSalutation] = useState('Mr.');
  const [updatedInput, setUpdatedInput] = useState('');
  const [openRefillModal, setOpenRefillModal] = useState(false);
  const [refillFormData, setRefillFormData] = useState(null);
  const [startRefilling, setStartRefilling] = useState(false);

  const names = ['Indemnification', 'Governing Law'];
  const [multiSelectOptions, setMultiSelectOptions] = useState([names[0]]);
  const [currency, setCurrency] = useState('Rupees');

  const [isSocketOpen, setIsSocketOpen] = useState(false);

  const inProgressForms = chatDocumentStatus.filter((item) => item.status === 'IN_PROGRESS');

  const openRight = Boolean(anchorElSec);
  const openDownload = Boolean(anchorDownload);

  const inputRef = useRef(null);
  const socketRef = useRef(null);
  const botIdRef = useRef(null);
  const generateDocRef = useRef(null);
  const chatContainerRef = useRef(null);

  const myTheme = useTheme();
  const isMd = useMediaQuery((t) => t.breakpoints.down('md'));
  const isSm = useMediaQuery((t) => t.breakpoints.down('sm'));

  const primaryColor = myTheme.palette.primary.main;

  const thanksHeadText = 'Thank you for answering the necessary questions to generate the document';
  const thanksFootText = 'Magic time. Generating your document';

  const questionsLimit = 3;

  const handleRefillOpen = () => {
    setOpenRefillModal(true);
  };

  const handleRefillClose = () => {
    setOpenRefillModal(false);
  };

  const extractFileNameWithExtFromPath = (url) => {
    const regex = /\/([^/]+\.[^/]+)$/;
    const match = url.match(regex);
    if (match) {
      return match[1];
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (updatedInput === 'SELECT_MENU') {
      const salut = salutation !== 'None' ? salutation : '';
      inputRef.current.value = `${salut} ${inputRef.current.value}`;
    }

    let formattedDate;
    if (updatedInput === 'DATE') {
      formattedDate = formatDateForQuestions(inputRef.current.value);
    }

    if (updatedInput === 'SELECT_MENU_CURRENCY') {
      inputRef.current.value = `${currency} ${inputRef.current.value}`;
    }

    if (updatedInput === 'MULTI_SELECT_MENU') {
      const val = inputRef.current.value;
      const concatenatedValue = `${multiSelectOptions.join(', ')}${val ? `, ${val}` : ''}`;
      inputRef.current.value = concatenatedValue;
    }

    if (updatedInput === 'MULTILINE_BULLET_POINT') {
      const val = inputRef.current.value;
      const lines = val.split('\n');

      const bulletedLines = lines.map((line) => `• ${line}`);

      const bulletedValue = bulletedLines.join('\n');

      inputRef.current.value = bulletedValue;
    }

    if (inputRef.current.value.length > 0) {
      if (isUserLoggedIn || isLawyerLoggedIn) {
        if (isQA) {
          if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(
              JSON.stringify({
                type: 'DOCUMENT_ANSWER',
                document_id: documentId || userDoc.doc_id,
                serial_number: serialNo,
                message: updatedInput === 'DATE' ? formattedDate : inputRef.current.value,
                user_document_id: userDoc.user_doc_id,
              }),
            );
            inputRef.current.value = '';

            setSerialNo((prevSerialNo) => prevSerialNo + 1);

            if (serialNo < userDoc.totalDocQuestions) {
              socketRef.current.send(
                JSON.stringify({
                  type: 'QUESTION_INDEX',
                  document_id: documentId || userDoc.doc_id,
                  serial_number: serialNo + 1,
                  user_document_id: userDoc.user_doc_id,
                }),
              );
            }
          }
        } else if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
          socketRef.current.send(JSON.stringify({ message: inputRef.current.value }));
        }
      } else if (chats.length < questionsLimit * 2) {
        // question + ans
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
          socketRef.current.send(JSON.stringify({ message: inputRef.current.value }));
        }
      } else {
        setSignupOpen(true);
      }

      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const handlePreviewDoc = async (link) => {
    if (link.includes('.pdf')) {
      await dispatch(setPdfLink(link));
      setShowPdf(true);
      setShowDownloadText(false);
    } else {
      await dispatch(fetchHtml(link));
      setShowPdf(false);
      setShowDownloadText(false);
    }
    setShowDoc(true);
  };

  const handleDocClick = async () => {
    if (showDoc) {
      setShowDoc(false);
      return;
    }

    if ((isUserLoggedIn || isLawyerLoggedIn) && userDoc?.totalCount > 0) {
      const docs = await dispatch(fetchSessionDocs(id || sessionId));
      setSessionDocs(docs);
      const latestDoc = docs && docs[docs.length - 1];
      handlePreviewDoc(latestDoc?.file_url);
      setSelectedDoc(latestDoc?.user_document_id);
      setDocName(extractFileNameWithExtFromPath(latestDoc?.file_name));
      setShowDoc(true);
    }
  };

  const fetchDocs = async () => {
    const docs = await dispatch(fetchSessionDocs(id || sessionId));
    setSessionDocs(docs);
  };

  const handleClose = () => {
    setAnchorElSec(null);
  };

  const handleCloseAnchorDownload = () => {
    setAnchorDownload(null);
  };

  const downloadFile = (url, doc_type) => {
    const downloadLink = url;
    const link = document.createElement('a');
    link.href = downloadLink;
    link.download = 'filename';
    doc_type === 'PDF' && (link.target = '_blank');
    link.rel = 'noopener noreferrer';
    link.click();
  };

  const handleDownload = async (userDocId, docType) => {
    const res = await dispatch(downloadDoc(userDocId, docType));
    downloadFile(res, docType);
  };

  const handleShowDoc = (link, docId, file_name) => {
    if (file_name.includes('.pdf') || link.includes('.pdf') || file_name.includes('.html') || link.includes('.html')) {
      handlePreviewDoc(link);
      setSelectedDoc(docId);
      setDocName(extractFileNameWithExtFromPath(file_name));
      return;
    }
    setShowDownloadText(true);
  };

  const handleConsultLawyer = async () => {
    setConsultLawyerOpen(true);
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === 'application/pdf' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    ) {
      setUploadDocLoading(true);
      await dispatch(
        uploadDoc({
          document_id: '64cc8d86aeca6f821d3d3ea3',
          session_id: id || sessionId,
          user_id: lawyer.user_id,
          pdf_file: file,
        }),
      );

      setUploadDocLoading(false);
    } else {
      alert('Please select a valid PDF or DOCX file.');
    }
  };

  useEffect(() => {
    if (Object.keys(lawyerDoc).length !== 0 && isLawyerLoggedIn) {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(
          JSON.stringify({
            sender: 'chatgpt@gmail.com',
            sender_type: 'LAWYER',
            message: lawyerDoc?.url,
            file_name: lawyerDoc?.file_name,
            type: 'DOCUMENT_UPLOAD',
            user_document_id: lawyerDoc?.user_document_id,
            questions: { user_document_id: lawyerDoc?.user_document_id },
          }),
        );
      }
    }
  }, [lawyerDoc]);

  useEffect(() => {
    if (id) {
      const chatPage = 1;
      dispatch(clearChats());
      dispatch(fetchChatFromHistory(id, chatPage));
    }
  }, [id]);

  useEffect(() => {
    if (Object.keys(StartChatQuery).length !== 0) {
      if ((!isUserLoggedIn || !isLawyerLoggedIn) && chats.length < 10) {
        setTimeout(() => {
          if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ message: StartChatQuery.userQuery }));
          }
        }, 500);
      } else if (isUserLoggedIn || isLawyerLoggedIn) {
        setTimeout(() => {
          if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ message: StartChatQuery.userQuery }));
          }
        }, 500);
      } else {
        setSignupOpen(true);
      }
    }

    return () => {
      if (Object.keys(StartChatQuery).length !== 0) {
        deleteFirstChatQuery();
      }
    };
  }, [StartChatQuery?.userQuery]);

  useEffect(() => {
    if (id || sessionId) {
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCK_ENDPOINT}/ws/chat/${id || sessionId}/${
          isLawyerLoggedIn ? lawyer.user_id : user_id
        }`,
      );

      socketRef.current.onopen = () => {
        setIsSocketOpen(true);
      };

      socketRef.current.onmessage = async (event) => {
        const message = event.data;

        const msgObj = JSON.parse(message);

        if (msgObj.type === 'start') {
          botIdRef.current = uuidv4();
          generateDocRef.current = uuidv4();
          setLoading(true);
          inputRef.current.value = '';
        }

        if (msgObj.type === 'stream') {
          if (msgObj.sender_type === 'USER' || msgObj.sender_type === 'TEMP_USER') {
            if (msgObj.message !== '') {
              const userChatObj = {
                id: uuidv4(),
                sender: msgObj.sender,
                sender_type: msgObj.sender_type,
                userQuery: msgObj.message,
                timestamp: msgObj.created_at,
              };

              dispatch(addChats(userChatObj));
            }
          }

          if (msgObj.sender_type === 'LAWYER') {
            if (msgObj.message !== '') {
              const userChatObj = {
                id: uuidv4(),
                sender: msgObj.sender,
                sender_type: msgObj.sender_type,
                userQuery: msgObj.message,
                timestamp: msgObj.created_at,
              };

              dispatch(addChats(userChatObj));
            }
          }

          if (msgObj.sender_type === 'BOT') {
            if (!botIdRef.current) botIdRef.current = uuidv4();

            const botChatObj = {
              id: botIdRef.current,
              sender: msgObj.sender,
              sender_type: msgObj.sender_type,
              botResponse: msgObj.message,
              loading: true,
              isQA: false,
            };

            dispatch(addChats(botChatObj));
          }
        }

        if (msgObj.type === 'payment') {
          const paymentObj = {
            id: uuidv4(),
            sender: msgObj.sender,
            sender_type: 'BOT',
            message: msgObj.message,
            timestamp: msgObj.created_at,
            isPaid: true,
          };
          dispatch(addDocResultsToChat(paymentObj));
        }

        if (msgObj.type === 'questions') {
          if (msgObj.questions) {
            setIsQA(true);

            if (msgObj.questions.question.question_type || msgObj.questions.question_type)
              setUpdatedInput(msgObj.questions.question.question_type || msgObj.questions.question_type);

            const questionObject = {
              id: uuidv4(),
              sender: msgObj.sender,
              sender_type: 'BOT',
              botResponse: msgObj.questions.question.question || msgObj.questions.question,
              totalQuestions: msgObj.questions.total_length,
              isQA: true,
              questionNo: msgObj.questions.question.serial_number || msgObj.questions.serial_number,
              ...(msgObj.created_at && { timestamp: msgObj.created_at }),
            };

            msgObj.questions.total_length &&
              dispatch(
                addUserDocDetails({
                  totalDocQuestions: msgObj.questions.total_length,
                  user_doc_id: msgObj.questions.user_document_id,
                }),
              );

            dispatch(addChats(questionObject));
          }
        }

        if (!generateDocRef.current) generateDocRef.current = uuidv4();

        if (msgObj.type === 'questions_ended') {
          const answeredObj = {
            id: generateDocRef.current,
            sender: msgObj.sender,
            sender_type: 'BOT',
            isAnswered: true,
            isGenerated: true,
            timestamp: msgObj.created_at,
            file_name: msgObj.file_name,
          };
          dispatch(addDocResultsToChat(answeredObj));
          setIsQA(false);
          setIsAnswered(true);
        }

        if (msgObj.type === 'document_generated') {
          const answeredObj = {
            id: generateDocRef.current,
            sender: msgObj.sender,
            sender_type: 'BOT',
            isAnswered: true,
            isGenerated: false,
            user_document_id: msgObj.questions.user_document_id,
            doc_link: msgObj.message,
            timestamp: msgObj.created_at,
            file_name: msgObj.file_name,
          };
          dispatch(getDocumentCount(id || sessionId));
          dispatch(setPaymentDoc(false));
          dispatch(addDocResultsToChat(answeredObj));
          dispatch(addDocLink(msgObj?.message));
          setIsQA(false);
          setSerialNo(1);
          setGeneratingDocLoading(false);
          generateDocRef.current = uuidv4();
        }

        if (msgObj.type === 'end') {
          setLoading(false);
          inputRef.current.value = '';
          StartChatQuery?.userQuery && dispatch(deleteFirstChatQuery());
        }

        if (msgObj.type === 'suggested_prompt') {
          const { prompts } = msgObj;
          dispatch(updateChats({ id: botIdRef.current, prompts, timestamp: msgObj.created_at }));
          inputRef.current.value = '';
          StartChatQuery?.userQuery && dispatch(deleteFirstChatQuery());
        }

        if (msgObj.type === 'document_upload') {
          const lawyerName = await dispatch(getUserAction(msgObj.sender));
          const lawyerUploadSuccessObj = {
            id: uuidv4(),
            sender: 'chatgpt@gmail.com',
            sender_type: 'BOT',
            uploadedBy: lawyerName.full_name,
            message: '',
            timestamp: msgObj.created_at,
            isUploaded: true,
            file_name: msgObj.questions.file_name,
            docLink: msgObj.message,
            userDocId: msgObj.questions.user_document_id,
            filledBy: 'LAWYER',
          };
          dispatch(addDocResultsToChat(lawyerUploadSuccessObj));
          dispatch(getDocumentCount(sessionId || id));
        }
      };

      socketRef.current.onclose = () => {
        setIsSocketOpen(false);
      };
    }
    return () => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(
          JSON.stringify({
            type: 'dismissal',
          }),
        );
        socketRef.current.close();
      }
    };
  }, [sessionId, id, dispatch, isUserLoggedIn]);

  useEffect(() => {
    if (paymentDone && userDoc.user_doc_id) {
      if (isSocketOpen && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(
          JSON.stringify({
            type: 'QUESTION_INITIATED',
            document_id: documentId || userDoc.doc_id,
            user_document_id: userDoc.user_doc_id,
          }),
        );
      }
    }
  }, [paymentDone, isSocketOpen]);

  useEffect(() => {
    if (id) {
      dispatch(getDocumentCount(id));
      isUserLoggedIn && dispatch(checkDocStatusInChat({ sessionId: id, page: 1, pageSize: 10 }));
    } else if (sessionId) {
      isUserLoggedIn && dispatch(getDocumentCount(sessionId));
    } else {
      dispatch(session());
    }
  }, [sessionId, dispatch, id]);

  useEffect(() => {
    isUserLoggedIn && dispatch(fetchAllDocs());

    return () => {
      dispatch(setPaymentDoc(false));
    };
  }, []);

  useEffect(() => {
    if (!generatingDocLoading) {
      const showLatestDoc = async () => {
        const docs = await dispatch(fetchSessionDocs(id || sessionId));
        setSessionDocs(docs);
        const latestDoc = docs && docs[docs.length - 1];
        handlePreviewDoc(latestDoc?.file_url);
        setDocName(extractFileNameWithExtFromPath(latestDoc?.file_name));
        setSelectedDoc(latestDoc?.user_document_id);
        setGeneratingDocLoading(true);
      };
      showLatestDoc();
      handleDocClick();
    }
  }, [generatingDocLoading]);

  useEffect(() => {
    if (location.state && location.state.consultLawyer) {
      if (!isUserLoggedIn) {
        setSignupOpen(true);
        return;
      }

      setConsultLawyerOpen(true);

      navigate('/chat', { replace: true, state: { ...location.state, consultLawyer: false } });
    }

    if (location.state && location.state.isScheduled) {
      if (!id) {
        const scheduledObj = {
          id: uuidv4(),
          sender: 'chatgpt@gmail.com',
          sender_type: 'BOT',
          message: ' 0.0 received for lawyer consultation. \n You have scheduled a 30 minutes video call with',
          timestamp: created_at,
          isScheduled: true,
        };
        dispatch(addDocResultsToChat(scheduledObj));
      }
      if (location.state.id) {
        navigate(`/chat/${location.state.id}`, {
          replace: true,
          state: { ...location.state, isScheduled: false },
        });
        return;
      }

      navigate(`/chat`, {
        replace: true,
        state: { ...location.state, isScheduled: false },
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (!location.pathname.includes(`/chat/${id}`) || chats.length < 20) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chats, isAnswered, generatingDocLoading]);

  useEffect(() => {
    if (refillFormData && Object.keys(refillFormData).length > 0 && startRefilling) {
      setOpenRefillModal(false);

      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(
          JSON.stringify({
            type: 'QUESTION_INITIATED',
            document_id: refillFormData.document_id,
            user_document_id: refillFormData._id,
          }),
        );
      }
    }
  }, [startRefilling, socketRef.current?.readyState]);

  const fetchMoreData = () => {
    if (chats.length >= 20) {
      const nextPage = Math.ceil(chats.length / 20) + 1;
      dispatch(fetchChatFromHistory(id, nextPage));
    }
  };

  return (
    <Box maxWidth="xl" mx="auto">
      <Grid container px={2} spacing={2}>
        <Grid
          sx={{ display: isMd && showDoc && 'none', transition: 'all ease-in-out 0.2s' }}
          item
          xs={12}
          md={showDoc ? 6 : 12}
        >
          <Container
            maxWidth="1000px"
            sx={{
              padding: '0 16px !important',
              px: { xs: '0 !important', md: '16px !important' },
              maxWidth: '1000px',
            }}
          >
            <RefillFormModal
              inProgressForms={inProgressForms}
              openRefillModal={openRefillModal}
              handleRefillClose={handleRefillClose}
              setStartRefilling={setStartRefilling}
              setRefillFormData={setRefillFormData}
              setDocId={setDocumentId}
            />

            {!showDoc && inProgressForms && inProgressForms.length > 0 && (
              <MuiButton
                onClick={handleRefillOpen}
                sx={{
                  bgcolor: 'secondary.grey400',
                  zIndex: '999',
                  borderRadius: '50%',
                  position: 'fixed',
                  top: '17%',
                  right: '1.5%',
                  minWidth: 'max-content',
                  // animation: `${blinkAnimation} 2s ease-in-out infinite`,
                }}
              >
                <AlertCirlcleError color={primaryColor} />
              </MuiButton>
            )}
            {userDoc?.totalCount > 0 ? (
              <span>
                {!showDoc && (
                  <MuiButton
                    onClick={handleDocClick}
                    sx={{
                      bgcolor: 'secondary.grey400',
                      zIndex: '999',

                      borderRadius: '20px',
                      position: 'fixed',
                      top: '10%',
                      right: '1.5%',
                      transition: 'all ease-in-out 0.4s',
                      '&:hover': {
                        bgcolor: 'secondary.grey666',
                      },
                    }}
                    startIcon={<PaperFileText color={primaryColor} />}
                  >
                    {!isUserLoggedIn && !isLawyerLoggedIn ? 0 : userDoc?.totalCount || 0}
                  </MuiButton>
                )}
              </span>
            ) : (
              <Tooltip title="No documents found">
                <TooltipSpanWrapper>
                  {!showDoc && (
                    <MuiButton
                      onClick={handleDocClick}
                      sx={{
                        bgcolor: 'secondary.grey400',
                        zIndex: '999',
                        borderRadius: '20px',
                        position: 'fixed',
                        top: '10%',
                        right: '1.5%',
                        '&:hover': {
                          bgcolor: 'secondary.grey400',
                        },
                      }}
                      startIcon={<PaperFileText color={primaryColor} />}
                    >
                      {!isUserLoggedIn && !isLawyerLoggedIn ? 0 : userDoc?.totalCount || 0}
                    </MuiButton>
                  )}
                </TooltipSpanWrapper>
              </Tooltip>
            )}

            <Box height="calc(100vh - 94px)" p={1} position="relative">
              <ConsultLawyer open={consultLawyerOpen} setOpen={setConsultLawyerOpen} doc={docName} />
              {!isUserLoggedIn && signupOpen && (
                <SignUpModal open={signupOpen} setOpen={setSignupOpen} userFingerprint={auth.user.fingerprint} />
              )}
              {!isUserLoggedIn && open && (
                <MultiStepModal
                  open={open}
                  setOpen={setOpen}
                  docId={documentId}
                  step={step}
                  setStep={setStep}
                  userFingerprint={auth.user.fingerprint}
                />
              )}
              {isUserLoggedIn && open && (
                <DocumentModal docText={docText} open={open} setOpen={setOpen} docId={documentId} id={id} />
              )}

              <ChatContainer>
                <Box
                  ref={chatContainerRef}
                  height="calc(100vh - 180px)"
                  overflow="auto"
                  display="flex"
                  flexDirection={id && chats.length > 10 ? 'column-reverse' : 'column'}
                  gap={0.4}
                  py={2}
                  component="div"
                  id="scrollableDiv"
                >
                  <InfiniteScroll
                    dataLength={chats.length}
                    next={fetchMoreData}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    inverse={true}
                    hasMore={true}
                    scrollableTarget="scrollableDiv"
                  >
                    {chats &&
                      chats
                        .slice()
                        .reverse()
                        .map((msg) => (
                          <div key={msg.id}>
                            {msg.sender_type === 'USER' && isUserLoggedIn && (
                              <UserQuery query={msg.userQuery} user="true" timestamp={msg.timestamp} />
                            )}
                            {msg.sender_type === 'USER' && isLawyerLoggedIn && (
                              <UserQuery
                                query={msg.userQuery}
                                user="false"
                                timestamp={msg.timestamp}
                                client={msg.name || secondPersonDetails.name}
                              />
                            )}
                            {msg.sender_type === 'LAWYER' && isUserLoggedIn && (
                              <LawyerQuery query={msg.userQuery} lawyer="false" timestamp={msg.timestamp} />
                            )}
                            {msg.sender_type === 'LAWYER' && isLawyerLoggedIn && (
                              <LawyerQuery query={msg.userQuery} lawyer="true" timestamp={msg.timestamp} />
                            )}

                            {msg.sender_type === 'TEMP_USER' && (
                              <UserQuery query={msg.userQuery} user="true" timestamp={msg.timestamp} />
                            )}
                            {msg.sender_type === 'BOT' && !msg.isQA && msg.botResponse && (
                              <BotResponse
                                response={msg.botResponse}
                                setOpen={setOpen}
                                loading={msg.loading}
                                prompts={msg.prompts}
                                setDocText={setDocText}
                                setDocId={setDocumentId}
                                {...(msg.timestamp && { timestamp: msg.timestamp })}
                              />
                            )}

                            {msg.sender_type === 'BOT' && msg.isPaid && (
                              <PaymentNotification message={msg.message} timestamp={msg.timestamp} />
                            )}

                            {msg.sender_type === 'BOT' && msg.isQA && (
                              <BotQuestions
                                question={msg.botResponse}
                                questionNo={msg.questionNo}
                                totalQuestions={userDoc?.totalDocQuestions}
                                timestamp={msg.timestamp}
                                setDocId={setDocumentId}
                              />
                            )}
                            {msg.sender_type === 'BOT' && msg.isAnswered && msg.isGenerated && (
                              <ThanksForAns
                                thanksHeadText={thanksHeadText}
                                thanksFootText={thanksFootText}
                                generatingDocLoading={msg.isGenerated}
                                downloadFile={handleDownload}
                                docId={msg.user_document_id}
                                handlePreviewDoc={handlePreviewDoc}
                                timestamp={msg.timestamp}
                                filename={msg.file_name}
                                handleDocClick={handleDocClick}
                                fetchDocs={fetchDocs}
                                setDocName={setDocName}
                              />
                            )}
                            {msg.sender_type === 'BOT' && msg.isAnswered && !msg.isGenerated && (
                              <ThanksForAns
                                thanksHeadText={thanksHeadText}
                                thanksFootText={thanksFootText}
                                generatingDocLoading={msg.isGenerated}
                                downloadFile={handleDownload}
                                docId={msg.user_document_id}
                                handlePreviewDoc={handlePreviewDoc}
                                timestamp={msg.timestamp}
                                filename={msg.file_name}
                                handleDocClick={handleDocClick}
                                fetchDocs={fetchDocs}
                                setDocName={setDocName}
                              />
                            )}

                            {msg.sender_type === 'BOT' && msg.isScheduled && (
                              <ScheduleNotification
                                message={msg.message}
                                timestamp={msg.timestamp}
                                lawyerDetails={secondPersonDetails}
                                isLawyerLoggedIn={isLawyerLoggedIn}
                              />
                            )}

                            {msg.sender_type === 'BOT' && msg.isUploaded && (
                              <UploadDocNotification
                                message={msg.message}
                                sender={secondPersonDetails.name || msg.uploadedBy}
                                timestamp={msg.timestamp}
                                docId={msg.userDocId}
                                downloadFile={handleDownload}
                                handlePreviewDoc={handlePreviewDoc}
                                filename={msg.file_name}
                                handleDocClick={handleDocClick}
                                isUploaded={msg.isUploaded}
                                isLawyerLoggedIn={isLawyerLoggedIn}
                                fetchDocs={fetchDocs}
                                setDocName={setDocName}
                                filledBy={msg.filledBy}
                                setSelectedDoc={setSelectedDoc}
                              />
                            )}

                            {msg.sender_type === 'BOT' && msg.isFormFilled && (
                              <UploadDocNotification
                                message={msg.doc_name}
                                timestamp={msg.timestamp}
                                docId={msg.userDocId}
                                downloadFile={handleDownload}
                                handlePreviewDoc={handlePreviewDoc}
                                filename={msg.doc_name}
                                handleDocClick={handleDocClick}
                                filledBy={msg.filledBy}
                                uploadedBy={msg.name}
                                isLawyerLoggedIn={isLawyerLoggedIn}
                                fetchDocs={fetchDocs}
                                setDocName={setDocName}
                                setSelectedDoc={setSelectedDoc}
                              />
                            )}
                          </div>
                        ))}
                  </InfiniteScroll>
                </Box>
              </ChatContainer>

              <Box position="absolute" bottom="0" left="0" right="0">
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    inputRef={inputRef}
                    placeholder={isLawyerJoined ? 'Ask a query...' : 'Ask a follow-up prompt/query...'}
                    type="submit"
                    loading={loading}
                    isLawyerLoggedIn={isLawyerLoggedIn}
                    isUploading={uploadDocLoading}
                    handleUpload={handleUpload}
                    salutation={salutation}
                    setSalutation={setSalutation}
                    updatedInput={updatedInput}
                    handleSubmit={handleSubmit}
                    names={names}
                    setMultiSelectOptions={setMultiSelectOptions}
                    multiSelectOptions={multiSelectOptions}
                    currency={currency}
                    setCurrency={setCurrency}
                  />
                </form>
              </Box>
            </Box>
          </Container>
        </Grid>
        {showDoc && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                bgcolor: 'primary.white',
                color: 'primary.black',
                borderRadius: '8px',
                overflow: 'auto',
                minHeight: 'calc(100vh - 76px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box bgcolor="chat.bg" borderRadius="8px" padding={{ xs: 1, md: '0.75rem 1.5rem' }}>
                <Stack direction="row" gap={5} alignItems="center" justifyContent="space-between">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={{ xs: 1.5, md: 2 }}
                    width={{ xs: 'auto', lg: '17.5rem' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={{ xs: 1.5, md: 2 }}
                      width={{ xs: 'auto', lg: '17.5rem' }}
                      onClick={(event) => setAnchorElSec(event.currentTarget)}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight={600}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {docName}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ borderRadius: '.5rem', bgcolor: 'primary.white200' }}
                      >
                        <ArrowDropdownCaretSortSelectArrow />
                      </Box>
                    </Stack>

                    <Menu
                      anchorEl={anchorElSec}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                      open={openRight}
                      onClose={handleClose}
                      onClick={handleClose}
                      sx={{
                        mt: { xs: '2%', xl: '1.5%' },

                        '& .MuiPaper-root': {
                          maxWidth: { xs: '10rem', sm: '16.5rem' },
                          top: '96px !important',
                          backgroundColor: 'secondary.blackShade',
                        },
                      }}
                    >
                      {sessionDocs &&
                        sessionDocs.map((doc) => (
                          <MenuItem
                            key={doc.user_document_id}
                            sx={{ fontSize: '.9rem' }}
                            onClick={() => handleShowDoc(doc.file_url, doc.user_document_id, doc.file_name)}
                          >
                            <Stack color="primary.black" direction="row" alignItems="center" justifyContent="center">
                              <Typography
                                color="primary.black"
                                fontWeight={
                                  docName === extractFileNameWithExtFromPath(doc.file_name) ? 'bold' : 'normal'
                                }
                                variant="body2"
                              >
                                {extractFileNameWithExtFromPath(doc.file_name)}
                              </Typography>
                            </Stack>
                          </MenuItem>
                        ))}
                    </Menu>
                  </Stack>

                  <Stack gap={2} direction="row" alignItems="center" justifyContent="center">
                    {!isSm && !isLawyerLoggedIn && !isLawyerJoined && !Object.keys(scheduleData).length > 0 ? (
                      <Button
                        onClick={handleConsultLawyer}
                        variant="contained"
                        text="Consult Lawyer"
                        sx={{
                          minWidth: { xs: 'auto', md: '168px' },
                          bgcolor: 'primary.main',
                          textTransform: 'capitalize ',
                          fontWeight: 600,
                          borderRadius: '20px',
                          fontSize: '.875rem',
                          color: 'primary.black',
                          transition: 'all ease 0.3s',
                          '&:hover': {
                            transform: 'scale(1.01)',
                            bgcolor: 'secondary.yellow400',
                            color: 'primary.black',
                          },
                        }}
                        icon={<VideoCameraMedia />}
                      />
                    ) : (
                      !isLawyerLoggedIn &&
                      !isLawyerJoined &&
                      !Object.keys(scheduleData).length > 0 && (
                        <Button
                          onClick={handleConsultLawyer}
                          variant="contained"
                          sx={{
                            bgcolor: 'primary.main',
                            textTransform: 'capitalize ',
                            fontWeight: 600,
                            borderRadius: '20px',
                            fontSize: '.875rem',
                            color: 'primary.black',
                            transition: 'all ease 0.3s',
                            '&:hover': {
                              transform: 'scale(1.01)',
                              bgcolor: 'secondary.yellow400',
                              color: 'primary.black',
                            },
                            '& .MuiButton-startIcon': {
                              mx: 0,
                            },
                          }}
                          icon={<VideoCameraMedia />}
                        />
                      )
                    )}

                    <IconButton
                      onClick={(e) => setAnchorDownload(e.currentTarget)}
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'secondary.main',
                        borderRadius: '20px',
                      }}
                    >
                      <Download />
                    </IconButton>
                    <Menu anchorEl={anchorDownload} open={openDownload} onClose={handleCloseAnchorDownload}>
                      <MenuItem sx={{ fontSize: '.9rem' }} onClick={() => handleDownload(selectedDoc, 'PDF')}>
                        <Typography variant="body2" fontWeight={600} color="primary.black">
                          PDF
                        </Typography>
                      </MenuItem>
                      <MenuItem sx={{ fontSize: '.9rem' }} onClick={() => handleDownload(selectedDoc, 'DOCX')}>
                        <Typography variant="body2" fontWeight={600} color="primary.black">
                          DOCX
                        </Typography>
                      </MenuItem>
                    </Menu>
                    <Box
                      p="5px"
                      onClick={() => setShowDoc(false)}
                      sx={{
                        borderRadius: '50%',
                        border: (theme) => `2px solid ${theme.palette.primary.main}`,
                        backgroundColor: 'primary.light',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <XSquareCloseDelete />
                    </Box>
                  </Stack>
                </Stack>
              </Box>
              <Stack
                spacing={1}
                p={2}
                sx={{
                  overflow: 'auto',
                  height: '1vh',
                  flexGrow: 1,
                }}
              >
                {/* eslint-disable no-nested-ternary */}
                {showDownloadText ? (
                  <Typography
                    width="100%"
                    variant="body2"
                    fontWeight={600}
                    sx={{
                      fontSize: '1rem',
                      textAlign: 'center',
                    }}
                    color="primary.black"
                  >
                    Couldn&apos;t Preview file
                    <br />
                    There was a problem with the preview.
                  </Typography>
                ) : showPdf ? (
                  <PdfPreview link={userDoc?.pdfLink} />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: userDoc?.htmlContent }} />
                )}
              </Stack>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Chat;
