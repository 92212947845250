import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import { EmailMessageInbox } from 'react-basicons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BannerWrapper, ImgWrapper } from '../Login/style';
import forgotPwd from '../../assets/forgotPwd.png';
import Button from '../../components/common/Button';
import { forgotPassword } from '../../redux/actions/authActions';
import ShowToastMessage from '../../utility/showToastMessage';
import TextStyle from '../../components/common/TextStyle';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSuccess = () => {
    ShowToastMessage('success', 'Reset password link sent to your email');
  };

  const onSubmit = (data) => {
    const json = JSON.stringify(data);
    dispatch(forgotPassword(json, onSuccess));
  };

  return (
    <BannerWrapper>
      <Box
        bgcolor="primary.white"
        width={{ xs: 'auto', sm: '40%', lg: '560px' }}
        p={{ xs: 2, md: 4 }}
        sx={{ borderRadius: '8px' }}
      >
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.black" fontWeight={600} lineHeight={1.5}>
            Forgot Password
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextStyle
              type="email"
              id="email"
              label="Email"
              {...register('email')}
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
            />
            {errors.email && (
              <Typography color="red" variant="caption">
                {errors.email.message}
              </Typography>
            )}

            <Stack direction="row" justifyContent="space-between" pt={2}>
              <Button
                type="submit"
                text="Send Reset Link"
                icon={<EmailMessageInbox />}
                variant="contained"
                size="small"
                sx={{
                  fontWeight: 600,
                  bgcolor: 'primary.main',
                  borderRadius: '20px',
                  color: 'primary.black',
                  transition: 'all ease 0.3s',
                  '&:hover': {
                    transform: 'scale(1.01)',
                    bgcolor: 'secondary.yellow400',
                    color: 'primary.black',
                  },
                  p: 1,
                  px: 2,
                }}
              />
              <Typography
                variant="subtitle2"
                textAlign="left"
                sx={{
                  color: 'secondary.grey100',
                  fontWeight: 600,
                  mt: '.75rem',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate('/client-login')}
              >
                Login
              </Typography>
            </Stack>
          </form>
        </Stack>
      </Box>
      <ImgWrapper src={forgotPwd} alt="Forgot Password Image" />
    </BannerWrapper>
  );
};

export default ForgotPassword;
