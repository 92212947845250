import React from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { PaperFileText } from 'react-basicons';
import { BotResponseWrapper, BotResponseImgWrapper } from './style';
import ChatLogo from '../../../assets/ChatLogo.png';
import Button from '../../common/Button';
import { convertTimestampToFormattedDate } from '../../../utility/dateTimeUtils';

const BotResponse = ({ response, setOpen, loading, setDocText, prompts, timestamp, setDocId }) => {
  const handleDocBtnClick = (e, id) => {
    setOpen(true);
    setDocText(e.target.id);
    setDocId(id);
  };

  return (
    <>
      {timestamp ? (
        <Typography sx={{ fontSize: ' 0.75rem', color: 'secondary.light', textAlign: 'left' }}>
          {convertTimestampToFormattedDate(timestamp)}
        </Typography>
      ) : (
        <Skeleton
          variant="text"
          width="20%"
          sx={{
            bgcolor: 'secondary.grey500',
          }}
        />
      )}
      <BotResponseWrapper>
        <Stack bgcolor="primary.white" borderRadius={2} color="primary.black" width={{ xs: '100%', md: '98%' }}>
          <Stack direction="row" p={{ xs: 1, md: 2 }}>
            <BotResponseImgWrapper src={ChatLogo} alt="bot-logo" />
            <Typography
              width="92%"
              sx={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {response}
            </Typography>
          </Stack>
          {!loading && prompts && prompts.documents_matched.length !== 0 && (
            <Box sx={{ bgcolor: 'chat.bg' }} borderRadius={2}>
              <Stack width={{ xs: 'full', sm: '75%', md: '60%' }} p={2} ml={{ sm: 7, md: 8 }}>
                <Typography variant="subtitle2">
                  Related Legal Documents you may generate from Brihaspati.ai <br /> (₹0 document generation fee)
                </Typography>

                <Box width="100%" display="flex" flexWrap="wrap">
                  {prompts.documents_matched.map((doc) => (
                    <Button
                      key={doc.document_name}
                      variant="contained"
                      size="small"
                      icon={<PaperFileText size={18} />}
                      text={doc.document_name ? doc.document_name : 'NDA'}
                      onClick={(e) => handleDocBtnClick(e, doc._id)}
                      sx={{
                        bgcolor: 'primary.main',
                        textTransform: 'capitalize',
                        borderRadius: '20px',
                        fontSize: '0.875rem',
                        height: '2.5rem',
                        fontWeight: 600,
                        color: 'primary.black',
                        transition: 'all ease 0.3s',
                        '&:hover': {
                          transform: 'scale(1.01)',
                          bgcolor: 'secondary.yellow400',
                          color: 'primary.black',
                        },
                        m: 1,
                        padding: '0.625rem 1.25rem',
                      }}
                    />
                  ))}
                </Box>
              </Stack>
            </Box>
          )}
        </Stack>
      </BotResponseWrapper>
    </>
  );
};
BotResponse.propTypes = {
  response: PropTypes.string,
  setOpen: PropTypes.func,
  setDocId: PropTypes.func,
  setDocText: PropTypes.func,
  loading: PropTypes.bool,
  timestamp: PropTypes.number,
  prompts: PropTypes.object,
};

BotResponse.defaultProps = {
  response: '',
  setOpen: null,
  setDocText: null,
  setDocId: null,
  timestamp: null,
  prompts: null,
  loading: false,
};

export default BotResponse;
