import styled from '@emotion/styled';

const ImageWrapper = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;

  @media (max-width: 500px) {
    width: 162px !important;
  }
`;
export default ImageWrapper;
