import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Avatar, Stack, useMediaQuery, Tooltip } from '@mui/material';
import { AddPlusNewSquareBoxSquare } from 'react-basicons';
import Container from '@mui/material/Container';
import lawyerLogo from '../../../assets/lawyerLogo.png';
import logo from '../../../assets/logo.png';
import border from '../../../assets/Line3.svg';
import {
  selectIsAdminLoggedIn,
  selectIsLawyerLoggedIn,
  selectIsUserLoggedIn,
} from '../../../redux/selectors/authSelectors';
import Button from '../../common/Button';
import ProfileMenu from '../ProfileMenu';
import { clearChats, clearSecondPersonDetails } from '../../../redux/reducers/chat';
import { LogoWrapper, ImageWrapper } from './style';
import { session } from '../../../redux/actions/chatActions';
import SignupModal from '../../common/Modal/SignupModal';

const LawyerNavbar = () => {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isLawyerLoggedIn = useSelector(selectIsLawyerLoggedIn);
  const isAdminLoggedIn = useSelector(selectIsAdminLoggedIn);
  const { user, lawyer, admin } = useSelector((state) => state.auth);
  const { secondPersonDetails } = useSelector((state) => state.chat);

  const location = useLocation();
  const dispatch = useDispatch();

  const [anchorProfile, setAnchorProfile] = useState(null);
  const [open, setOpen] = useState(false);

  const openProfile = Boolean(anchorProfile);

  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isChatOpen = location.pathname.includes('/chat');
  const isProfileOpen = location.pathname.includes('/profile');
  const isChatHistoryOpen = location.pathname.includes('/chat-history');
  const isLawyerHomeOpen = location.pathname.includes('/lawyer-home');

  const handleAvatarClick = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorProfile(null);
  };

  const handleNewChatClick = () => {
    if (isUserLoggedIn) {
      dispatch(session());
      dispatch(clearChats());
      dispatch(clearSecondPersonDetails());
      navigate('/new-chat');
    } else {
      setOpen(true);
    }
  };

  const handleInviteLawyer = () => {};

  const getInitials = () => {
    if (isLawyerLoggedIn) {
      return (lawyer.full_name && lawyer.full_name[0].toUpperCase()) || lawyer.name[0].toUpperCase();
    }
    if (isUserLoggedIn) {
      return (user.full_name && user.full_name[0].toUpperCase()) || user.name[0].toUpperCase();
    }
    if (isAdminLoggedIn) {
      return (admin.full_name && admin.full_name[0].toUpperCase()) || admin.name[0].toUpperCase();
    }
    return 'GU';
  };

  const getSecondPersonInitials = () => {
    if (secondPersonDetails && secondPersonDetails.name) {
      return secondPersonDetails.name[0];
    }
    return 'U';
  };

  const getSecondPersonDetails = () => {
    if (secondPersonDetails && secondPersonDetails.name && secondPersonDetails.company) {
      return `${secondPersonDetails.name}, ${secondPersonDetails.company}`;
    }
    return 'Client details not available!';
  };

  const jumpToPage = location.state?.currentPage;

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        bgcolor: 'primary.bg',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" color="primary.white" alignItems="center" justifyContent="center">
            <LogoWrapper
              src={isLawyerLoggedIn ? lawyerLogo : logo}
              alt="Brihaspati Logo"
              onClick={() => navigate('/')}
            />
            {isLawyerLoggedIn ? (
              <Stack
                alignItems="center"
                spacing="2px"
                mt={1.75}
                ml="1rem"
                justifyContent="center"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate('/lawyer-home', { state: { currentPage: jumpToPage } })}
              >
                <Typography
                  display={{ xs: 'none', mob: 'flex' }}
                  sx={{ textTransform: 'capitalize' }}
                  textAlign="center"
                >
                  Clients
                </Typography>

                <ImageWrapper src={border} alt="Border" />
              </Stack>
            ) : (
              !isAdminLoggedIn && (
                <Stack
                  alignItems="center"
                  spacing="2px"
                  mt={{ xs: isLawyerLoggedIn || isChatHistoryOpen || isLawyerHomeOpen ? 1.75 : 0 }}
                  ml="1rem"
                  justifyContent="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate('/chat-history', { state: { currentPage: jumpToPage } })}
                >
                  <Typography
                    display={{ xs: 'none', mob: 'flex' }}
                    sx={{ textTransform: 'capitalize', fontWeight: 500 }}
                    textAlign="center"
                  >
                    Chats
                  </Typography>
                  {isLawyerLoggedIn && <ImageWrapper src={border} alt="Border" />}
                  {(isChatHistoryOpen || isLawyerHomeOpen) && <ImageWrapper src={border} alt="Border" />}
                </Stack>
              )
            )}
          </Stack>
          {isLawyerLoggedIn && (
            <Box sx={{ mr: '5rem' }}>
              {isLawyerLoggedIn && !isChatOpen && !isProfileOpen ? (
                <Typography
                  display={{ xs: 'none', md: 'flex' }}
                  fontStyle="italic"
                  color="primary.white"
                  variant="caption"
                  textAlign="center"
                >
                  Kindly review and revert revised documents ASAP
                </Typography>
              ) : (
                !isProfileOpen &&
                !isSmallScreen && (
                  <Stack direction="row" alignItems="center">
                    <Avatar
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'primary.black',
                        fontWeight: 600,
                        width: '2.5rem',
                        height: '2.5rem',
                        mr: { xs: 1 },
                        textTransform: 'capitalize',
                      }}
                    >
                      {getSecondPersonInitials()}
                    </Avatar>
                    <Typography
                      display={{ xs: 'none', md: 'flex' }}
                      fontStyle="italic"
                      color="primary.white"
                      variant="caption"
                      textAlign="center"
                      sx={{ fontSize: '.875rem', textTransform: 'capitalize' }}
                    >
                      {getSecondPersonDetails().toLowerCase()}
                    </Typography>
                  </Stack>
                )
              )}
            </Box>
          )}

          {isLawyerLoggedIn && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" color="white">
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    width: '3rem',
                    height: '3rem',
                    position: 'relative',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    background: 'radial-gradient(50% 50.00% at 50% 50.00%, #FECE2F 0%, #333 100%)',
                    border: (theme) => `2px dashed ${theme.palette.primary.main}`,
                    cursor: 'pointer',
                  }}
                  onClick={handleAvatarClick}
                >
                  <Avatar
                    sx={{
                      width: '100%',
                      height: '100%',
                      fontSize: '1.5rem',
                      backgroundColor: 'transparent',
                      textTransform: 'capitalize',
                    }}
                  >
                    {getInitials()}
                  </Avatar>
                </Box>
                <ProfileMenu open={openProfile} anchorProfile={anchorProfile} handleAvatarClose={handleAvatarClose} />
              </Stack>
            </Stack>
          )}
          {!isLawyerLoggedIn && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" color="white">
              <Stack direction="row" alignItems="center">
                {location.pathname !== '/get-started' && (
                  <Box>
                    {isSmallScreen && !isAdminLoggedIn && (
                      <Tooltip title="New Chat">
                        <span>
                          <Button
                            variant="contained"
                            size="small"
                            icon={<AddPlusNewSquareBoxSquare />}
                            onClick={handleNewChatClick}
                            sx={{
                              bgcolor: 'primary.main',
                              borderRadius: '24px',
                              fontWeight: 600,
                              fontSize: '14px',
                              color: 'primary.black',
                              transition: 'all ease 0.3s',
                              textTransform: 'capitalize',
                              '&:hover': {
                                transform: 'scale(1.01)',
                                bgcolor: 'secondary.yellow400',
                                color: 'primary.black',
                              },
                              '& .MuiButton-startIcon': {
                                mr: '0px',
                              },
                              m: 1,
                              p: 1,
                              marginRight: '1rem',
                            }}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {isSmallScreen && isAdminLoggedIn && (
                      <Tooltip title="Invite Lawyer">
                        <span>
                          <Button
                            variant="contained"
                            size="small"
                            icon={<AddPlusNewSquareBoxSquare />}
                            onClick={handleInviteLawyer}
                            sx={{
                              bgcolor: 'primary.main',
                              borderRadius: '24px',
                              fontWeight: 600,
                              fontSize: '14px',
                              color: 'primary.black',
                              transition: 'all ease 0.3s',
                              textTransform: 'capitalize',
                              '&:hover': {
                                transform: 'scale(1.01)',
                                bgcolor: 'secondary.yellow400',
                                color: 'primary.black',
                              },
                              '& .MuiButton-startIcon': {
                                mr: '0px',
                              },
                              m: 1,
                              p: 1,
                              marginRight: '1rem',
                            }}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {!isSmallScreen && !isAdminLoggedIn && (
                      <Button
                        variant="contained"
                        size="small"
                        icon={<AddPlusNewSquareBoxSquare />}
                        text="New Chat"
                        onClick={handleNewChatClick}
                        sx={{
                          bgcolor: 'primary.main',
                          borderRadius: '20px',
                          fontWeight: 600,
                          fontSize: '14px',
                          color: 'primary.black',
                          transition: 'all ease 0.3s',
                          textTransform: 'capitalize',
                          '&:hover': {
                            transform: 'scale(1.01)',
                            bgcolor: 'secondary.yellow400',
                            color: 'primary.black',
                          },
                          m: 1,
                          p: 1,
                          px: 2,
                          marginRight: '1rem',
                        }}
                      />
                    )}
                    {!isSmallScreen && isAdminLoggedIn && (
                      <Button
                        variant="contained"
                        size="small"
                        icon={<AddPlusNewSquareBoxSquare />}
                        text="Invite Lawyer"
                        onClick={handleInviteLawyer}
                        sx={{
                          bgcolor: 'primary.main',
                          borderRadius: '20px',
                          fontWeight: 600,
                          fontSize: '14px',
                          color: 'primary.black',
                          transition: 'all ease 0.3s',
                          textTransform: 'capitalize',
                          '&:hover': {
                            transform: 'scale(1.01)',
                            bgcolor: 'secondary.yellow400',
                            color: 'primary.black',
                          },
                          m: 1,
                          p: 1,
                          px: 2,
                          marginRight: '1rem',
                        }}
                      />
                    )}
                  </Box>
                )}

                <Box
                  sx={{
                    width: '3rem',
                    height: '3rem',
                    position: 'relative',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    background: 'radial-gradient(50% 50.00% at 50% 50.00%, #FECE2F 0%, #333 100%)',
                    border: (theme) => `2px dashed ${theme.palette.primary.main}`,
                    cursor: 'pointer',
                  }}
                  onClick={handleAvatarClick}
                >
                  <Avatar
                    sx={{
                      width: '100%',
                      height: '100%',
                      fontSize: '1.5rem',
                      backgroundColor: 'transparent',
                      textTransform: 'capitalize',
                    }}
                  >
                    {getInitials()}
                  </Avatar>
                </Box>

                <ProfileMenu open={openProfile} anchorProfile={anchorProfile} handleAvatarClose={handleAvatarClose} />
              </Stack>
            </Stack>
          )}
        </Toolbar>
      </Container>
      <SignupModal open={open} setOpen={setOpen} userFingerprint={user.fingerprint} newChat />
    </AppBar>
  );
};
export default LawyerNavbar;
