import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import UserQueryWrapper from './style';
import { convertTimestampToFormattedDate } from '../../../utility/dateTimeUtils';

const UserQuery = ({ query, user, timestamp, userName, client }) => {
  const { user: stateUser } = useSelector((state) => state.auth);
  const { scheduleData } = useSelector((state) => state.chat);

  const getSecondPersonInitials = () => {
    if (scheduleData && scheduleData.full_name) {
      return scheduleData.full_name && scheduleData.full_name[0];
    }

    return 'U!';
  };

  return (
    <>
      {timestamp && (
        <Typography
          sx={{ fontSize: ' 0.75rem', color: 'secondary.light', textAlign: user === 'true' ? 'right' : 'left' }}
        >
          {convertTimestampToFormattedDate(timestamp)}
        </Typography>
      )}

      <UserQueryWrapper user={user}>
        <Stack
          bgcolor={!user ? 'primary.white' : 'secondary.grey200'}
          borderRadius={2}
          color="primary.black"
          width={{ xs: '100%', md: '95%' }}
          direction="row"
          p={{ xs: 1, md: 2 }}
        >
          <Avatar
            sx={{
              bgcolor: 'primary.main',
              color: 'primary.black',
              fontWeight: 600,
              width: { xs: 32, sm: 50 },
              height: { xs: 32, sm: 50 },
              mr: { xs: 1, sm: 2 },
              textTransform: 'capitalize',
            }}
          >
            {stateUser.name
              ? stateUser.name[0].toUpperCase()
              : (userName && userName[0].toUpperCase()) || client[0] || getSecondPersonInitials()}
          </Avatar>

          <Typography width="92%" sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
            {query}
          </Typography>
        </Stack>
      </UserQueryWrapper>
    </>
  );
};
UserQuery.propTypes = {
  query: PropTypes.string,
  user: PropTypes.string,
  timestamp: PropTypes.number,
  userName: PropTypes.string,
  client: PropTypes.string,
};
UserQuery.defaultProps = {
  query: '',
  user: false,
  timestamp: null,
  userName: '',
  client: '',
};
export default UserQuery;
