import styled from '@emotion/styled';

const LawyerQueryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.lawyer === 'true' ? 'end' : 'start')};
  margin: 0.5rem 0;
`;

export default LawyerQueryWrapper;
