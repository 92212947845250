import styled from '@emotion/styled';

export const TextWrapper = styled.div`
  position: absolute;
  left: 10%;
  color: white;
  max-width: 500px;

  @media (max-width: 475px) {
    left: 8%;
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.bg};
  display: flex;
  justify-content: center;
  padding: 12px;
  gap: 6rem;

  padding-top: 5rem;
  @media (max-width: 1024px) {
    gap: 1rem;
  }

  @media (max-width: 475px) {
    flex-direction: column-reverse;
    justify-content: start;
    margin: 0 auto;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
`;

export const ImgWrapper = styled.img`
  height: 18rem;
  width: 18rem;
  object-fit: contain;

  @media screen and (max-width: 600px) {
    height: 15rem;
    width: 15rem;
  }
  @media screen and (max-width: 475px) {
    display: none;
  }
`;
