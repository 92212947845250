import { createPaymentOrder, getAllTransactionsService, verifySignature } from '../../services/paymentServices';
import errorHandler from '../../utility/errorHandler';
import { addTransactionHistory } from '../reducers/auth';
import { addUserDocDetailsAfterPayment, setPayment } from '../reducers/chat';

const createPaymentOrderAction = (data, onSuccess) => async (dispatch) => {
  try {
    const res = await createPaymentOrder(data);
    // eslint-disable-next-line no-console
    console.log(res.data);
    dispatch(setPayment(res.data));
    onSuccess();
  } catch (error) {
    errorHandler(error);
  }
};

const verifySignatureAction = (data) => async (dispatch) => {
  try {
    const response = await verifySignature(data);
    dispatch(
      addUserDocDetailsAfterPayment({
        user_doc_id: response.data.user_document_id,
      }),
    );
  } catch (error) {
    errorHandler(error);
  }
};
const getAllTransactions = (currPage) => async (dispatch) => {
  try {
    const response = await getAllTransactionsService(currPage);

    await dispatch(addTransactionHistory(response.data));
  } catch (err) {
    errorHandler(err);
  }
};
export { createPaymentOrderAction, verifySignatureAction, getAllTransactions };
