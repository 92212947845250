import { scheduledMeetingService, getMeetingDetailsService } from '../../services/calendlyServices';
import errorHandler from '../../utility/errorHandler';
import { setScheduleData } from '../reducers/chat';

const scheduledMeetingAction = (data) => async (dispatch) => {
  try {
    const response = await scheduledMeetingService(data);

    dispatch(setScheduleData(response.data.data.data));
  } catch (error) {
    errorHandler(error);
  }
};

const getMeetingDetails = (data, createdAt) => async (dispatch) => {
  try {
    const response = await getMeetingDetailsService(data);
    dispatch(setScheduleData({ ...response.data.data.meeting_details, ...response.data.data.user_details, createdAt }));
  } catch (error) {
    errorHandler(error);
  }
};

export { scheduledMeetingAction, getMeetingDetails };
