import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAdminLoggedIn, selectIsLawyerLoggedIn, selectIsUserLoggedIn } from '../redux/selectors/authSelectors';

const Auth = () => {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isLawyerLoggedIn = useSelector(selectIsLawyerLoggedIn);
  const isAdminLoggedIn = useSelector(selectIsAdminLoggedIn);

  return isUserLoggedIn || isLawyerLoggedIn || isAdminLoggedIn ? <Outlet /> : <Navigate to="/client-login" />;
};

export default Auth;
