import styled from '@emotion/styled';

const ChatContainer = styled.div`
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  & ::-webkit-scrollbar {
    width: 0.5em;
  }

  & ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
`;

const ImgWrapper = styled.img`
  width: 45px;
  height: 45px;
  padding: 5px;
  marginright: 4px;
`;

export { ChatContainer, ImgWrapper };
