import styled from '@emotion/styled';
import DataTable from 'react-data-table-component';

export const TableWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    padding: 1rem 0.2rem;
  }
`;

export const CustomDataTable = styled(DataTable)`
  font-family: 'Work Sans';
  .rdt_Table {
    background-color: ${({ theme }) => theme.palette.primary.bg};
    margin: 16px auto;

    .sc-hmdomO.cTsUPQ {
      border-radius: 12px;
      background-color: ${({ theme }) => theme.palette.secondary.grey400};
      border: 1px solid ${({ theme }) => theme.palette.secondary.grey};
      color: white !important;
    }
  }

  .rdt_TableHeadRow {
    background-color: ${({ theme }) => theme.palette.primary.bg};
    color: white;
    padding: 0.25rem 1.5rem;
    font-size: 0.75rem;
  }

  .rdt_TableBody {
    gap: 0.625rem;
  }

  .rdt_TableCell {
    flex: none;
  }

  .rdt_TableRow {
    color: white;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.palette.secondary.grey400};
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    transition: all 0.3s ease-in-out;
    min-height: 82px;

    &:hover {
      background-color: ${({ theme }) => theme.palette.secondary.hover};
      cursor: pointer;
    }
  }
`;

export const ImgWrapper = styled.img`
  width: 160px;
  height: 160px;
  object-fit: contain;
  margin-right: 4px;
`;
