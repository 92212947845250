import errorHandler from '../../utility/errorHandler';
import {
  changePasswordService,
  loginService,
  signupService,
  guestSignupService,
  deleteGuest,
  lawyerSignupService,
  updateUserService,
  getUserDetailsService,
  onlineStatusService,
  forgotPasswordService,
  resetPasswordService,
  getUserService,
  getAllLawyerService,
} from '../../services/authServices';
import {
  adminLogin,
  allLawyersInfo,
  deleteGuestUserFingerprint,
  lawyerDetails,
  lawyerLogin,
  lawyerLoginFailure,
  setGuestUser,
  userDetails,
  userLogin,
  userLoginFailure,
  userSignupAction,
} from '../reducers/auth';
import { setItem } from '../../utility/localStorageControl';

const loginUser = (email, password, onSuccess) => async (dispatch) => {
  try {
    const res = await loginService({ email, password });
    setItem('access_token', res.data.data.access_token);
    onSuccess();
    dispatch(userLogin(res.data.data));
  } catch (error) {
    errorHandler(error, userLoginFailure);
  }
};

const changePassword = (oldPassword, newPassword, onSuccess) => async () => {
  try {
    await changePasswordService({ old_password: oldPassword, new_password: newPassword });
    onSuccess();
  } catch (error) {
    errorHandler(error);
  }
};

const forgotPassword = (data, onSuccess) => async () => {
  try {
    await forgotPasswordService(data);
    onSuccess();
  } catch (error) {
    errorHandler(error);
  }
};

const resetPassword = (data, token, onSuccess) => async () => {
  try {
    setItem('access_token', token);
    await resetPasswordService(data);
    onSuccess();
  } catch (error) {
    errorHandler(error);
  }
};

const onlineStatus = (data) => async () => {
  try {
    await onlineStatusService(data);
  } catch (error) {
    errorHandler(error);
  }
};

const userSignup = (data, onSuccess) => async (dispatch) => {
  try {
    const res = await signupService(data);
    setItem('access_token', res.data.data.access_token);
    onSuccess();
    dispatch(userSignupAction(res.data.data));
  } catch (error) {
    errorHandler(error);
  }
};

const lawyerSignup = (data, onSuccess) => async () => {
  try {
    await lawyerSignupService(data);
    onSuccess();
  } catch (error) {
    errorHandler(error);
  }
};

const loginLawyer = (email, password, onSuccess) => async (dispatch) => {
  try {
    const res = await loginService({ email, password });
    setItem('access_token', res.data.data.access_token);
    onSuccess(email);

    email.includes('admin') ? dispatch(adminLogin(res.data.data)) : dispatch(lawyerLogin(res.data.data));
  } catch (error) {
    errorHandler(error, lawyerLoginFailure);
  }
};

const guestUserSignUp = (fingerprint, onSuccess) => async (dispatch) => {
  try {
    const { data } = await guestSignupService(fingerprint);
    setItem('access_token', data.data.access_token);

    const guestData = {
      fingerprint: fingerprint.user_fingerprint,
      ...data?.data,
    };
    dispatch(setGuestUser(guestData));
    onSuccess();
  } catch (error) {
    errorHandler(error);
  }
};

const deleteGuestUser = (fingerprint) => async (dispatch) => {
  try {
    await deleteGuest(fingerprint);
    dispatch(deleteGuestUserFingerprint());
  } catch (error) {
    errorHandler(error);
  }
};

const getUserDetails = (loggedIn) => async (dispatch) => {
  try {
    const response = await getUserDetailsService();
    loggedIn && loggedIn.user && dispatch(userDetails(response.data.data));
    loggedIn && loggedIn.lawyer && dispatch(lawyerDetails(response.data.data));
  } catch (error) {
    errorHandler(error);
  }
};

const getUserAction = (data) => async () => {
  try {
    const res = await getUserService(data);
    return res.data.data;
  } catch (error) {
    errorHandler(error);
  }
  return null;
};

const updateUser = (data, onSuccess) => async () => {
  try {
    await updateUserService(data);
    onSuccess();
  } catch (error) {
    errorHandler(error);
  }
};

const getAllLawyers = (data) => async (dispatch) => {
  try {
    const { page, page_size } = data;
    const res = await getAllLawyerService({ page, page_size });
    dispatch(allLawyersInfo(res.data.data));
  } catch (error) {
    errorHandler(error);
  }
  return null;
};

export {
  loginUser,
  loginLawyer,
  userSignup,
  guestUserSignUp,
  lawyerSignup,
  changePassword,
  forgotPassword,
  resetPassword,
  deleteGuestUser,
  updateUser,
  getUserAction,
  getUserDetails,
  onlineStatus,
  getAllLawyers,
};
