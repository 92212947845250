import API from '../configs/api';
import DataService from '../configs/dataService/dataService';

const createPaymentOrder = (data) => DataService.post(`${API.payment.createOrder}`, data);

const verifySignature = (data) => DataService.post(`${API.payment.verifySignature}`, data);

const getAllTransactionsService = (page) => DataService.get(`${API.payment.getAllTransactions}?page=${page}`); // doc name ,paginated

export { createPaymentOrder, verifySignature, getAllTransactionsService };
