import { createTheme, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smob: 375,
      mob: 426,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#FECE2F',
      light: '#FECE2F33',
      bg: '#333333',
      black: '#000000',
      white: '#ffffff',
      white100: '#F2F2F2',
      white200: '#E4DFD0',
    },
    secondary: {
      main: '#222222',
      bg: '#444444',
      grey: '#777777',
      grey100: '#888',
      grey200: '#DDDDDD',
      grey300: '#ECECEC',
      grey400: '#555',
      grey500: '#666',
      yellow: '#F2C94C',
      yellow200: '#D6CAA9',
      yellow300: '#DDCF9E',
      yellow400: '#eeb300',
      yellow500: '#cfc08e',
      light: '#8C8C8C',
      hover: '#7A7665',
    },
    text: {
      light: '#8C8C8C',
    },
    chat: {
      bg: '#F6F2E6',
    },
  },
  typography: {
    fontFamily: ['Work Sans'],
  },
});

const CustomThemeProvider = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

CustomThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomThemeProvider;
