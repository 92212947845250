import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { EyePasswordHide, EyePasswordShow, NextArrowForward } from 'react-basicons';
import { SHA1 } from 'crypto-js';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { BannerWrapper, ImgWrapper } from './style';
import login from '../../assets/login.png';
import Button from '../../components/common/Button';
import { loginUser } from '../../redux/actions/authActions';
import ShowToastMessage from '../../utility/showToastMessage';
import TextStyle from '../../components/common/TextStyle';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Email is invalid').required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSuccess = () => {
    ShowToastMessage('success', 'Logged in successfully');
    navigate('/chat-history');
  };

  const onSubmit = (data) => {
    const { email, password } = data;
    const hashedPassword = SHA1(password).toString();
    dispatch(loginUser(email, hashedPassword, onSuccess));
  };

  return (
    <BannerWrapper>
      <Stack>
        <Box
          width={{ xs: 'auto', lg: '560px' }}
          bgcolor="primary.white"
          p={{ xs: 2, md: '1.5rem' }}
          sx={{ borderRadius: '8px' }}
          position="relative"
          left={{ xs: 'auto', md: '-11%' }}
        >
          <Stack spacing={3}>
            <Typography variant="h5" color="primary.black" fontWeight={600} lineHeight={1.5}>
              Login
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextStyle
                id="email"
                label="Email Address"
                {...register('email')}
                variant="outlined"
                fullWidth
                sx={{ marginY: '.5rem' }}
              />
              {errors.email && (
                <Typography color="red" variant="caption">
                  {errors.email.message}
                </Typography>
              )}

              <TextStyle
                id="password"
                type={isPasswordShown ? 'text' : 'password'}
                {...register('password')}
                label="Password"
                variant="outlined"
                fullWidth
                sx={{ marginY: '.5rem' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {' '}
                      <IconButton onClick={togglePasswordVisibility}>
                        {isPasswordShown ? <EyePasswordHide size={20} /> : <EyePasswordShow size={20} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && (
                <Typography color="red" variant="caption">
                  {errors.password.message}
                </Typography>
              )}

              <Stack direction="row" justifyContent="space-between" pt={2}>
                <Button
                  type="submit"
                  text="login"
                  icon={<NextArrowForward />}
                  variant="contained"
                  size="small"
                  sx={{
                    fontWeight: 600,
                    bgcolor: 'primary.main',
                    borderRadius: '20px',
                    color: 'primary.black',
                    transition: 'all ease 0.3s',
                    '&:hover': {
                      transform: 'scale(1.01)',
                      bgcolor: 'secondary.yellow400',
                      color: 'primary.black',
                    },
                    p: 1,
                    px: 2,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  textAlign="left"
                  sx={{
                    color: 'secondary.grey100',
                    fontWeight: 600,
                    mt: '.75rem',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => navigate('/forgot-password')}
                >
                  Forgot Password
                </Typography>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>{' '}
      <ImgWrapper src={login} alt="" />
    </BannerWrapper>
  );
};

export default Login;
