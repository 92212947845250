import styled from '@emotion/styled';

const ImageWrapper = styled.img`
  width: 12rem;
  height: 12rem;
`;

const DocContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export { ImageWrapper, DocContainer, FormContainer };
