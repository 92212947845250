import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { PreviousArrowBackward } from 'react-basicons';
import bgCover from '../../assets/bgCover.jpg';
import ImgBgWrapper from './style';
import Button from '../../components/common/Button';

const NotFound = () => {
  const location = useLocation();
  return (
    <Grid
      height="100vh"
      container
      justifyContent="center"
      alignItems="center"
      position="absolute"
      sx={{
        bgcolor: 'primary.bg',
      }}
    >
      <ImgBgWrapper src={bgCover} alt="bgCover" />
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backdropFilter: 'blur(3px)',
        }}
      >
        {location.pathname.includes('coming-soon') ? (
          <Typography
            className="coming-soon"
            zIndex={1}
            variant="h4"
            color="primary.main"
            textAlign="center"
            fontWeight={800}
            letterSpacing="1px"
            sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)' }}
          >
            UPDATING SOON!
          </Typography>
        ) : (
          <Typography
            zIndex={1}
            variant="h4"
            color="primary.main"
            textAlign="center"
            fontWeight={800}
            letterSpacing="1px"
            sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)' }}
          >
            404 - PAGE NOT FOUND!
          </Typography>
        )}

        <Button
          text="Go Back"
          variant="outlined"
          onClick={() => window.history.back()}
          icon={<PreviousArrowBackward />}
          sx={{
            bgcolor: 'primary.main',
            textTransform: 'capitalize',
            borderRadius: '20px',
            fontSize: '0.875rem',
            height: '2.5rem',
            fontWeight: 600,
            color: 'primary.black',
            transition: 'all ease 0.3s',
            '&:hover': {
              transform: 'scale(1.01)',
              bgcolor: 'secondary.yellow400',
              color: 'primary.black',
            },
            m: 1,
            padding: '0.625rem 1.25rem',
          }}
        />
      </Box>
    </Grid>
  );
};

export default NotFound;
