import { Outlet } from 'react-router-dom';
import './App.css';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import ConsultLawyer from './components/common/Modal/ConsultLawyer';
import { setConsultLawyerModal, setSignUpModal } from './redux/reducers/modal';
import SignupModal from './components/common/Modal/SignupModal';
import { selectIsUserLoggedIn } from './redux/selectors/authSelectors';

const App = () => {
  const myTheme = useTheme();
  const primaryColor = myTheme.palette.primary.main;
  const secondaryColor = myTheme.palette.primary.black;

  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const consultLawyerModal = useSelector((state) => state.modal.consultLawyerModal);
  const signUpModal = useSelector((state) => state.modal.signUpModal);
  const { user } = useSelector((state) => state.auth);

  const toggleConsultLawyerModal = () => {
    dispatch(setConsultLawyerModal(false));
  };

  const toggleSignUpModal = () => {
    dispatch(setSignUpModal(false));
  };

  return (
    <div>
      {!isUserLoggedIn && signUpModal && (
        <SignupModal open={signUpModal} setOpen={toggleSignUpModal} userFingerprint={user.fingerprint} />
      )}
      {isUserLoggedIn && consultLawyerModal && (
        <ConsultLawyer open={consultLawyerModal} setOpen={toggleConsultLawyerModal} />
      )}
      <Outlet />
      <Toaster
        toastOptions={{
          success: {
            iconTheme: {
              primary: primaryColor,
              secondary: secondaryColor,
            },
            style: {
              background: secondaryColor,
              color: primaryColor,
              fontFamily: 'Work Sans',
            },
          },
          error: {
            iconTheme: {
              primary: 'red',
              secondary: secondaryColor,
            },
            style: {
              background: secondaryColor,
              color: primaryColor,
            },
          },
        }}
      />
    </div>
  );
};

export default App;
