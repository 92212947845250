import API from '../configs/api';
import DataService from '../configs/dataService/dataService';

const loginService = (data) => DataService.post(API.auth.login, data);
const signupService = (data) => DataService.post(API.auth.signup, data);
const guestSignupService = (data) => DataService.post(API.auth.guestSignup, data);
const lawyerSignupService = (data) => DataService.post(API.auth.lawyerSignup, data);
const changePasswordService = (data) => DataService.put(API.auth.changePassword, data);
const forgotPasswordService = (data) => DataService.post(API.auth.forgotPassword, data);
const resetPasswordService = (data, token) => DataService.post(API.auth.resetPassword, data, token);
const getUserDetailsService = () => DataService.get(API.auth.getUserDetails);
const updateUserService = (data) => DataService.put(API.auth.updateUser, data);
const deleteGuest = (data) => DataService.delete(`${API.auth.deleteGuest}?user_fingerprint=${data}`);
const onlineStatusService = (data) => DataService.put(`${API.auth.onlineStatus}?user_status=${data}`);
const getUserService = (data) => DataService.get(`${API.auth.getUser}?email_or_id=${data}`);
const getAllLawyerService = ({ page, page_size }) =>
  DataService.get(`${API.auth.getAllLawyersInfo}?page=${page}&page_size=${page_size}`);

export {
  loginService,
  signupService,
  guestSignupService,
  changePasswordService,
  forgotPasswordService,
  resetPasswordService,
  deleteGuest,
  lawyerSignupService,
  updateUserService,
  getUserDetailsService,
  getUserService,
  onlineStatusService,
  getAllLawyerService,
};
