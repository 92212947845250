import styled from '@emotion/styled';

export const TextWrapper = styled.div`
  position: absolute;
  left: 10%;
  color: white;
  max-width: 500px;

  @media (max-width: 475px) {
    left: 8%;
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.bg};
  display: flex;
  justify-content: center;

  @media (max-width: 475px) {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
  }
`;

export const ImgWrapper = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImgLogoWrapper = styled.img`
  width: 9rem;
`;
