import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import LawyerQueryWrapper from './style';
import { convertTimestampToFormattedDate } from '../../../utility/dateTimeUtils';

const LawyerQuery = ({ query, lawyer, timestamp }) => (
  <>
    {timestamp && (
      <Typography
        sx={{ fontSize: ' 0.75rem', color: 'secondary.light', textAlign: lawyer === 'true' ? 'right' : 'left' }}
      >
        {convertTimestampToFormattedDate(timestamp)}
      </Typography>
    )}
    <LawyerQueryWrapper lawyer={lawyer}>
      <Stack
        bgcolor={lawyer ? 'primary.white' : 'secondary.grey200'}
        borderRadius={2}
        color="primary.black"
        width={{ xs: '100%', md: '95%' }}
        direction="row"
        gap={{ xs: 0.5, md: 1 }}
        p={{ xs: 1, md: 2 }}
      >
        <Avatar
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.black',
            fontWeight: 600,
            width: { xs: 32, sm: 50 },
            height: { xs: 32, sm: 50 },
            mr: { xs: 1, sm: 2 },
          }}
        />
        <Typography width="92%">{query}</Typography>
      </Stack>
    </LawyerQueryWrapper>
  </>
);

LawyerQuery.propTypes = {
  query: PropTypes.string,
  lawyer: PropTypes.string,
  timestamp: PropTypes.number,
};
LawyerQuery.defaultProps = {
  query: '',
  lawyer: false,
  timestamp: null,
};
export default LawyerQuery;
