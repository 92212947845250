import React, { useEffect, useRef, useState } from 'react';
import Fingerprint2 from 'fingerprintjs2';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import StartChatImg from '../../assets/Question.png';
import TextField from '../../components/common/TextField';
import { StartChatWrapper, StartChatImgWrapper, ImgBackBtnWrapper } from './style';
import { guestUserSignUp } from '../../redux/actions/authActions';
import { addStartChatQuery } from '../../redux/reducers/chat';
import ShowToastMessage from '../../utility/showToastMessage';
import ArrowBackIcon from '../../assets/ArrowBack.png';
import { selectGuestId, selectIsUserLoggedIn } from '../../redux/selectors/authSelectors';
import MultiStepModal from '../../components/common/Modal/MultiStepModal';

const StartChat = () => {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const [step, setStep] = useState(!isUserLoggedIn ? 1 : 2);
  const [open, setOpen] = useState(false);

  const guestId = useSelector(selectGuestId);
  const { user } = useSelector((state) => state.auth);

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = useLocation();
  const guestChat = pathname.includes('/get-started');

  const onSuccess = () => {
    ShowToastMessage('success', `${guestId ? `Welcome Guest_${guestId.substring(0, 10)}...` : 'Welcome Guest!'}`);
  };

  const generateFingerprint = () =>
    new Promise((resolve) => {
      Fingerprint2.get((components) => {
        const values = components.map((component) => component.value);
        const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
        resolve(fingerprint);
      });
    });

  useEffect(() => {
    if (!isUserLoggedIn) {
      if (guestChat && user.fingerprint === '') {
        generateFingerprint()
          .then((generatedFingerprint) => {
            const data = {
              user_fingerprint: generatedFingerprint,
            };
            dispatch(guestUserSignUp(data, onSuccess));
          })
          .catch((error) => {
            console.error('Error generating fingerprint:', error);
          });
      }
    }
  }, [guestChat]);

  useEffect(() => {
    if (location.state && location.state.generateDoc) {
      setOpen(true);

      const updatedState = { ...location.state, generateDoc: false };
      navigate({ pathname: location.pathname, state: updatedState });
    }
  }, [location.state, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputRef.current.value.length > 0) {
      dispatch(addStartChatQuery({ userQuery: inputRef.current.value }));
      navigate('/chat');
    }
  };

  const handleClick = (e) => {
    inputRef.current.value = e.target.value;
    inputRef.current.focus();
  };

  return (
    <StartChatWrapper>
      <Stack spacing={1} p={1} pt={1.6}>
        <Stack
          direction={{ xs: 'column-reverse', sm: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          gap={{ xs: 1, sm: 3, md: guestChat ? 8 : '0', lg: guestChat ? '220px' : '0' }}
          p={1}
          px={{ xs: 'auto', md: 3 }}
          pr={{ xs: 'auto', md: '0' }}
          mb="12px"
        >
          <Stack spacing={1} alignSelf="end">
            {guestChat ? (
              <Box>
                <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                  Welcome to brihaspati.ai
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  lineHeight={1.5}
                  my=".5rem"
                  sx={{ fontSize: '1.125rem' }}
                >
                  You know chatGPT. Think of this as a legalGPT. <br /> Go ahead, ask your first legal question.
                </Typography>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection={{ xs: 'column', lg: 'row' }}
                alignItems="start"
                gap="0.5rem"
                ml={{ xs: '0', lg: '-12%' }}
                mb={{ xs: 0, sm: 6 }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate(-1)}
                  sx={{
                    borderRadius: '0.50688rem',
                    marginRight: '0.4rem',
                    padding: '0.42238rem 0.84481rem',
                  }}
                >
                  <ImgBackBtnWrapper src={ArrowBackIcon} alt="ArrowBackIcon" />
                </Button>
                <Typography variant="subtitle1" fontSize="1rem">
                  BOOM! New Chat
                  <br />
                  You can keep everything tidy by starting new chats for unrelated prompts
                </Typography>
              </Box>
            )}

            <Typography
              variant="subtitle2"
              color="primary.main"
              sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              // onClick={() => navigate('/disclaimer')}
              onClick={() => navigate('/coming-soon')}
            >
              Disclaimers <MdOutlineKeyboardArrowRight size={18} />
            </Typography>
          </Stack>
          <StartChatImgWrapper src={StartChatImg} alt="landing-page-img" />
        </Stack>
        <form onSubmit={guestChat ? handleSubmit : handleSubmit}>
          <TextField fullWidth inputRef={inputRef} placeholder="Type your legal prompt / question here.." />
        </form>
        <Stack spacing={3} p={1} px={{ xs: 'auto', md: 3 }}>
          <Stack spacing={1} mt="12px">
            <Typography variant="subtitle2" color="primary.main">
              Example Prompts
            </Typography>
            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1}>
              <Button
                sx={{
                  bgcolor: 'transparent',
                  borderColor: 'secondary.light',
                  color: 'secondary.light',
                  textTransform: 'none',
                  borderRadius: '0.5rem',
                  padding: '0.625rem',
                }}
                variant="outlined"
                size="small"
                value="How to incorporate a company?"
                onClick={handleClick}
              >
                How to incorporate a company?
              </Button>
              <Button
                sx={{
                  bgcolor: 'transparent',
                  borderColor: 'secondary.light',
                  color: 'secondary.light',
                  textTransform: 'none',
                  borderRadius: '0.5rem',
                  padding: '0.625rem',
                }}
                variant="outlined"
                size="small"
                value="How to get GST registration?"
                onClick={handleClick}
              >
                How to get GST registration?
              </Button>
              <Button
                sx={{
                  bgcolor: 'transparent',
                  borderColor: 'secondary.light',
                  color: 'secondary.light',
                  textTransform: 'none',
                  borderRadius: '0.5rem',
                  padding: '0.625rem',
                }}
                variant="outlined"
                value="How to file a patent?"
                size="small"
                onClick={handleClick}
              >
                How to file a patent?
              </Button>
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2" color="primary.main">
              Generate A Legal document
            </Typography>
            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1}>
              <Button
                onClick={() => setOpen(true)}
                sx={{
                  bgcolor: 'transparent',
                  borderColor: 'secondary.light',
                  color: 'secondary.light',
                  textTransform: 'none',
                  borderRadius: '0.625rem',
                  padding: '0.625rem',
                }}
                variant="outlined"
                size="small"
              >
                Select Document <MdOutlineKeyboardArrowRight size={18} />
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {open && (
        <MultiStepModal
          open={open}
          setOpen={setOpen}
          step={step}
          setStep={setStep}
          userFingerprint={user && user.fingerprint}
        />
      )}
    </StartChatWrapper>
  );
};

export default StartChat;
