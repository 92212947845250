import { TextField, styled } from '@mui/material';

const TextStyle = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.secondary.grey300,

    '& input::placeholder': {
      color: theme.palette.primary.black,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.grey200,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.primary.white,

      '& fieldset': {
        borderColor: theme.palette.secondary.grey100,
      },
      '& legend': {
        backgroundColor: theme.palette.primary.white,
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary.black',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary.black',
      },
    },
    '& input:-webkit-autofill': {
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.palette.primary.white} inset`,
      '-webkit-text-fill-color': 'inherit',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.secondary.grey[600],
    '&.Mui-focused': {
      color: theme.palette.secondary.grey100,
    },
  },
}));

export default TextStyle;
