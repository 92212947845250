import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LawyerHeader from '../components/shared/LawyerHeader/LawyerHeader';

const LoginLayout = ({ children }) => (
  <Box bgcolor="primary.bg" width="100vw" minHeight="100vh">
    <LawyerHeader />
    {children}
  </Box>
);

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
