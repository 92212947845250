// eslint-disable-next-line no-unused-vars
import { Toaster, toast } from 'react-hot-toast';

const ShowToastMessage = (type, message) => {
  const toastTypes = {
    success: toast.success,
    error: toast.error,
  };
  const notify = toastTypes[type];

  if (notify) {
    notify(message);
  }
};

export default ShowToastMessage;
