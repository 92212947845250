import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar, Stack, Typography } from '@mui/material';
import { CalendarDateAppointmentTime, ClockTime } from 'react-basicons';
import { separateDateAndTime } from '../../utility/dateTimeUtils';

const Appointment = ({ startTime, endTime, lawyerDetails }) => (
  <Stack
    maxWidth="max-content"
    width={{ xs: '80%', md: 'max-content' }}
    direction="row"
    gap={2}
    mb={2}
    bgcolor="primary.white"
    borderRadius=" 0.5rem"
    sx={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
    p={2}
    ml={{ xs: 1.8, sm: 8, md: 10 }}
  >
    <Avatar
      sx={{
        background: 'linear-gradient(180deg, #FFCA10 0%, rgba(196, 196, 196, 0.00) 100%)',
        width: { xs: '5.5rem', md: '7.5rem' },
        height: { xs: '5.5rem', md: '7.5rem' },
        fontSize: '1.5rem',
        color: 'primary.black',
        fontWeight: 600,
        textTransform: 'capitalize',
      }}
    >
      {lawyerDetails.name ? lawyerDetails.name[0] : lawyerDetails?.email?.split('.')[0][0]}
    </Avatar>
    <Stack gap={2}>
      <Typography fontWeight={600} sx={{ textTransform: 'capitalize' }}>
        {lawyerDetails.name ? lawyerDetails.name : lawyerDetails?.email?.split('.')[0]}
      </Typography>
      <Stack direction="row">
        <CalendarDateAppointmentTime />
        <Typography ml={2}>{separateDateAndTime(startTime).date}</Typography>
      </Stack>
      <Stack direction="row">
        <ClockTime />
        <Typography ml={2}>
          {separateDateAndTime(startTime).time}-{separateDateAndTime(endTime).time}
        </Typography>
      </Stack>
    </Stack>
  </Stack>
);

Appointment.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  lawyerDetails: PropTypes.object,
};

Appointment.defaultProps = {
  startTime: '',
  endTime: '',
  lawyerDetails: {},
};

export default Appointment;
