const convertPaiseToRupees = (inputString) => {
  let msg = inputString;

  if (inputString.includes('₹')) {
    msg = inputString.replace('₹', '');
  }

  const regex = /(\d+\.\d+)/;
  const match = msg.match(regex);

  if (match && match[1]) {
    const paiseValue = parseFloat(match[1]);
    const rupeesValue = (paiseValue / 100).toFixed(2);
    const rupeeString = `₹${rupeesValue}`;
    return msg.replace(match[1], rupeeString);
  }

  return inputString;
};

export default convertPaiseToRupees;
