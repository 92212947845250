import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import propTypes from 'prop-types';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfPreview = ({ link }) => {
  const [numberPages, setNumberPages] = useState();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberPages(numPages);
  };

  return (
    <Document file={link} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numberPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
};

PdfPreview.propTypes = {
  link: propTypes.string.isRequired,
};

export default PdfPreview;
