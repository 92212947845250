import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  StartChatQuery: {},
  chats: [],
  chatHistory: [],
  userDoc: {
    doc_id: '',
    totalDocQuestions: '',
    user_doc_id: '',
    doc_link: '',
    htmlContent: '',
    totalCount: '',
    allDocs: [],
    pdfLink: '',
  },
  lawyerDoc: {},
  payment: {},
  paymentDoc: false,
  scheduleData: {},
  isLawyerJoined: false,
  secondPersonDetails: {},
  chatDocumentStatus: [],
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addUserDocDetails: (state, action) => ({
      ...state,
      userDoc: {
        ...state.userDoc,
        totalDocQuestions: action.payload.totalDocQuestions,
        user_doc_id: action.payload.user_doc_id,
      },
    }),

    addUserDocDetailsAfterPayment: (state, action) => ({
      ...state,
      userDoc: {
        ...state.userDoc,
        user_doc_id: action.payload.user_doc_id,
      },
    }),
    addDocId: (state, action) => ({
      ...state,
      userDoc: {
        ...state.userDoc,
        doc_id: action.payload,
      },
    }),

    addStartChatQuery: (state, action) => ({
      ...state,
      StartChatQuery: action.payload,
    }),

    deleteFirstChatQuery: (state) => ({
      ...state,
      StartChatQuery: {},
    }),

    addDocLink: (state, action) => ({
      ...state,
      userDoc: {
        ...state.userDoc,
        doc_link: action.payload,
      },
    }),

    addChats: (state, action) => {
      const { id, sender, botResponse, page } = action.payload;
      const prevIndex = state.chats.findIndex((chat) => chat.id === id);

      if (sender === 'chatgpt@gmail.com') {
        if (prevIndex !== -1) {
          const updatedChats = state.chats.map((chat, index) => {
            if (index === prevIndex) {
              return {
                ...chat,
                botResponse: chat.botResponse + botResponse,
              };
            }
            return chat;
          });

          return {
            ...state,
            chats: updatedChats,
          };
        }
      }

      if (page) {
        return {
          ...state,
          chats: page !== 1 ? [action.payload, ...state.chats] : [...state.chats, action.payload],
        };
      }
      return {
        ...state,
        chats: [...state.chats, action.payload],
      };
    },

    addDocResultsToChat: (state, action) => {
      const newChat = action.payload;
      const existingChatIndex = state.chats.findIndex((chat) => chat.id === newChat.id);

      if (existingChatIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        state.chats[existingChatIndex] = newChat;
      } else {
        state.chats.push(newChat);
      }
    },

    addChatHistory: (state, action) => ({
      ...state,
      chatHistory: action.payload,
    }),

    clearChats: (state) => ({
      ...state,
      chats: [],
      chatHistory: [],
      scheduleData: {},
      isLawyerJoined: false,
      chatDocumentStatus: [],
    }),

    clearChatMessages: (state) => ({
      ...state,
      chats: [],
    }),

    addTotalDoc: (state, action) => ({
      ...state,
      userDoc: {
        ...state.userDoc,
        totalCount: action.payload,
      },
    }),

    updateChats: (state, action) => {
      const { id, prompts, timestamp } = action.payload;

      const prevIndex = state.chats.findIndex((chat) => chat.id === id);

      if (prevIndex !== -1) {
        const updatedChats = state.chats.map((chat, index) => {
          if (index === prevIndex) {
            return {
              ...chat,
              loading: false,
              timestamp,
              prompts,
            };
          }
          return chat;
        });

        return {
          ...state,
          chats: updatedChats,
        };
      }

      return state;
    },

    setHtmlContent: (state, action) => ({
      ...state,
      userDoc: {
        ...state.userDoc,
        htmlContent: action.payload,
      },
    }),
    setAllDocs: (state, action) => ({
      ...state,
      userDoc: {
        ...state.userDoc,
        allDocs: action.payload,
      },
    }),

    setPaymentDoc: (state, action) => ({
      ...state,
      paymentDoc: action.payload,
    }),

    setPayment: (state, action) => ({
      ...state,
      payment: action.payload,
    }),

    setScheduleData: (state, action) => ({
      ...state,
      scheduleData: action.payload,
    }),
    setUploadDoc: (state, action) => ({
      ...state,
      lawyerDoc: action.payload,
    }),

    setLawyerJoined: (state, action) => ({
      ...state,
      isLawyerJoined: action.payload,
    }),

    setSecondPersonDetails: (state, action) => ({
      ...state,
      secondPersonDetails: action.payload,
    }),
    clearSecondPersonDetails: (state) => ({
      ...state,
      secondPersonDetails: {},
    }),
    setPdfLink: (state, action) => ({
      ...state,
      userDoc: {
        ...state.userDoc,
        pdfLink: action.payload,
      },
    }),

    setChatDocStatus: (state, action) => ({
      ...state,
      chatDocumentStatus: action.payload,
    }),
  },
});

export const {
  addStartChatQuery,
  addChats,
  addQuestionsToChatHistory,
  addUserDocDetails,
  updateChats,
  addDocResultsToChat,
  addTotalDoc,
  deleteFirstChatQuery,
  addChatHistory,
  clearChats,
  clearChatMessages,
  addDocLink,
  setPaymentDoc,
  setHtmlContent,
  setAllDocs,
  addDocId,
  setPayment,
  setScheduleData,
  setUploadDoc,
  setLawyerJoined,
  setSecondPersonDetails,
  clearSecondPersonDetails,
  addUserDocDetailsAfterPayment,
  setPdfLink,
  setChatDocStatus,
} = chatSlice.actions;

export default chatSlice.reducer;
