import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Download, NextArrowForward } from 'react-basicons';
import file from '../../assets/file.svg';
import Button from '../common/Button';
import { ImgFileLogoWrapper } from './style';
import { downloadDoc } from '../../redux/actions/docActions';

const FilePreview = ({
  anchorEl,
  setAnchorEl,
  openDownload,
  handleClose,
  handleDownload,
  docId,
  handlePreviewDoc,
  filename,
  fetchDocs,
  setDocName,
  filledBy,
  setSelectedDoc,
}) => {
  const dispatch = useDispatch();

  const extractFileName = (filePath) => {
    const parts = filePath.split('/');
    const fileNameWithExtension = parts[parts.length - 1];
    const fileName = fileNameWithExtension.split('?')[0];
    if (fileName.includes('%20')) {
      return fileName.split('%20').join(' ');
    }
    return fileName;
  };

  const handleOpen = async (userDocId) => {
    fetchDocs();
    setSelectedDoc(userDocId);
    let res;
    if (filledBy === 'LAWYER') {
      res = await dispatch(downloadDoc(userDocId, 'PDF'));
    } else {
      res = await dispatch(downloadDoc(userDocId, 'HTML'));
    }

    handlePreviewDoc(res);
    setDocName(extractFileName(res));
  };

  return (
    <Stack
      direction="row"
      alignItems={{ xs: 'start', md: 'center' }}
      p={2}
      pl={{ xs: 'auto', md: '60px' }}
      gap={2}
      mt={2}
      bgcolor="primary.white100"
      sx={{ borderRadius: '0.5rem' }}
    >
      <ImgFileLogoWrapper src={file} alt="File" />
      <Stack>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
          {(filename && extractFileName(filename)) || filename}
        </Typography>
        <Stack direction="row" flexWrap="wrap-reverse" mt={1} gap={2}>
          <Button
            text="Download"
            variant="contained"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            sx={{
              bgcolor: 'primary.main',
              borderRadius: '20px',
              color: 'primary.black',
              fontWeight: 600,
              fontSize: '0.875rem',
              transition: 'all ease 0.3s',
              '&:hover': {
                transform: 'scale(1.01)',
                bgcolor: 'secondary.yellow400',
                color: 'primary.black',
              },
              p: 1,
              px: 2,
            }}
            icon={<Download />}
          />
          <Menu anchorEl={anchorEl} open={openDownload} onClose={handleClose}>
            {filledBy === 'USER' ? (
              <>
                <MenuItem sx={{ fontSize: '.9rem' }} onClick={() => handleDownload(docId, 'PDF')}>
                  <Typography variant="body2" fontWeight={600} color="primary.black">
                    PDF
                  </Typography>
                </MenuItem>
                <MenuItem sx={{ fontSize: '.9rem' }} onClick={() => handleDownload(docId, 'DOCX')}>
                  <Typography variant="body2" fontWeight={600} color="primary.black">
                    DOCX
                  </Typography>
                </MenuItem>
              </>
            ) : (
              <MenuItem sx={{ fontSize: '.9rem' }} onClick={() => handleDownload(docId, 'PDF')}>
                <Typography variant="body2" fontWeight={600} color="primary.black">
                  PDF
                </Typography>
              </MenuItem>
            )}
          </Menu>
          {(!filename.includes('.docx') || !extractFileName(filename).includes('.docx')) && (
            <Button
              text="Open"
              variant="contained"
              onClick={() => handleOpen(docId)}
              sx={{
                bgcolor: 'primary.main',
                borderRadius: '20px',
                fontWeight: 600,
                fontSize: '0.875rem',
                color: 'primary.black',
                transition: 'all ease 0.3s',
                '&:hover': {
                  transform: 'scale(1.01)',
                  bgcolor: 'secondary.yellow400',
                  color: 'primary.black',
                },
                p: 1,
                px: 2,
              }}
              icon={<NextArrowForward />}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

FilePreview.propTypes = {
  anchorEl: PropTypes.bool,
  setAnchorEl: PropTypes.func.isRequired,
  openDownload: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  handlePreviewDoc: PropTypes.func.isRequired,
  fetchDocs: PropTypes.func.isRequired,
  setDocName: PropTypes.func.isRequired,
  docId: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  filledBy: PropTypes.string.isRequired,
  setSelectedDoc: PropTypes.func,
};

FilePreview.defaultProps = {
  anchorEl: null,
  setSelectedDoc: () => {},
};

export default FilePreview;
