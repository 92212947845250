import { createSelector } from '@reduxjs/toolkit';

const authSelector = (state) => state.auth;

export const selectIsUserLoggedIn = createSelector(authSelector, (auth) => auth.user.isLoggedIn);

export const selectIsLawyerLoggedIn = createSelector(authSelector, (auth) => auth.lawyer.isLoggedIn);

export const selectIsAdminLoggedIn = createSelector(authSelector, (auth) => auth.admin.isAdminLoggedIn);

export const selectSessionId = createSelector(authSelector, (auth) => auth.user.sessionId);

export const selectGuestId = createSelector(authSelector, (auth) => auth.user.fingerprint);
