const apiAuthEndpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}`;

const API = {
  auth: {
    login: `${apiAuthEndpoint}/api/v1/user/login`,
    signup: `${apiAuthEndpoint}/api/v1/user/signup`,
    changePassword: `${apiAuthEndpoint}/api/v1/user/change-password`,
    forgotPassword: `${apiAuthEndpoint}/api/v1/user/forgot-password`,
    resetPassword: `${apiAuthEndpoint}/api/v1/user/reset-password`,
    getUserDetails: `${apiAuthEndpoint}/api/v1/user/user`,
    updateUser: `${apiAuthEndpoint}/api/v1/user/user`,
    guestSignup: `${apiAuthEndpoint}/api/v1/user/guest/signup`,
    deleteGuest: `${apiAuthEndpoint}/api/v1/user/guest/delete`,
    lawyerSignup: `${apiAuthEndpoint}/api/v1/user/lawyer/signup`,
    onlineStatus: `${apiAuthEndpoint}/api/v1/user/user-status`,
    getUser: `${apiAuthEndpoint}/api/v1/user/filter/user`,
    getAllLawyersInfo: `${apiAuthEndpoint}/api/v1/user/lawyer`,
  },
  chat: {
    session: `${apiAuthEndpoint}/api/v1/chat/session`,
    allSession: `${apiAuthEndpoint}/api/v1/chat/sessions`,

    history: `${apiAuthEndpoint}/api/v1/chat/chats`,
    addLawyerToChat: `${apiAuthEndpoint}/api/v1/chat/expert-session`,
    lawyerMetrics: `${apiAuthEndpoint}/api/v1/chat/lawyer/metrics`,
    userMetrics: `${apiAuthEndpoint}/api/v1/chat/user/metrics`,
  },
  docs: {
    getDoc: `${apiAuthEndpoint}/api/v1/documents/user-document`,
    getTotalDocCount: `${apiAuthEndpoint}/api/v1/documents/user-document/count`,
    getAllDocs: `${apiAuthEndpoint}/api/v1/documents/documents`,
    getSessionDocs: `${apiAuthEndpoint}/api/v1/documents/user-documents`,
    uploadDoc: `${apiAuthEndpoint}/api/v1/documents/user-document/upload`,
    docStatus: `${apiAuthEndpoint}/api/v1/documents/user-documents/incomplete`,
  },
  payment: {
    createOrder: `${apiAuthEndpoint}/api/v1/payment/order/create`,
    verifySignature: `${apiAuthEndpoint}/api/v1/payment/verify_signature`,
    getAllTransactions: `${apiAuthEndpoint}/api/v1/payment/transaction_history/get`,
  },
  calendly: {
    getCalendlyEvent: `${apiAuthEndpoint}/api/v1/calendly_event`,
    scheduledMeeting: `${apiAuthEndpoint}/api/v1/scheduled_meeting`,
    getMeetingDetails: `${apiAuthEndpoint}/api/v1/meeting-details`,
  },
  admin: {
    getAllSessions: `${apiAuthEndpoint}/api/v1/admin/all-session`,
  },
};

export default API;
