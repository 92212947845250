import React, { useState } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import FilePreview from '../FilePreview';
import { convertTimestampToFormattedDate } from '../../../utility/dateTimeUtils';

const UploadDocNotification = ({
  message,
  timestamp,
  docId,
  filename,
  handlePreviewDoc,
  downloadFile,
  filledBy,
  uploadedBy,
  isLawyerLoggedIn,
  isUploaded,
  sender,
  fetchDocs,
  setDocName,
  setSelectedDoc,
}) => {
  const [anchorDownload, setAnchorDownload] = useState(null);

  const openDownload = Boolean(anchorDownload);

  const handleClose = () => {
    setAnchorDownload(null);
  };

  return (
    <>
      {timestamp && (
        <Typography sx={{ fontSize: ' 0.75rem', color: 'secondary.light', textAlign: 'left' }}>
          {convertTimestampToFormattedDate(timestamp)}
        </Typography>
      )}
      <Box width="100%" bgcolor="primary.white" borderRadius={2} color="primary.black" my=".5rem">
        <Stack p={{ xs: 1, md: 2 }} pt={{ xs: 0, md: 0 }}>
          <Stack
            bgcolor="primary.white"
            borderRadius={2}
            color="primary.black"
            width={{ xs: '100%', md: '95%' }}
            direction="row"
            gap={{ xs: 0.5, md: 1 }}
            py={{ xs: 1, md: 2 }}
          >
            <Avatar
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.black',
                fontWeight: 600,
                width: { xs: 32, sm: 50 },
                height: { xs: 32, sm: 50 },
                mr: { xs: 1, sm: 2 },
              }}
            />
            {filledBy === 'LAWYER' || isUploaded ? (
              <Typography width="92%" fontWeight={600}>
                {isLawyerLoggedIn
                  ? 'You sent a revised document with track changes'
                  : `${sender || uploadedBy || 'Lawyer'} sent you a revised document with track changes`}
                <br />
                {!isLawyerLoggedIn && ' Download or Open the document to see the track changes'}
              </Typography>
            ) : (
              <Typography width="92%" fontWeight={600}>
                Thanks for answering the necessary questions to generate {message}
              </Typography>
            )}
          </Stack>
          <FilePreview
            anchorEl={anchorDownload}
            setAnchorEl={setAnchorDownload}
            handleClose={handleClose}
            openDownload={openDownload}
            handleDownload={downloadFile}
            docId={docId}
            handlePreviewDoc={handlePreviewDoc}
            filename={filename}
            fetchDocs={fetchDocs}
            setDocName={setDocName}
            filledBy={filledBy}
            setSelectedDoc={setSelectedDoc}
          />
        </Stack>
      </Box>
    </>
  );
};

UploadDocNotification.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
  docId: PropTypes.string,
  filename: PropTypes.string,
  handlePreviewDoc: PropTypes.func,
  fetchDocs: PropTypes.func.isRequired,
  setDocName: PropTypes.func.isRequired,
  downloadFile: PropTypes.func,
  filledBy: PropTypes.string,
  uploadedBy: PropTypes.string,
  isLawyerLoggedIn: PropTypes.bool,
  isUploaded: PropTypes.bool,
  sender: PropTypes.string,
  setSelectedDoc: PropTypes.func,
};

UploadDocNotification.defaultProps = {
  message: '',
  timestamp: 0,
  docId: '',
  filename: '',
  handlePreviewDoc: () => {},
  downloadFile: () => {},
  filledBy: '',
  uploadedBy: '',
  isLawyerLoggedIn: false,
  isUploaded: false,
  sender: '',
  setSelectedDoc: () => {},
};

export default UploadDocNotification;
