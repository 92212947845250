import styled from '@emotion/styled';

const ImgWrapper = styled.img`
  width: 50px;
  height: 55px;
  object-fit: contain;
  margin-right: 16px;

  @media screen and (max-width: 600px) {
    width: 32px;
    height: 45px;
  }
`;
const ThanksImgWrapper = styled.img`
  width: 90%;
`;

const ImgFileLogoWrapper = styled.img`
  width: auto;
  height: auto;
  @media (max-width: 600px) {
    width: 50px;
    height: 55px;
    object-fit: contain;
  }
`;

const LoadingImgWrapper = styled.img`
  width: auto;
  height: auto;
  object-fit: contain;
  @media (max-width: 600px) {
    width: 260px;
  }
  @media (max-width: 400px) {
    width: 220px;
  }
`;

export { ImgWrapper, ThanksImgWrapper, ImgFileLogoWrapper, LoadingImgWrapper };
