import React, { useState } from 'react';
import { XSquareCloseDelete, AccountUserPersonSquare, EyePasswordShow, EyePasswordHide } from 'react-basicons';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SHA1 } from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import signup from '../../../assets/signup.png';
import { ImageWrapper } from './style';
import TextStyle from '../TextStyle';
import Button from '../Button';
import ShowToastMessage from '../../../utility/showToastMessage';
import { deleteGuestUser, getUserDetails, userSignup } from '../../../redux/actions/authActions';
import { getDocumentCount } from '../../../redux/actions/docActions';
import { selectSessionId } from '../../../redux/selectors/authSelectors';

const SignupModal = ({ open, setOpen, userFingerprint, onNext, newChat }) => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { user } = useSelector((state) => state.auth);
  const { fingerprint } = user;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionId = useSelector(selectSessionId);

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isReEnterPasswordShown, setIsReEnterPasswordShown] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const toggleReEnterPasswordVisibility = () => {
    setIsReEnterPasswordShown(!isReEnterPasswordShown);
  };

  const validationSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    companyName: yup.string().required('Company Name is required'),
    designation: yup.string().required('Designation is required'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
      .required('Password is required'),

    reEnterPwd: yup.string().required('Re-Enter Password is required'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSuccess = () => {
    ShowToastMessage('success', 'Account created successfully');
    dispatch(getDocumentCount(sessionId));
    dispatch(getUserDetails({ user: true }));

    dispatch(deleteGuestUser(fingerprint));

    if (newChat) {
      setOpen(false);
    }
    if (onNext) {
      onNext();
    }
  };

  const onSubmit = (data) => {
    const { fullName, email, companyName, designation, password, reEnterPwd } = data;
    if (password === reEnterPwd) {
      const hashedPassword = SHA1(password).toString();
      const formData = {
        full_name: fullName,
        email,
        company_name: companyName,
        designation,
        password: hashedPassword,
        user_fingerprint: userFingerprint,
      };

      const json = JSON.stringify(formData);

      dispatch(userSignup(json, onSuccess));
    } else {
      ShowToastMessage('error', 'Password did not match');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          backgroundColor: 'primary.white',
        },
        '& .MuiDialog-container': {
          alignItems: 'start',
        },
      }}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.2)',
          backdropFilter: 'blur(5px)',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" fontSize="1.125rem" color="primary.black" fontWeight={600} lineHeight={1.5}>
            Signup to generate document
          </Typography>
          <Box
            component="div"
            p="5px"
            sx={{
              borderRadius: '50%',
              border: (theme) => `2px solid ${theme.palette.primary.main}`,
              backgroundColor: 'primary.light',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(false)}
          >
            <XSquareCloseDelete onClick={handleClose} />
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography
          display="flex"
          width="88%"
          variant="caption"
          fontSize="0.875rem"
          color="secondary.main"
          lineHeight={1.5}
        >
          Brihaspati.ai is designed and trained to address legal questions & generate documents for early stage startups
          and small busiensses. It is not yet ready for all the different kinds of legal queries
        </Typography>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, md: 10 }}
          justifyContent="space-between"
          mt={2}
        >
          <Box width={{ xs: '100%', md: '50%' }} component="form" onSubmit={handleSubmit(onSubmit)}>
            <TextStyle
              size="small"
              id="fullName"
              label="Enter your full name"
              {...register('fullName')}
              variant="outlined"
              fullWidth
              sx={{
                marginY: '.5rem',
              }}
            />
            {errors.fullName && (
              <Typography color="red" variant="caption">
                {errors.fullName.message}
              </Typography>
            )}

            <TextStyle
              size="small"
              id="email"
              type="email"
              label="Your Company Email Address"
              {...register('email')}
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
              autoComplete="new-email"
            />
            {errors.email && (
              <Typography color="red" variant="caption">
                {errors.email.message}
              </Typography>
            )}

            <TextStyle
              size="small"
              id="companyName"
              label="Company Name"
              {...register('companyName')}
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
            />
            {errors.companyName && (
              <Typography color="red" variant="caption">
                {errors.companyName.message}
              </Typography>
            )}

            <TextStyle
              size="small"
              id="designation"
              label="Your Designation"
              {...register('designation')}
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
            />
            {errors.designation && (
              <Typography color="red" variant="caption">
                {errors.designation.message}
              </Typography>
            )}

            <TextStyle
              size="small"
              type={isPasswordShown ? 'text' : 'password'}
              id="password"
              {...register('password')}
              label="Create Password"
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    <IconButton onClick={togglePasswordVisibility}>
                      {isPasswordShown ? <EyePasswordHide size={20} /> : <EyePasswordShow size={20} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {errors.password && (
              <Typography color="red" variant="caption">
                {errors.password.message}
              </Typography>
            )}

            <TextStyle
              size="small"
              type={isReEnterPasswordShown ? 'text' : 'password'}
              id="reEnterPwd"
              {...register('reEnterPwd')}
              label="Re-Enter Password"
              variant="outlined"
              fullWidth
              sx={{ marginY: '.5rem' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    <IconButton onClick={toggleReEnterPasswordVisibility}>
                      {isReEnterPasswordShown ? <EyePasswordHide size={20} /> : <EyePasswordShow size={20} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.reEnterPwd && (
              <Typography color="red" variant="caption">
                {errors.reEnterPwd.message}
              </Typography>
            )}

            <Button
              variant="contained"
              type="submit"
              text="Create brihaspati.ai account"
              icon={<AccountUserPersonSquare />}
              sx={{
                bgcolor: 'primary.main',
                borderRadius: '20px',
                color: 'primary.black',
                my: 2,
                fontWeight: 600,
                fontSize: '.875rem',
                transition: 'all ease 0.3s',
                textTransform: 'none',
                '&:hover': {
                  transform: 'scale(1.01)',
                  bgcolor: 'secondary.yellow400',
                  color: 'primary.black',
                },
                p: 1,
                px: 2,
              }}
            />
          </Box>
          <Stack alignItems="center" ml="0 !important" width={{ xs: '100%', md: '50%' }}>
            {!isMd && <ImageWrapper src={signup} />} <br />
            <Typography variant="caption" fontSize="0.75rem" color="secondary.main" lineHeight={1.5}>
              Already on Brihaspati.ai?
            </Typography>
            <Button
              text="Login"
              variant="outlined"
              onClick={() => navigate('/client-login')}
              sx={{
                position: 'relative',
                marginTop: '0.75rem',
                bgcolor: 'primary.light',
                borderRadius: '1.25rem',
                textTransform: 'capitalize',
                color: 'primary.black',
                border: '2px solid',
                borderColor: 'primary.main',
                padding: '0.5rem 1.2rem',
                fontWeight: 600,
                fontSize: '0.875rem',
                transition: 'all ease 0.3s',
                '&:hover': {
                  border: '2px solid',
                  borderColor: 'primary.main',
                  transform: 'scale(1.01)',
                  bgcolor: 'primary.light',
                  color: 'primary.black',
                },
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          mb: '1rem',
          px: '24px',
          mt: '-4%',
        }}
      >
        <Typography variant="caption" color="secondary.main" lineHeight={1.5} mt={2}>
          You agree to all{' '}
          <Typography
            variant="caption"
            color="primary.main"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate('/coming-soon')}
          >
            Terms of Use
          </Typography>{' '}
          by creating an account
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

SignupModal.propTypes = {
  open: PropTypes.bool,
  newChat: PropTypes.bool,
  setOpen: PropTypes.func,
  userFingerprint: PropTypes.string,
  onNext: PropTypes.func,
};

SignupModal.defaultProps = {
  open: false,
  newChat: false,
  setOpen: null,
  userFingerprint: '',
  onNext: null,
};

export default SignupModal;
