import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Box, FormControl, InputAdornment, MenuItem, TextField as MuiTextField, Select } from '@mui/material';
import { AttachmentClipHook, SendMessageDm } from 'react-basicons';
import { BeatLoader } from 'react-spinners';

const TextField = ({
  placeholder,
  inputRef,
  fullWidth,
  loading,
  isLawyerLoggedIn,
  handleUpload,
  isUploading,
  salutation,
  setSalutation,
  updatedInput,
  handleSubmit,
  names,
  setMultiSelectOptions,
  multiSelectOptions,
  currency,
  setCurrency,
}) => {
  const location = useLocation();
  const isChatPage = location.pathname !== '/chat-history' && location.pathname !== '/new-chat';

  const isMultiline = updatedInput === 'MULTILINE' || updatedInput === 'MULTILINE_BULLET_POINT';
  const isSelectable = updatedInput === 'SELECT_MENU';
  const isDate = updatedInput === 'DATE';
  const isMultiSelectable = updatedInput === 'MULTI_SELECT_MENU';
  const isSelectableCurrency = updatedInput === 'SELECT_MENU_CURRENCY';

  const handleSalutationChange = (event) => {
    setSalutation(event.target.value);
  };
  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleMultiSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setMultiSelectOptions(typeof value === 'string' ? value.split(',') : value);
  };

  const getStartAdorment = () => {
    if (isSelectable) {
      return (
        <FormControl sx={{ minWidth: 'fit-content' }}>
          <Select
            variant="standard"
            disableUnderline
            labelId="simple-select-label"
            id="simple-select"
            value={salutation}
            onChange={handleSalutationChange}
          >
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="Mr.">Mr.</MenuItem>
            <MenuItem value="Mrs.">Mrs.</MenuItem>
            <MenuItem value="Ms.">Ms.</MenuItem>
          </Select>
        </FormControl>
      );
    }

    if (isMultiSelectable) {
      return (
        <FormControl sx={{ maxWidth: '290px' }}>
          <Select
            variant="standard"
            disableUnderline
            labelId="simple-select-label"
            id="simple-select"
            value={multiSelectOptions}
            onChange={handleMultiSelectChange}
            multiple
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (isSelectableCurrency) {
      return (
        <FormControl sx={{ minWidth: 'fit-content' }}>
          <Select
            variant="standard"
            disableUnderline
            labelId="simple-select-label"
            id="simple-select"
            value={currency}
            onChange={handleCurrencyChange}
          >
            <MenuItem value="Rupees">Rupees</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
          </Select>
        </FormControl>
      );
    }

    return null;
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [updatedInput]);

  return !isChatPage ? (
    <MuiTextField
      fullWidth={fullWidth}
      inputRef={inputRef}
      autoComplete="off"
      sx={{
        bgcolor: 'primary.white',
        borderRadius: 2,
        '&:hover fieldset': {
          borderRadius: 2,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused': {
            borderRadius: 2,
            '& fieldset': {
              borderColor: 'transparent',
            },
          },
        },
        '& .MuiInputLabel-root': {
          borderRadius: 2,
          '&.Mui-focused': {
            color: 'transparent',
          },
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {loading || isUploading ? (
              <BeatLoader size={8} color="gray" speedMultiplier={0.8} />
            ) : (
              <>
                {isLawyerLoggedIn && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    component="label"
                    htmlFor="upload_attachment"
                    sx={{
                      height: '32px',
                      width: '32px',
                      borderRadius: '50%',
                      border: '2px solid',
                      borderColor: 'primary.main',
                      backgroundColor: 'primary.light',
                      cursor: 'pointer',
                      ml: 2,
                      outline: 0,
                    }}
                  >
                    <AttachmentClipHook color="black" weight={2} />
                    <MuiTextField
                      type="file"
                      id="upload_attachment"
                      sx={{ display: 'none' }}
                      onChange={handleUpload}
                      accept=".pdf,.docx"
                      inputProps={{
                        accept: '.pdf,.docx',
                      }}
                    />
                  </Box>
                )}
                <Box
                  component="button"
                  type="submit"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    p: 1,
                    height: '36px',
                    width: '36px',
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    cursor: 'pointer',
                    ml: 2,
                    border: 0,
                    outline: 0,
                  }}
                >
                  <SendMessageDm color="black" size={40} weight={2} />
                </Box>
              </>
            )}
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
    />
  ) : (
    <MuiTextField
      fullWidth={fullWidth}
      inputRef={inputRef}
      autoComplete="off"
      type={isDate ? 'date' : 'text'}
      multiline={isMultiline}
      maxRows={2}
      onKeyDown={(e) => {
        if (isMultiline) {
          if ((e.key === 'Enter' && e.altKey) || (e.key === 'Enter' && e.shiftKey)) {
            e.preventDefault();
            const textarea = e.target;
            const currentCursorPosition = textarea.selectionStart;
            const textBeforeCursorPosition = textarea.value.substring(0, currentCursorPosition);
            const textAfterCursorPosition = textarea.value.substring(currentCursorPosition);
            const newText = `${textBeforeCursorPosition}\n${textAfterCursorPosition}`;
            textarea.value = newText;
            textarea.setSelectionRange(currentCursorPosition + 1, currentCursorPosition + 1);
            textarea.scrollTop = textarea.scrollHeight;
          } else if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
          }
        }
      }}
      sx={{
        bgcolor: 'primary.white',
        borderRadius: 2,
        '&:hover fieldset': {
          borderRadius: 2,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused': {
            borderRadius: 2,
            '& fieldset': {
              borderColor: 'transparent',
            },
          },
        },
        '& .MuiInputLabel-root': {
          borderRadius: 2,
          '&.Mui-focused': {
            color: 'transparent',
          },
        },
      }}
      InputProps={{
        startAdornment: getStartAdorment(),
        endAdornment: (
          <InputAdornment position="end">
            {loading || isUploading ? (
              <BeatLoader size={8} color="gray" speedMultiplier={0.8} />
            ) : (
              <>
                {isLawyerLoggedIn && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    component="label"
                    htmlFor="upload_attachment"
                    sx={{
                      height: '32px',
                      width: '32px',
                      borderRadius: '50%',
                      border: '2px solid',
                      borderColor: 'primary.main',
                      backgroundColor: 'primary.light',
                      cursor: 'pointer',
                      ml: 2,
                      outline: 0,
                    }}
                  >
                    <AttachmentClipHook color="black" weight={2} />
                    <MuiTextField
                      type="file"
                      id="upload_attachment"
                      sx={{ display: 'none' }}
                      onChange={handleUpload}
                      accept=".pdf,.docx"
                      inputProps={{
                        accept: '.pdf,.docx',
                      }}
                    />
                  </Box>
                )}
                <Box
                  component="button"
                  type="submit"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    p: 1,
                    height: '36px',
                    width: '36px',
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    cursor: 'pointer',
                    ml: 2,
                    border: 0,
                    outline: 0,
                  }}
                >
                  <SendMessageDm color="black" size={40} weight={2} />
                </Box>
              </>
            )}
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
    />
  );
};

TextField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  isLawyerLoggedIn: PropTypes.bool,
  handleUpload: PropTypes.func,
  isUploading: PropTypes.bool,
  salutation: PropTypes.string,
  setSalutation: PropTypes.func,
  updatedInput: PropTypes.string,
  handleSubmit: PropTypes.func,
  names: PropTypes.array,
  multiSelectOptions: PropTypes.array,
  setMultiSelectOptions: PropTypes.func,
  currency: PropTypes.string,
  setCurrency: PropTypes.func,
};

TextField.defaultProps = {
  inputRef: null,
  fullWidth: false,
  loading: false,
  isLawyerLoggedIn: false,
  handleUpload: null,
  isUploading: false,
  salutation: '',
  setSalutation: null,
  updatedInput: '',
  handleSubmit: null,
  names: [],
  multiSelectOptions: [],
  setMultiSelectOptions: null,
  currency: '',
  setCurrency: null,
};

export default TextField;
